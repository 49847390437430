import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import MediaQuery from 'react-responsive';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import CustomHelmet from "@app/components/CustomHelmets/CustomHelmet";
import CustomHeader from "@app/components/Header/CustomHeader";
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import Parallax from "@app/components/Parallax/Parallax";

import landingPageStyle from "@app/assets/jss/material-kit-pro-react/views/landingPageStyle";

// Sections for this page
import SectionProduct from "./Sections/SectionProduct";

class LandingPage extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          openConsumerModal: false,
          openProviderModal: false,
      };

      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;                    
  }

  handleOpenModal(modalType) {
      this.setState({
          [modalType]: true
      });
  }
  
  handleCloseModal(modalType) {
      this.setState({
          [modalType]: false
      });
  }

  render() {
    const { classes, ...rest } = this.props;

    const currentLang = this.props.locale;
    const canonical = this.props.intl.formatMessage({ id: "landing.canonical" });
    const ogTitle = this.props.intl.formatMessage({ id: "landing.og.title" });
    const ogDescription = this.props.intl.formatMessage({ id: "landing.og.description" });
    const ogType = this.props.intl.formatMessage({ id: "landing.og.type" });
    const ogSiteName = this.props.intl.formatMessage({ id: "landing.og.site.name" });
    const ogLocale = this.props.intl.formatMessage({ id: "landing.og.locale" });
    const ogLocaleAlt1 = this.props.intl.formatMessage({ id: "landing.og.localeAlt1" });
    const ogLocaleAlt2 = this.props.intl.formatMessage({ id: "landing.og.localeAlt2" });
    const ogImage = this.props.intl.formatMessage({ id: "landing.og.image" });
    const ogImageType = this.props.intl.formatMessage({ id: "landing.og.image.type" });
    const ogImageWidth = this.props.intl.formatMessage({ id: "landing.og.image.width" });
    const ogImageHeight = this.props.intl.formatMessage({ id: "landing.og.image.height" });
    const ogImageAlt = this.props.intl.formatMessage({ id: "landing.og.image.alt" });
    const twitterTitle = this.props.intl.formatMessage({ id: "landing.twitter.title" });
    const twitterDescription = this.props.intl.formatMessage({ id: "landing.twitter.description" });
    const twitterCardType = this.props.intl.formatMessage({ id: "landing.twitter.card.type" });
    const twitterCreator = this.props.intl.formatMessage({ id: "landing.twitter.creator" });
    const twitterImage = this.props.intl.formatMessage({ id: "landing.twitter.image" });
    const twitterImageAlt = this.props.intl.formatMessage({ id: "landing.twitter.image.alt" });


    const parallaxContent = (
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12}>
            <h1 className={classes.title}>Simple Software Solutions</h1>
          </GridItem>
        </GridContainer>
      </div>
    );

    return (
      <div>
        <CustomHelmet
          htmlLang={currentLang}
          helmetTitle={ogTitle}
          canonical={canonical}
          metaDescription={ogDescription}
          ogType={ogType}
          ogSiteName={ogSiteName}
          ogTitle={ogTitle}
          ogDescription={ogDescription}
          ogURL={canonical}
          ogLocale={ogLocale}
          ogLocaleAlt1={ogLocaleAlt1}
          ogLocaleAlt2={ogLocaleAlt2}
          ogImage={ogImage}
          ogImageType={ogImageType}
          ogImageWidth={ogImageWidth}
          ogImageHeight={ogImageHeight}
          ogImageAlt={ogImageAlt}
          twitterTitle={twitterTitle}
          twitterDescription={twitterDescription}
          twitterCardType={twitterCardType}
          twitterCreator={twitterCreator}
          twitterImage={twitterImage}
          twitterImageAlt={twitterImageAlt}
        />
        <CustomHeader/>
        <MediaQuery query="(min-device-width: 0px) and (max-device-width: 599px)">
          <Parallax image={require("@app/assets/img/bg/landing-sionnach-solutions-laptop-table-field-600w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 600px) and (max-device-width: 959px)">
          <Parallax image={require("@app/assets/img/bg/landing-sionnach-solutions-laptop-table-field-960w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 960px) and (max-device-width: 1279px)">
          <Parallax image={require("@app/assets/img/bg/landing-sionnach-solutions-laptop-table-field-1280w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1280px) and (max-device-width: 1919px)">
          <Parallax image={require("@app/assets/img/bg/landing-sionnach-solutions-laptop-table-field-1920w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1920px)">
          <Parallax image={require("@app/assets/img/bg/landing-sionnach-solutions-laptop-table-field-2560w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <SectionProduct
              currentLang={currentLang}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      locale: state.i18n.locale
  }
}
const mapDispatchToProps = dispatch => {
    return {
        setLocale: locale => dispatch(setLocale(locale))
    };
};

const Page = injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(landingPageStyle)(LandingPage)));
export default Page;
