import React from 'react';
import MediaQuery from 'react-responsive';

import logoWhiteS from '@app/assets/img/logos/sionnach-solutions-logo-white-60w.png';
import logoWhiteM from '@app/assets/img/logos/sionnach-solutions-logo-white-120w.png';
import logoWhiteL from '@app/assets/img/logos/sionnach-solutions-logo-white-240w.png';
import logoYellowS from '@app/assets/img/logos/sionnach-solutions-logo-yellow-60w.png';
import logoYellowM from '@app/assets/img/logos/sionnach-solutions-logo-yellow-120w.png';
import logoYellowL from '@app/assets/img/logos/sionnach-solutions-logo-yellow-240w.png';

const Brand = props => {
    const logoAlt = props.logo ? props.logo : "Sionnach Solutions";
    return (
        <div>
            <MediaQuery query="(min-device-width: 0px) and (max-device-width: 599px)">
                <img src={Date.now() % 2 === 1 ? logoWhiteS : logoYellowS} alt={logoAlt}/>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 600px) and (max-device-width: 959px)">
                <img src={Date.now() % 2 === 1 ? logoWhiteM : logoYellowM} alt={logoAlt}/>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 960px) and (max-device-width: 1279px)">
                <img src={Date.now() % 2 === 1 ? logoWhiteM : logoYellowM} alt={logoAlt}/>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 1280px) and (max-device-width: 1919px)">
                <img src={Date.now() % 2 === 1 ? logoWhiteM : logoYellowM} alt={logoAlt}/>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 1920px)">
                <img src={Date.now() % 2 === 1 ? logoWhiteL : logoYellowL} alt={logoAlt}/>
            </MediaQuery>
        </div>
    );
}

export default Brand;
