import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import MediaQuery from 'react-responsive';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import CustomHelmet from "@app/components/CustomHelmets/CustomHelmet";
import CustomHeader from "@app/components/Header/CustomHeader";
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import Parallax from "@app/components/Parallax/Parallax";
// sections for this page
import SectionCurrent from "./Sections/SectionCurrent";
import SectionNext from "./Sections/SectionNext";
import SectionImage from "./Sections/SectionImage";

import blogPostsPageStyle from "@app/assets/jss/material-kit-pro-react/views/blogPostsPageStyle";

class BlogPostsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;

    const currentLang = this.props.locale;
    
    const canonical = this.props.intl.formatMessage({ id: "blog.canonical" });
    const ogTitle = this.props.intl.formatMessage({ id: "blog.og.title" });
    const ogDescription = this.props.intl.formatMessage({ id: "blog.og.description" });
    const ogType = this.props.intl.formatMessage({ id: "blog.og.type" });
    const ogSiteName = this.props.intl.formatMessage({ id: "blog.og.site.name" });
    const ogLocale = this.props.intl.formatMessage({ id: "blog.og.locale" });
    const ogLocaleAlt1 = this.props.intl.formatMessage({ id: "blog.og.localeAlt1" });
    const ogLocaleAlt2 = this.props.intl.formatMessage({ id: "blog.og.localeAlt2" });
    const ogImage = this.props.intl.formatMessage({ id: "blog.og.image" });
    const ogImageType = this.props.intl.formatMessage({ id: "blog.og.image.type" });
    const ogImageWidth = this.props.intl.formatMessage({ id: "blog.og.image.width" });
    const ogImageHeight = this.props.intl.formatMessage({ id: "blog.og.image.height" });
    const ogImageAlt = this.props.intl.formatMessage({ id: "blog.og.image.alt" });
    const twitterTitle = this.props.intl.formatMessage({ id: "blog.twitter.title" });
    const twitterDescription = this.props.intl.formatMessage({ id: "blog.twitter.description" });
    const twitterCardType = this.props.intl.formatMessage({ id: "blog.twitter.card.type" });
    const twitterCreator = this.props.intl.formatMessage({ id: "blog.twitter.creator" });
    const twitterImage = this.props.intl.formatMessage({ id: "blog.twitter.image" });
    const twitterImageAlt = this.props.intl.formatMessage({ id: "blog.twitter.image.alt" });

    const heading = this.props.intl.formatMessage({ id: "blog.index.header" });

    const parallaxContent = (
      <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={9} className={classes.textCenter}>
              <h1 className={classes.title}>
                {heading}
              </h1>
            </GridItem>
          </GridContainer>
        </div>
    );

    return (
      <div>
        <CustomHelmet
          htmlLang={currentLang}
          helmetTitle={ogTitle}
          canonical={canonical}
          metaDescription={ogDescription}
          ogType={ogType}
          ogSiteName={ogSiteName}
          ogTitle={ogTitle}
          ogDescription={ogDescription}
          ogURL={canonical}
          ogLocale={ogLocale}
          ogLocaleAlt1={ogLocaleAlt1}
          ogLocaleAlt2={ogLocaleAlt2}
          ogImage={ogImage}
          ogImageType={ogImageType}
          ogImageWidth={ogImageWidth}
          ogImageHeight={ogImageHeight}
          ogImageAlt={ogImageAlt}
          twitterTitle={twitterTitle}
          twitterDescription={twitterDescription}
          twitterCardType={twitterCardType}
          twitterCreator={twitterCreator}
          twitterImage={twitterImage}
          twitterImageAlt={twitterImageAlt}
        />
        <CustomHeader/>
        <MediaQuery query="(min-device-width: 0px) and (max-device-width: 599px)">
          <Parallax image={require("@app/assets/img/bg/blog-paper-pads-clips-pencils-markers-600w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 600px) and (max-device-width: 959px)">
          <Parallax image={require("@app/assets/img/bg/blog-paper-pads-clips-pencils-markers-960w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 960px) and (max-device-width: 1279px)">
            <Parallax image={require("@app/assets/img/bg/blog-paper-pads-clips-pencils-markers-1280w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1280px) and (max-device-width: 1919px)">
          <Parallax image={require("@app/assets/img/bg/blog-paper-pads-clips-pencils-markers-1920w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1920px)">
          <Parallax image={require("@app/assets/img/bg/blog-paper-pads-clips-pencils-markers-2560w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <div className={classes.main}>
          <div className={classes.container}>
            <SectionCurrent
              width={6}
            />
            <SectionNext
              lang={currentLang}
              />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      locale: state.i18n.locale
  }
}
const mapDispatchToProps = dispatch => {
    return {
        setLocale: locale => dispatch(setLocale(locale))
    };
};

const Page = injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(blogPostsPageStyle)(BlogPostsPage)));
export default Page;