import React from "react";
import cloudFunctions from "@app/services/cloudFunctions";

import ContactUsView from "./ContactUsView";

class ContactUsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mapPosition: { lat: 53.241090, lng: -9.458762 },
            name: '',
            email: '',
            phone: '',
            message: '',
            isNameValid: null,
            isEmailValid: null,
            isPhoneValid: null,
            isMessageValid: null,
            validationErrors: {
                name: { show: false, errorMessage: '' },
                email: { show: false, errorMessage: '' },
                phone: { show: false, errorMessage: '' },
                message: { show: false, errorMessage: '' }
            },
            isLoading: false,
            errorMessage: ''
        };

        this.validateFields = this.validateFields.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPhoneChange = this.onPhoneChange.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0); 
    }

    validateFields() {
        let {
            name,
            email,
            phone,
            message,
            isNameValid,
            isEmailValid,
            isPhoneValid,
            isMessageValid,
            validationErrors
        } = this.state;
        if (isNameValid && isEmailValid && (!phone || isPhoneValid) && isMessageValid) {
            return true;
        }
        if (!name) {
            isNameValid = false;
            validationErrors.name.show = true;
            validationErrors.name.errorMessage = 'Name is required';
        }
        if (!email) {
            isEmailValid = false;
            validationErrors.email.show = true;
            validationErrors.email.errorMessage = 'Email is required';
        }
        if (!message) {
            isMessageValid = false;
            validationErrors.message.show = true;
            validationErrors.message.errorMessage = 'Message is required';
        }
        this.setState({
            isNameValid: isNameValid,
            isEmailValid: isEmailValid,
            isMessageValid: isMessageValid,
            validationErrors: validationErrors
        });
        return false;
    }

    onNameChange(e) {
        const value = e.target.value;
        let validationErrors = this.state.validationErrors;
        let isNameValid = value !== '' && value.match(/^([A-Za-zÀ-ÖØ-öø-ÿ]{1,}([\.] |[-']| ))+[A-Za-zÀ-ÖØ-öø-ÿ]+\.?$/i) !== null;
        if (isNameValid) {
            validationErrors.name.errorMessage = '';
        }
        else {
            validationErrors.name.errorMessage = 'Name is invalid, at least first and last name are required';
        }
        validationErrors.name.show = !isNameValid;
        this.setState({
            name: value,
            isNameValid: isNameValid,
            validationErrors: validationErrors
        });
    }

    onEmailChange(e) {
        const value = e.target.value;
        let validationErrors = this.state.validationErrors;
        let isEmailValid = value !== '' && value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i) !== null;
        if (isEmailValid) {
            validationErrors.email.errorMessage = '';
        }
        else {
            validationErrors.email.errorMessage = 'Email is invalid';
        }
        validationErrors.email.show = !isEmailValid;
        this.setState({
            email: value,
            isEmailValid: isEmailValid,
            validationErrors: validationErrors
        });
    }

    onPhoneChange(e) {
        const value = e.target.value;
        let validationErrors = this.state.validationErrors;
        let isPhoneValid = null;
        let isValueEmpty = value === '';
        if (!isValueEmpty) {
            isPhoneValid = value.match(/^0[1-9]\s?[0-9]{7,10}$/) !== null;
        }
        if (isValueEmpty || isPhoneValid) {
            validationErrors.phone.show = false;
            validationErrors.phone.errorMessage = '';
        }
        else {
            validationErrors.phone.show = true;
            validationErrors.phone.errorMessage = 'Phone number is invalid';
        }
        this.setState({
            phone: value,
            isPhoneValid: isPhoneValid,
            validationErrors: validationErrors
        });
    }

    onMessageChange(e) {
        const value = e.target.value;
        let validationErrors = this.state.validationErrors;
        let isMessageValid = null;
        let isValueEmpty = value === '';
        if (!isValueEmpty) {
            isMessageValid = value.length <= 500;
        }
        if (isValueEmpty || isMessageValid) {
            validationErrors.message.show = false;
            validationErrors.message.errorMessage = '';
        }
        else {
            validationErrors.message.show = true;
            validationErrors.message.errorMessage = 'Message must not exceed 500 characters';
        }
        this.setState({
            message: value,
            isMessageValid: isMessageValid,
            validationErrors: validationErrors
        });
    }

    onSubmit(e) {
        e.preventDefault();
        window.scrollTo(0, 0);        
        if (this.validateFields()) {
            const {
                name, email, phone, message
            } = this.state;
            this.setState({
                isLoading: true,
                errorMessage: ''
            });
            cloudFunctions.addContactUsMessage({
                name: name,
                email: email,
                phone: phone,
                message: message
            })
                .then(() => {
                    this.setState({
                        messageAccepted: true,
                        isLoading: false
                    });
                    this.props.history.push(paths.LANDING);
                })
                .catch(error => {
                    const errorMessage = 'Unable to send your message. Try again later.';
                    console.log(errorMessage + ': ' + error.code + ': ' + error.message);
                    this.setState({
                        isLoading: false,
                        errorMessage: error.massage
                    });
                });
        }
    }

    generateViewProps() {
        return {
            ...this.state,
            onNameChange: this.onNameChange,
            onEmailChange: this.onEmailChange,
            onPhoneChange: this.onPhoneChange,
            onMessageChange: this.onMessageChange,
            onSubmit: this.onSubmit
        };
    }

    render() {
        const viewProps = this.generateViewProps();
        return (
            <ContactUsView {...viewProps} />
        );
    }

}

export default ContactUsContainer;