import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import InputIcon from '@material-ui/icons/Input';
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import Quote from "@app/components/Typography/Quote";
import CustomTable from "@app/components/CustomTable/CustomTable";
import Button from "@app/components/CustomButtons/Button";
// images
import placeHolder from "@app/assets/img/place-holders/wide-image-placeholder.jpg";
import digital from "@app/assets/img/blog/bitcoin/digital-money-transfer.png";
import times from "@app/assets/img/blog/bitcoin/the-times-jan-3-2009-1080w.jpg";
import checkPaid from "@app/assets/img/blog/bitcoin/check-paid-1080w.jpg";
import checkInsufficient from "@app/assets/img/blog/bitcoin/check-insufficient-funds-1080w.jpg";
import anglo1 from "@app/assets/img/blog/bitcoin/anglo-irish-bank-circular-inter-institution-money-deposit-scam-1.jpg";
import anglo1Ga from "@app/assets/img/blog/bitcoin/scéim-cham-taisce-airgid-idir-institiúideach-ciorclach-banc-angla-éireannach-1.jpg";
import anglo2 from "@app/assets/img/blog/bitcoin/anglo-irish-bank-circular-inter-institution-money-deposit-scam-2.jpg";
import anglo2Ga from "@app/assets/img/blog/bitcoin/scéim-cham-taisce-airgid-idir-institiúideach-ciorclach-banc-angla-éireannach-2.jpg";
import anglo3 from "@app/assets/img/blog/bitcoin/anglo-irish-bank-circular-inter-institution-money-deposit-scam-3.jpg";
import anglo3Ga from "@app/assets/img/blog/bitcoin/scéim-cham-taisce-airgid-idir-institiúideach-ciorclach-banc-angla-éireannach-3.jpg";
import distributed from "@app/assets/img/blog/bitcoin/distributed-ledger.jpg";
import cipher from "@app/assets/img/blog/bitcoin/caesar-cipher.jpg";
import cipherGa from "@app/assets/img/blog/bitcoin/sifear-chaesair.jpg";
import richard from "@app/assets/img/blog/bitcoin/richard-cantillon-quote.jpg";
import richardGa from "@app/assets/img/blog/bitcoin/sliocht-richard-cantillon.jpg";
import rubik from "@app/assets/img/blog/bitcoin/rubiks-cube.jpg";
import captcha from "@app/assets/img/blog/bitcoin/captcha.jpg";
import wallets from "@app/assets/img/blog/bitcoin/wallets-hard-soft.jpg";
import anonymous from "@app/assets/img/blog/bitcoin/anonymous.png";
import addresses from "@app/assets/img/blog/bitcoin/bitcoin-addresses.jpg";
import addressesGa from "@app/assets/img/blog/bitcoin/seoltaí-bonngiotán.jpg";
import symmetric from "@app/assets/img/blog/bitcoin/symmetric-key.jpg";
import asymmetric from "@app/assets/img/blog/bitcoin/asymmetric-keys.jpg";
import checkSigned from "@app/assets/img/blog/bitcoin/check-signed-robert-redford.png";
import transaction from "@app/assets/img/blog/bitcoin/ledger-wallet-bitcoin-transaction.png";
import block from "@app/assets/img/blog/bitcoin/bitcoin-block.jpg";
import blockGa from "@app/assets/img/blog/bitcoin/bloc-bonngiotán.jpg";
import blockchain from "@app/assets/img/blog/bitcoin/blockchain.jpg";
import blockchainGa from "@app/assets/img/blog/bitcoin/blocshlabhra.jpg";
import difficulty from "@app/assets/img/blog/bitcoin/bitcoin-difficulty-chart.png";
import difficultyGa from "@app/assets/img/blog/bitcoin/cairt-deacrachta-bonngiotán.png";
import thomasPaine from "@app/assets/img/blog/bitcoin/thomas-paine-quote.jpg";
import thomasPaineGa from "@app/assets/img/blog/bitcoin/sliocht-thomas-paine.jpg";
import inflation from "@app/assets/img/blog/bitcoin/bitcoin-inflation-rate.png";
import inflationGa from "@app/assets/img/blog/bitcoin/ráta-boilsciú-bonngiotán.png";
import supply from "@app/assets/img/blog/bitcoin/bitcoin-supply.png";
import supplyGa from "@app/assets/img/blog/bitcoin/soláthar-bonngiotán.png";
import fees from "@app/assets/img/blog/bitcoin/send-bitcoin-fee.png";
import pools from "@app/assets/img/blog/bitcoin/bitcoin-mining-pools.jpg";
import poolsGa from "@app/assets/img/blog/bitcoin/linn-mianadóireacht-bonngiotán.jpg";
import farm from "@app/assets/img/blog/bitcoin/mining-farm-ste-haycinthe.jpg";
import sha256 from "@app/assets/img/blog/bitcoin/secure-hash-algorithm.png";
import sha256Ga from "@app/assets/img/blog/bitcoin/algartam-hais-daingnigh.png";
import electricity from "@app/assets/img/blog/bitcoin/bitcoin-mining-footprint.jpg";
import electricityGa from "@app/assets/img/blog/bitcoin/lorg-mianadóireachta-bonngiotán.jpg";
import checksum from "@app/assets/img/blog/bitcoin/linux-mint-download-authenticity-verify.jpg";
import checksumGa from "@app/assets/img/blog/bitcoin/fíordheimhniú-íoslódála-íoslódála-linux.jpg";
import target from "@app/assets/img/blog/bitcoin/bitcoin-difficulty-target.jpg";
import targetGa from "@app/assets/img/blog/bitcoin/sprioc-deacracht-bonngiotán.jpg";
import nonce from "@app/assets/img/blog/bitcoin/nonce-definition.jpg";
import nonceGa from "@app/assets/img/blog/bitcoin/focal-ócáide.png";
import longest from "@app/assets/img/blog/bitcoin/longest-chain.jpg";
import immutable from "@app/assets/img/blog/bitcoin/bitcoin-marriage-proposal.png";
import immutableGa from "@app/assets/img/blog/bitcoin/blockchair-com-bloc-bonngiotan-416236.png";
import longestChain1 from "@app/assets/img/blog/bitcoin/blockchain-51-percent-attack-1.jpg";
import longestChain2 from "@app/assets/img/blog/bitcoin/blockchain-51-percent-attack-2.jpg";
import longestChain1Ga from "@app/assets/img/blog/bitcoin/ionsaí-51-blcoshlabhra-1.jpg";
import longestChain2Ga from "@app/assets/img/blog/bitcoin/ionsaí-51-blcoshlabhra-2.jpg";
import chainSize from "@app/assets/img/blog/bitcoin/bitcoin-blockchain-size.png";
import chainSizeGa from "@app/assets/img/blog/bitcoin/méid-blocslabhra-bonngiotán.png";
import blocks from "@app/assets/img/blog/bitcoin/bigger-blocks.jpg";
import forking from "@app/assets/img/blog/bitcoin/bitcoin-litecoin-fork.jpg";
import forkingGa from "@app/assets/img/blog/bitcoin/forc-bonngiotán-bonntanaí.jpg";
import split from "@app/assets/img/blog/bitcoin/bitcoin-core-bitcoin-cash-fork.jpg";
import splitGa from "@app/assets/img/blog/bitcoin/forc-bonngiotán-cór-bonngiotán-airgead.jpg";
import airdrop from "@app/assets/img/blog/bitcoin/air-drop.jpg";
import bitcoinFees from "@app/assets/img/blog/bitcoin/bitcoin-core-bitcoin-cash-fees.png";
import bitcoinFeesGa from "@app/assets/img/blog/bitcoin/táillí-bonngiotán-cór-bonngiotán-airgead.png";
import vitalik from "@app/assets/img/blog/bitcoin/vitalik-buterin.jpg";
import token from "@app/assets/img/blog/bitcoin/basic-attention-token.png";
import tokenGa from "@app/assets/img/blog/bitcoin/licín-aird-bunúsach.png";
import exchange from "@app/assets/img/blog/bitcoin/cryptocurrency-exchanges.png";
import kitties from "@app/assets/img/blog/bitcoin/crypto-kitties-for-sale.jpg";
import nike from "@app/assets/img/blog/bitcoin/nike-blockchain-patent.jpg";
import nikeGa from "@app/assets/img/blog/bitcoin/paitinn-blocslabhra-nike.jpg";
import steem from "@app/assets/img/blog/bitcoin/steem.jpg";
import augur from "@app/assets/img/blog/bitcoin/augur.jpg";
import steemGa from "@app/assets/img/blog/bitcoin/steem-ga.jpg";
import augurGa from "@app/assets/img/blog/bitcoin/augur-ga.jpg";
// style
import sectionTextStyle from "@app/assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle";

function SectionText({ ...props }) {
  const { classes } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid,
    classes.imgCenter
  );
  const fillButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close }
  ].map((prop, key) => {
    return (
      <Button justIcon size="sm" color={prop.color} key={key}>
        <prop.icon />
      </Button>
    );
  });
  const simpleButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close }
  ].map((prop, key) => {
    return (
      <Button simple justIcon size="sm" color={prop.color} key={key}>
        <prop.icon />
      </Button>
    );
  });
  const roundButtons = [
    { color: "info", icon: Person },
    { color: "success", icon: Edit },
    { color: "danger", icon: Close }
  ].map((prop, key) => {
    return (
      <Button round justIcon size="sm" color={prop.color} key={key}>
        <prop.icon />
      </Button>
    );
  });
  const inputIconSuccess = (
    <InputIcon color="primary"/>
  );
  const settings = {
    autoplaySpeed: 7000,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnDotsHover: true,
    pauseOnHover: true
  };
  return (
    <div className={classes.section}>
      {props.lang == 'ga' && 
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Ríomh-Airgid
            </h3>
            <p>
              Is <b>airgead idirlín</b> é <b><i>Bonngiotán</i></b> [<b>bitcoin</b>]. Is féidir Bonngiotán a sheoladh ag duine díreach cosúill le <b>ríomhphost</b>. Tá dhá phointe deachúlach ag an <b>Euro</b>, agus tugtar <b>cent</b> ar €0.01. Tá ocht bpointe deachúlach ag an Bonngiotán agus tugtar <b>satoshi</b> ar  B0.00000001.
            </p>
            <figure>
              <img src={digital} alt="Beirt, duine amháin ag úsáid fón agus an duine eile ag úsáid ríomhaire glúine, ag seoladh nó ag fáil airgid dhigitigh." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Tá go leor daoine ag úsáid an euro i bhfoirm dhigiteach amháin cheana féin</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Satoshi Nakamoto
            </h3>
            <p>
              <b>Satoshi Nakamoto</b> an t-ainm cleite a úsáideann cruthaitheoir(í) an Bonngiotán. Níl a fhios cé hé Satoshi Nakamoto. Is ainm firinscneach (masculine) ón tSeapán é Satoshi a chiallaíonn <b><i>críona</i></b> [<b>wise</b>]. Chruthaigh Satoshi profíl ar an bhfóram <a href="https://web.archive.org/web/20110317060514/http://p2pfoundation.ning.com:80/profile/SatoshiNakamoto" target="_blank">p2pfoundation</a> sa bhliain 2008, chuir sé síos air fhéin mar <b>fhear Seapánach sna 30idí</b>. Déantar go leor plé ar <a href="https://www.economist.com/technology-quarterly/2018/08/30/satoshi-nakamoto-bitcoins-enigmatic-creator" target="_blank">aitheantas agus ar spreagthaí</a> Satoshi Nakamoto.
            </p>
            <figure>
              <img src={times} alt="Satharn, 3ú Eanáir 2009, ceannlíne Times Shasana: 'Chancellor on Brink of Second Bailout for Banks'. Sa fotheideal tá: 'Billions may be needed as lending squeeze tightens'" className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Déantar tagairt do cheannlíne Times Shasana, 3ú Eanáir 2009, sa chéad bhloc den blockchain Bitcoin</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Caith Dúbailte
            </h3>
            <p>
              D’fhoilsigh Satoshi Nakamoto an páipéar bán <a href="https://bitcoin.org/bitcoin.pdf" target="_blank"><i><b>Bitcoin: A Peer-to-Peer Electronic Cash System</b></i></a> i bhFómhar 2008. Thug sé réiteach fíor chliste ar an bhfadhb, <a href="https://www.investopedia.com/terms/d/doublespending.asp" target="_blank"><b>caith dúbailte</b></a>, a bhí ag cur mearbhaill ar chripteagrafaithe le scór bliain. Is éard é an caith dúbailte na an píosa ceannann céanna airgid a chaith faoi dhó.
            </p>
            <figure>
              <img src={checkPaid} alt="Seic stampáilte 'PAID'." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Tógann sé laethanta seic a phróiseáil</b></i></figcaption>
            </figure>
            <figure>
              <img src={checkInsufficient} alt="Seic stampáilte 'INSUFFICIENT FUNDS'." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Is minic go dteipeann ar seic ghlanadh</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Tríú Páirtí Iontaofa
            </h3>
            <p>
              Sula raibh Bonngiotán ann, má bhí tú ag iarraidh airgead a sheoladh ar an idirlíon, theastaigh <b><i>tríú páirtí iontaofa</i></b> [<b>trusted third party</b>] uait, cosúil le Banc na hÉireann, PayPal nó Mastercard. Deimhnítear leo seo go bhfuil a ndotháin airgid ag an seoltóir. Réitíonn siad aon fhadhb le haisíocaíochtaí nó aon díospóid.
            </p>
            <Carousel {...settings}>
              <div>
                <figure>
                  <img src={anglo1Ga} alt="Scéim cham taisce airgid idir-institiúideach ciorclach Banc Angla-Éireannach. Taispeánann sé trí íocaíocht; €978M ar an 26ú Méan Fómhar 2008, €2B ar an 29ú Méan Fómhar 2008 agus €4B ar an 30ú Méan Fómhar 2008." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>Scéim cham taisce airgid idir-institiúideach ciorclach Banc Angla-Éireannach <br/>Foinse: <a href="https://www.independent.ie/business/irish/anglo/6bn-fix-was-designed-to-make-bank-look-like-less-of-a-basket-case-29404959.html" target="_blank">independent.ie</a></b></i></figcaption>
                </figure>
              </div>
              <div>
                <figure>
                  <img src={anglo2Ga} alt="David Drumm, Príomhfheidhmeannach, John Bowe, Stiúrthóir Margaí Caipitil, an bheirt le Banc Angla-Éireannach. Bowe: Déanaimid píosaí gach lá. Drumm: Ach táimid é a chur ar ais? Bowe: Téann an t-airgead i gciorcal. Drumm: Coinnigh ort. Athscríbhinn giorraithe de chomhrá teileafóin idir David Drumm agus John Bowe ar an 29ú Méan Fómhar 2008." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>Athscríbhinn, aistrithe agus giorraithe de chomhrá teileafóin idir David Drumm agus John Bowe <br/>Foinse: <a href="https://www.independent.ie/business/irish/anglo/6bn-fix-was-designed-to-make-bank-look-like-less-of-a-basket-case-29404959.html" target="_blank">independent.ie</a></b></i></figcaption>
                </figure>
              </div>
              <div>
                <figure>
                  <img src={anglo3Ga} alt="Bowe: Tá an damhsa chun é a fháil ar ais in am ag éirí an-chrua. Íocaimid isteach sa bhanc [Irish Permanent]. Tugann an banc é don cuideachta árachais [Irish Life]. Tugann an cuideachta árachais ar ais dúinn é [Anglo Irish Bank]. Athscríbhinn giorraithe de chomhrá teileafóin idir David Drumm agus John Bowe ar an 29ú Méan Fómhar 2008." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>Athscríbhinn, aistrithe agus giorraithe de chomhrá teileafóin idir David Drumm agus John Bowe <br/>Foinse: <a href="https://www.independent.ie/business/irish/anglo/6bn-fix-was-designed-to-make-bank-look-like-less-of-a-basket-case-29404959.html" target="_blank">independent.ie</a></b></i></figcaption>
                </figure>
              </div>
            </Carousel>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Mórleabhar Dáilte
            </h3>
            <p>
              Tá an <b><i>samhaltán slandála</i></b> [<b>security model</b>], bunchloch an <b><i>chóras airgeadaíochta</i></b> [<b>monetary system</b>] a tháinig roimhe, casta bun os cionn ag an mBonngiotán. Ní theastaíonn an tríú páirtí iontaofa níos mó. Seachas cuntas <b><i>mórleabhar</i></b> [<b>ledger</b>] amháin atá rialaithe (go lárnach) ag aonán <b><i>iontaofa</i></b> [<b>trustworthy</b>] amháin, bheartaigh Bonngiotán ar mhórleabhar poiblí atá <b><i>dáilte</i></b> [<b>distributed</b>] go <b><i>forleathan</i></b> [<b>extensively</b>] ar a dtugtar <b><i>blocshlabhra</i></b> [<b>blockchain</b>].
            </p>
            <div>
                <figure>
                  <img src={distributed} alt="Grafach ag iarraidh go leor cóipeanna den mhórleabhar céanna a dháileadh ar fud na cruinne a thaispeáint." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>Tá go leor cóipeanna den mhórleabhar Bhonngiotáin ar fud an idirlín</b></i></figcaption>
                </figure>
              </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Cripteagrafaíocht
            </h3>
            <p>
              Bhí an <b>ríomh-airgid</b> ina ábhar mór cainte i measc dream na <b>cripteagrafaíochta</b> ó cruthaíodh an t-idirlíon. Coinníonn cripteagrafaíochta an chumarsáid sabháilte mar tá an t-eolas á dhéanamh <b><i>doiléir</i></b> (obscure). Bheadh sé ríthábhachtach in aon chóras ríomh-airgead, agus is é seo an fáth go dtugtar <b><i>criptea-airgeadra</i></b> [<b>cryptocurrency</b>] ar Bonngiotán.
            </p>
            <figure>
              <img src={cipherGa} alt="Grafach a léiríonn ceann de na chéad chineálacha cripteagrafaíochta, sifear Chaesair. Sifear a mhalartaíonn gach litir i dteachtaireacht ar litir atá fad seasta uaithi. Tá roth le litreacha na haibítre liostaithe faoi dhó in dhá shraith timpeall an roth. Taispeánann an roth le fritháireamh de 7 gcarachtar, áit a bhfuil H san aibítir gnáth-théacs ag teacht le A sa rúnscríobh (nó téacs-sifear)." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b><a href="https://earlychurchhistory.org/military/julius-caesars-secret-code/" target="_blank">Chruthaigh Julius Caesar</a> an córás chun a chomhfhreagras pearsanta agus, go háirithe, a chomhfhreagras míleata a thascradh</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Ríomh-Airgid
            </h3>
            <p>
              Déanadh iarrachtaí ríomh-airgid a chruthú roimh Bonngiotán. Bhí <a href="https://en.wikipedia.org/wiki/Ecash" target="_blank"><b>Ecash</b></a> ann ag tús na 90idí. Ina dhiaidh sin tháinig <a href="https://en.wikipedia.org/wiki/Nick_Szabo#Bit_gold" target="_blank"><b>Bit gold</b></a>, <a href="https://en.wikipedia.org/wiki/Proof_of_work#Reusable_proof-of-work_as_e-money" target="_blank"><b>RPOW</b></a>, agus <a href="https://en.bitcoin.it/wiki/B-money" target="_blank"><b>b-money</b></a>. Bhí a gcuid fadhbanna fhéin leo ar fad agus dhá bharr théip orthu. <a href="https://web.archive.org/web/20110525181920/http://p2pfoundation.ning.com/forum/topics/bitcoin-open-source?commentId=2003008%3AComment%3A9493" target="_blank"><b>Chreid Satoshi</b></a> gur théip orthu mar go raibh siad ar fad <b><i>rialaithe go lárnach</i></b> [<b>centrally controlled</b>].
            </p>
            <figure>
              <img src={richardGa} alt="An Iarmhairt Cantillon: Feicfidh na chéad daoine a bhfaigheann an t-airgead nua-chruthaithe ardú ar a n-ioncam ach feicfidh na daoine dheireanacha a bhfaigheann an t-airgead nua-chruthaithe a gcumhacht ceannaigh ag laghdú de réir mar a thagann boilsciú praghsanna tomhaltóirí chun cinn. Ba eacnamaí Éireannach-Francach é Richard Cantillon (1680ú – Bealtaine 1734). Measann cuid gur athair eacnamaíochta nua-aimseartha é." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Is as Trá Lí, Co. Chiarraí é <a href="https://en.wikipedia.org/wiki/Richard_Cantillon" target="_blank">Richard Cantillon</a>. Is inspioráid é do cheannródaithe ríomh-airgead ar nós <a href="https://twitter.com/NickSzabo4" target="_blank">Nick Szabo</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Mianadóireacht
            </h3>
            <p>
              In Eanair 2009, sheol Satoshi <a href="https://opensource.com/resources/what-open-source" target="_blank">bogearra foinse oscailte</a> saor in aisce, <a href="https://github.com/bitcoin/bitcoin" target="_blank"><b><i>Croílár Bonngiotán</i></b></a> [<b>Bonngiotán Core</b>]. Úsáidtear an bogearra chun an bhlocslabhra a chruthú agus a <i>chothabháil</i> (maintain). Is <b>mianadoirí</b> a thugtar orthu siúd a úsáideann an bogearra agus is <b>mianadóireacht</b> an proiséas (mar go gcruthaíonn sé Bonngiotán nua). Tá na mianadóirí in iomaíocht lena chéile chun réiteach a fail ar an <i>bhfadhb cripteagrafach dochúlaithe</i> [<b>irreversible cryptographic puzzle</b>]. Tá sé deacair an ceann is fearr a fail ar an bhfadhb ach is fuasta a dheimhniú go bhfuil sé réitigh. 
            </p>
            <figure>
              <img src={rubik} alt="Grafach le dhá chiúb Rubik, ceann ar chlé nach bhfuil réitithe agus ceann ar dheis atá réitithe." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Tá sá crua ciúb Rubik a réiteach, ach tá sé easca a fheicéal go bhfuil sé réitithe</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Cruthúnas-Oibre
            </h3>
            <p>
              Go foirmeálta tugtar córas <b><i>cruthúnas-oibre</i></b> [<b>proof-of-work</b>] ar an bpróiseas mianadóireachta. Is teicníc frith-spam é cruthúnas-oibre. Tá sé rud éigin cosúil le Google Captcha, nuair a bhíonn ort íomhánna áirithe a roghnú (roinnt oibre a dhéanamh) sular dtugtar <i>rochtain</i> [<b>access</b>] chuig suíomh gréasáin.
            </p>
            <figure>
              <img src={captcha} alt="Samplaí de CAPTCHAanna éagsúla." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Is dúshlán é CAPTCHA a fhíoraíonn daoine</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <h3 className={classes.title}>
              Sparán
            </h3>
            <p>
              Tá bogearraí eile ar fáíl ar a dtugtar <b><i>sparán</i></b> [<b>wallet</b>] chun Bonngiotán a <b>sheoladh</b>, a <b>stóráil</b> agus a <b>fháil</b>. Tá go leor sparán crua-earraí agus bogearraí ar fáil inniu. Éilíonn sparán crua-earraí slándáil mhéadaithe, ach <a href="https://westoahu.hawaii.edu/cyber/ledger-cryptocurrency-wallet-flaw/" target="_blank">níl aon rud slán go hiomlán</a>.
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <figure>
              <img src={wallets} alt="A hardware wallet and a phone app wallet." className={imgClasses} />
                <figcaption className={classes.textCenter}>
                  <i><b>Sparán crua-earraí agus aip soghluaiste <a href="https://shop.ledger.com/pages/ledger-nano-x" target="_blank">Ledger</a></b></i></figcaption>
              </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Seoladh
            </h3>
            <p>
              Teastaíonn seoladh Bonngiotán chun Bonngiotán a fháil agus a stóráil. Ligeann sparán Bonngiotán duit seoltaí iolracha a chruthú, rud atá cosúil le cuntais bhainc iolracha a bheith agat.
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <figure>
              <img src={addressesGa} alt="Tá seoladh Bonngiotán idir 26 agus 35 carachtar ar fhad, mar shampla: 1KvcJhqrFZ1DW5hpSQCYN3W4J2PLBRw94P. Líon iomlán na seoltaí féideartha Bonngiotán: 2¹⁶⁰ or 1461501637330902918203684832716283019655932542976." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Tá méid beagnach gan teorainn de sheoltaí Bonngiotán féideartha</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <h3 className={classes.title}>
              Súdanaithnid
            </h3>
            <p>
              Tugann an seoladh Bonngiotán <b><i>súdanaithnideacht</i></b> [<b>pseudo-anonymity</b>] dhuit. Déantar taiféad ar <b>sheoladh an <i>fhaighteoir</i></b> [<b>receipent</b>] agus an méid Bonngiotán a fhaightear. Tá gach idirbheart Bonngiotán taifeadta ar bhlocshlabhra go deo. Tá sé <b>níos sabháilte</b> agus <b>níos príobháidaigh</b> <a href="https://en.bitcoin.it/wiki/Address_reuse" target="_blank">seoladh nua</a> a úsáid i gcás gach idirbheart.
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <figure>
            <img src={anonymous} alt="A face hidden by sunglasses." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Níl idirbhearta Bonngiotán go hiomlán gan ainm nó neamh-inrianaithe</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Muinín
            </h3>
            <p>
              Roimh lár na 70idí, chun <b>teachtaireacht criptithe a sheoladh</b> bheadh ar an mbeirt <b><i>eochair siméadracha rúnda</i></b> [<b>secret symmetric key</b>] a roinnt lena chéile. Tá eochar (pasfhocal) amháin acu chun an t-eolas a chriptiú agus a díchriptiú. Caithfear a chinntiú go roinnfear an t-eochar ar bhealach slán, mar shampla <i>cúiréir</i> [<b>courier</b>] nó post cláraithe. Níor eascaigh teileachumarsáid éifeachtach ghnó uaidh seo. D’athraigh rudaí nuair  a d’fhoilsigh <a href="https://cisac.fsi.stanford.edu/people/whitfield_diffie" target="_blank">Whitfield Diffie</a> agus <a href="https://cisac.fsi.stanford.edu/people/martin_hellman" target="_blank">Martin Hellman</a> a bpáipéar <a href="https://ee.stanford.edu/%7Ehellman/publications/24.pdf" target="_blank"><b><i>New Directions in Cryptography</i></b></a> i bhfómhar na bliana 1976.
            </p>
            <figure>
              <img src={symmetric} alt="Eochair siméadrach nochtaithe go contúirteach ar an ngréasán domhanda." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Is gá eochair siméadrach a roinnt ach tá baol leis</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Eochracha Poiblí-Príobháideacha
            </h3>
            <p>
              Mhol Diffie agus Hellman <b>dhá <i>eochair neamhshiméadracha</i></b> [<b>asymmetric keys</b>] a úsáid. Bheadh ceann acu <b>coinnigh faoi rún</b> ag an t-úinéra agus bheadh an ceann eile <b>roinnte go poiblí</b>. Bheadh an eochair phoiblí á úsáid chun an t-eolas a <b>chriptiú</b> agus bheadh an eochair rúnda ag táisteal chun é a <b>díchriptiú</b>.
            </p>
            <figure>
              <img src={asymmetric} alt="Eochracha príobháideacha neamhshiméadracha go sábháilte lena n-úinéirí, agus a n-eochracha neamhshiméadracha poiblí nochtaithe go neamhdhíobhálach agus go húsáideach ar an ngréasán domhanda." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Tá sé úsáideach agus neamhdhíobhálach eochair phoiblí a roinnt. Níor cheart eochracha príobháideacha a roinnt.</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Sínithe Digiteacha
            </h3>
            <p>
              Is féidir <i>sláine</i> [<b>integrity</b>] teachtaireacht a sheiceáil leis an eochair phoiblí. Tá an eochair phíobháideach á úsáid chun a chinntiú go bhfuil coras dosháraithe i bhfeidhm. Cinntíonn an próiséas seo nár athraigh an teachtaireacht agus nach bhféadadh ach an té leis an eochair phríobháideach é a scríobh.
            </p>
            <figure>
              <img src={checkSigned} alt="Seic sínithe ag Robert Redford." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Tá síniú digiteach cosúil le síniú dúch ar pháipéar</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Idirbheart
            </h3>
            <p>
              Go bunúsach is é seoladh Bonngiotán an eochair phoiblí de <b>péire eochracha poiblí-príobháidí</b>. Síníonn an eochair phríobháideach idirbheart Bonngiotán go digiteach. Fíoraíonn an eochair phoiblí an t-idirbheart. Fanann idirbhearta a chraoltar chuig líonra Bonngiotán le <b>hidirbhearta neamhdhearbhaithe</b> eile.
            </p>
            <figure>
              <img src={transaction} alt="Gabháil scáileáin d’idirbheart seolta Bitcoin i gcomhéadan úsáideora gréasáin sparán crua-earraí Trezor. 0.12345678 Bonngiotán an méid atá á sheoladh, comhionann le €750.36 ag an am. Taispeántar táille ‘gnáth’ de 42 sat/B, ionann agus €0.43. Tugtar 'am dearbhaithe ionchais' de 50 nóiméad." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Sampla d’idirbheart atá á chruthú sa comhéadan úsáideora gréasáin sparán crua-earraí <a href="https://trezor.io/" target="_blank">Trezor</a>.</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Bloc
            </h3>
            <p>
              Roghnaíonn na mianadóirí na hidirbhearta is mian leo as an ngrúpa hidibhearta neamhdhearbhaithe ionas go bhféadfar iad a dhearbhú. Cuirtear na hidirbhearta isteach i struchtúr ar a dtugtar bloc. Tá bloc teoranta i méid ag thart ar 1-meigibheart, a thugann spás do thart ar <a href="https://en.bitcoin.it/wiki/Maximum_transaction_rate" target="_blank">3,500 idirbheart</a>.
            </p>
            <figure>
              <img src={blockGa} alt="Léiriú simplithe ar na sonraí i mbloc Bitcoin. Comhartha Aitheantais Uathúil Nua: 0000000000000000000f67...de27d19, Comhartha Aitheantais Uathúil Roimhe: 0000000000000000000d80...1395a40, Stampa Ama: 2020-03-25 08:52, Height: 622886, Aird: F2Pool, # Idirbhearta: 2,931, Deacracht: 16,552,923,967,337.23, Méid: 1.344719MB, Ócáide: 408,115,700, Iomlán Bonngiotán: 10628.98283719 BTC, Duais Bloc: 12.50000000 BTC, Iomlán Táillí: 0.53265959 BTC, Liosta Idirbhearta: Duais + Táillí de 13.03265959 BTC chuig seoladh 1KFH...DbYY, seolann seoladh 1AUR...411j 0.29843774 BTC chuig seoladh 1HTf...vFoi, seolann seoladh 1HTf...vFoi 0.28939070 BTC chuig seoladh 1EyE...mksn." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Cuid de na sonraí i mbloc Bonngiotán</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Blocshlabhra
            </h3>
            <p>
              Cruthaíonn gach mianadóir Bonngiotán bloc nua gach uair a réitítear an fhadhb roimhe. Chomh luath agus a réitíonn minéir an fhadhb, craolann siad a réiteach in éineacht lena mbloc. Má ghlactar leis mar réiteach bailí, nascann an bloc leis an mbloc a tháinig roimhe in ord <i>seicheamhach</i> [<b>sequential</b>], agus tugann sé seo an <b>Blocslabhra Bonngiotán</b> dúinn.
            </p>
            <figure>
              <img src={blockchainGa} alt="Taispeántas simplithe den blocshlabhra Bonngiotán a thaispeánann go bhfuil an comhartha aitheantas uathúil an bhloc roimhe sa i ngach bloc." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>I ngach bloc Bonngiotán tá comharthaG aitheantais uathúil an bhloc roimhe</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Deacracht
            </h3>
            <p>
              Chuile 2016 bloc (díreach coicís a thogann gach bloc nó méan na 2016 bloc, ná 10 noimead) déantar tomhas ar na deacrachtaí a bhaineann leo. Déantar measúnachán ar an léibheal deacracht a theastaíonn chun go dtógadh sé coicís na 2016 bloc a mhionnaigh. De réir an measunachán, laghdófar nó meadófar an deacracht.
            </p>
            <figure>
              <img src={difficultyGa} alt="Cairt a thaispeánann an leibhéal deacrachta atá ag síormhéadú i mBonngiotán. Is é an leibhéal deacrachta an líon iarrachtaí a mbeifear ag súil go mbeadh ag teastáil chun an fhadhb a réiteach. Shroich sé 2.5 trilliún i 2018, 10 trilliún i 2019, agus os cionn 15 trilliún luath i 2020, gur shroich sé 16,552,923,967,337.23." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Bíonn leibhéal deacrachta Bitcoin beagnach i gcónaí ag dul suas<br/>Foinse: <a href="https://www.coinwarz.com/mining/bitcoin/difficulty-chart" target="_blank">coinwarz.com</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Múnlú
            </h3>
            <p>
              Spreagann réiteach na faidhbe bronnadh Bonngiotán nua don mhianadóir rathúil. Is é seo an bealach a <b>chruthaítear</b> agus a <b>dháiltear</b> <u>gach</u> bitcoin nua. Tá mianadóireacht cosúil le <a href="https://www.ecb.europa.eu/press/pr/date/2016/html/pr160504.en.html" target="_blanl"><b></b>Banc Ceannais na hEorpa ag priontáil agus ag eisiúint Euro nua</a>.
            </p>
            <figure>
              <img src={thomasPaineGa} alt="Pictiúr portráid de Thomas Paine agus sliocht dá chuid: 'Dá mbeadh nó dá bhféadfadh luach a bheith ag rud ar bith atá cothrom le hór agus airgead, ní theastódh aon dlí tairisceana uaidh.'" className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b> Scríobh <a href="https://en.wikipedia.org/wiki/Thomas_Paine" target="_blank">Thomas Paine</a> ionsaí fíochmhar ar airgead páipéir<br/>Foinse: <a href="https://mises.org/library/thomas-paine-paper-money" target="_blank">mises.org</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Laghdú
            </h3>
            <p>
              Laghdaíonn duais an Bhonngiotáin faoi leith chuile 210,000 bloc de bharr na socraithe atá déanta ar bhogearra Bonngiotán. Ar an <b>28ú Samhain 2012</b>, tháinig an chéad <b>laghdú</b> ó <b>50</b> go <b>25</b>. Bhí an dara laghdú ar an <b>9ú Iúil 2016</b> ó <b>25</b> go <b>12.5</b>. Tá súil leis an gcéad laghdú eile i <b>mí Bealtaine 2020</b>. Is féidir a bheith cruinn leis an dáta. Cinntíonn na bogearraí go bhfuil bloc nua á chruthú ar an meán chuile deich nóiméad.
            </p>
            <figure>
              <img src={inflationGa} alt="Mhéadaigh ráta boilscithe Bonngiotán go dtí os cionn 160% idir a thús i 2009 agus a bhuaic i 2011. Ó shin, tá sé ag laghdú go seasta go bhfuil sé ag 3.84% ag tús 2020 sa chairt seo." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Tá sé beartaithe go dtitfidh ráta boilsciú Bonngiotán faoi bhun sprioc 2% an Bhainc Ceannais Eorpaigh i gceann bliain nó dhó<br/>Foinse: <a href="https://charts.bitcoin.com/btc/chart/inflation#ek" target="_blank">charts.bitcoin.com</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Uasteorainn
            </h3>
            <p>
              Tá <a href="https://en.bitcoin.it/wiki/Controlled_supply" target="_blank">teorainn de 21 milliún Bonngiotán</a> réamhshocraithe ag bogearraí Bonngiotán. Ceaptar go shroicheadh sé é seo sa bhliain 2140.
            </p>
            <figure>
              <img src={supplyGa} alt="Tá méid teoranta de Bonngiotán, 21 milliún agus tá 18 milliún acu buailte cheana féin i 2020." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Tá ráta soláthair agus boilscithe Bonngiotán intuartha<br/>Foinse: <a href="https://cointelegraph.com" target="_blank">cointelegraph.com</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Táillí
            </h3>
            <p>
              Is féidir leis an seolteoir táille a chur san áireamh nuair atá siad ag cruthú idirbheart Bonngiotán. Go hiondúil socraíonn an sparán na táillí go <i>huathoibríoch</i> [<b>automatically</b>] ach is féidir é a dhéanamh <i>le láimh</i> [<b>manually</b>] freisin. Athraíonn <a href="https://bitinfocharts.com/comparison/bitcoin-transactionfees.html" target="_blank">méid na táillí</a> ar an gréasán Bonngiotán go minic, ach go hiondúil bíonn an meán-tháille <b>faoi €1</b>. É sin ráíte, ag a <i>bhuaic</i> [<b>peak</b>], ar an 22ú Nollaig 2017, bhí €45 mar mhéan-tháille.
            </p>
            <figure>
              <img src={fees} alt="Gabháil scáileáin den bosca liosta-anuas táillí den aip sparán Trezor. Tá 5 roghanna ann; 'High: 66 sat/B', 'Normal: 62 sat/B', 'Economy: 2 sat/B', 'Low: 1 sat/B', 'Custom'." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Táillí Bitcoin i satoshis in aghaidh gach beart sonraí<br/>Tagairt: <a href="https://bitcoinfees.earn.com/" target="_blank">bitcoinfees.earn.com</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Linn Mianadóireacht
            </h3>
            <p>
              Nuair a thosaigh an blocshlabhra Bonngiotán in Eanair 2010, ní raibh mórán mianadóirí ag mianadóireacht. Ag an am bheadh beagnach aon ríomhaire le nasc idirlín in ann tosaigh ag mianadóireacht agus tar éis tamaill bhí seans acu réiteach a fáil ar an bhfadhb chun an duais a fáil. Bíonn duais chuile 10 noiméad, mar sin bíonn 144 duais chuile lá.
            </p>
            <p>
              Roimh dheireadh 2010, bhí mianadóirí ag gearán go raibh siad ag mianadóireacht seachtain i ndiaidh seachtain, gan aon duais dhá bharr. Dhá bharr sin <a href="https://bitcointalk.org/index.php?topic=1976.0" target="_blank">thosaigh na linn mianadóireacht</a>. Bailíonn na mianadóirí le chéile mar ghrúpa chun seans níos fearr a bheith acu duais a fáil agus é a roinnt eatarthu.
            </p>
            <figure>
              <img src={poolsGa} alt="Aibréan 2020, píchairt de dháileadh cumhachta ríomhaireachta (haiseáil) idir na linn mianadóireachta. Tá f2pool.com, atá bunaithe sa tSín-SAM-AE, ar an mbarr le 18.5% den chumhacht haiseáil iomlán den líonra Bhonngiotán. Tá poolin.com, atá bunaithe sa tSín-SAM, sa 2ú háit le 15.9%. Tá pool.btc.com, atá bunaithe sa tSín-Singeapór, sa 3ú háit le 14.2%. Tá antpool.com, atá bunaithe sa tSín, sa 4ú háit le 11.4%. Tá 1thash.top, atá bunaithe sa tSín, sa 5ú háit le 6.3%. Tá huobipool.com, atá bunaithe sa tSín, sa 6ú háit le 6.2%. Tá viabtc.com, atá bunaithe sa tSín, sa 7ú háit le 6.1%. Tá slushpool, bunaithe i bPoblacht na Seice, san 8ú háit le 5.2%. Tá an 16.1% ag gach mianadóir eile." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Aibréan 2020 - Dáileadh cumhachta ríomhaireachta (haiseáil) idir na linn mianadóireachta<br/>Foinse: <a href="https://miningpoolstats.stream/bitcoin" targte="_blank">miningpoolstats.stream</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Treallamh
            </h3>
            <p>
              Tá costas mór ag baint le trealamh mianadóireachta a shocrú suas. Nuair nach raibh mórán daoine ag mianadóireacht, bheadh aon ríomhaire caighdeánach inann a dhul in iomaíocht chun an fhadhb cripteagrafach dochúlaithe a réiteach.
            </p>
            <p>
              Ach ansin, thosaigh daoine ar nós <a href="https://en.bitcoin.it/wiki/ArtForz" target="_blank">ArtForz</a> ag úsáid suiteálacha le aonaid próiseála grafaice a bhí i bhfad níos sciobtha ag mianadóireacht ná an gnáth lárionad próiseála.
            </p>
            <p>
              Timpeall na bliana 2013, thosaigh <a href="cseweb.ucsd.edu/~mbtaylor/papers/bitcoin_taylor_cases_2013.pdf" target="_blank">hacadúlaithe ag caint</a> faoi treallamh atá deartha chun mianadóireacht amháin. Tháinig feidhmchlár nua ar an margadh, an <b><i>Ciorcad Comhtháite AonFheidhmeach</i></b> [<b>Application Specific Integrated Circuit (ASIC)</b>]. Is féidir iad a fáil ar <a href="https://www.amazon.co.uk/Bitmain-Antminer-S9-13-5TH-s/dp/B01MCZVPFE/ref=mp_s_a_1_3?keywords=bitcoin+miner+asic&qid=1577370779&refinements=p_76%3A419158031&rnid=419157031&rps=1&sprefix=bitcoin+miner&sr=8-3" target="_blank">amazon</a> cosúil leis an <a href="https://www.amazon.co.uk/gp/offer-listing/B01MCZVPFE/ref=dp_olp_all_mbc?ie=UTF8&condition=all" target="_blank">Antminer S9</a> (a dhéanann  13.5TH/s) atá ar fáíl ar Amazon.co.uk ar phraghsanna éagsúla m.sh. €100 faoi lathair.
            </p>
            <figure>
              <img src={farm} alt="An taobh istigh d’fheirm mianadóireachta Bonngiotán i Sainte Hyacinthe, Québec." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Feirm mianadóireachta Bonngiotán i Sainte Hyacinthe, Québec</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Leictreachas
            </h3>
            <p>
              Tá dhá chostas mhór ag baint le mianadóireacht, an <b>trealamh</b> agus an <b>leictreachas</b>. Le Antminer S9, tá costas an leictreachas ag <b>€0.15/cileavatuair (KiloWatt/Hour)</b> agus luach <b>€7,500</b> ag Bonngiotánamháin, tar éis bliana bheadh thart ar luach <b>€500</b> de bhonngiotán buaite agat. Ach sa mbliain céanna bheadh <b>€1,500</b> cáite agat ar leictreachas.
            </p>
            <p>
              Tá sé soiléir as sin nach bhfuil brabach le fáil ar an eangach náisiúnta in Éirinn. Ach, tá áiteanna cosúil le Quebec an tarraingteach do mhinadóirí. Tá na rataí leictreachas chomh híseal le €0.05/cileavatuair ag an bpobal i gcoitinne agus €0.01/cileavatuair ar fáíl do ghnólachtaí. Le deich mbliana anuas tá <a href="https://www.technologyreview.com/s/610786/bitcoin-is-eating-quebec/" target="_blank">fáilte</a>, <a href="https://www.lesaffaires.com/blogues/francois-remy/le-gouvernement-isole-hydro-quebec-des-cryptomonnaies/602157" target="_blank">cosc</a>, agus <a href="http://news.hydroquebec.com/en/press-releases/1487/the-regie-de-lenergie-hands-down-its-decision-in-the-blockchain-file/" target="_blank">fáilte arís</a> curtha ag Québec roimhe mianadóirí Bonngiotán.
            </p>
            <figure>
              <img src={electricityGa} alt="In 2020, tá líonra mianadóireachta Bonngiotán ag baint úsáide as thart ar 75 TeireaVatUair leictreachais gach bliain. Lorg mianadóireachta bonngiotán bliantúil; Lorg carbóin: 34.64 Mt CO2. Inchomparáideach le lorg carbóin na Danmhairge. Leictreachas: 72.94 TeireaVatUair. Inchomparáideach le húsáid leictreachais na hOstaire. Lorg mianadóireachta idirbheart amháin; Lorg carbóin: 344.93 kg CO2. Inchomparáideach le lorg carbóin 862,321 idirbhearta VISA nó 57,488 uaireanta faire YouTube. Leictreachas: 726.16 kWh. Coibhéiseach le húsáid chumhachta ghnáth-theaghlaigh na Stáit Aontaithe Mheiriceá thar 24.54 lá." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Lorg measta líonra mianadóireachta Bonngiotán<br/>Foinse: <a href="https://digiconomist.net/bitcoin-energy-consumption" target="_blank">BitcoinEnergyConsumption.com (digiconomist.net)</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Haiseáil
            </h3>
            <p>
              Úsáidtear <b><i>haiseáil</i></b> [<b>hashing</b>] chun sláine an blocshlabhra a choinneáil. Is <i>feidhm</i> [<b>function</b>] é a thogann faisnéis de méideanna eagsúla mar <i>ionchur</i> [<b>input</b>] agus as sin gheobhadh tú píosa téacs <i>uathúil</i> [<b>unique</b>] <i>do fhad sheasta</i> [<b>of fixed length</b>]  mar <i>aschur</i> [<b>output</b>]. is féidir leat triail a bhaint as <a href="https://xorbin.com/tools/sha256-hash-calculator" target="_blank">anseo</a>.
            </p>
            <p>
              Seo é an próiseas atá ag tarlú le linn mianadóireacht. Tá na mianadóirí ag úsáid an blocshlabhra Bonngiotán uilig (faoi láthair beagnach 250GB i méid), chomh maith lena n-iarracht chun bloc nua a chruthú, mar ionchur. Le chéile, tá na mianadóirí ag déanamh 100,000,000,000,000,000,000 hais gach soicind.
            </p>
            <figure>
              <img src={sha256Ga} alt="Ionchuir1: 'Is doimhin é poll an amhrais.' Aschur1: 'e8219bc15d3226938e018f0ef061a5cc77b77a3bdc5b4e361f3f0a4876206fc4'. Ionchuir2: 'Is doimhin é poll. Gan amhrais.' Aschur2: '6e52a545b31920cdf142025f0084b41490c46fbfead894942fe009d88aec8d30'." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Algartam Hais Daingnigh (AHD-256) [Secure Hash Algorithm (SHA-256)] - Samplaí ionchuir agus aschur</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Sláine
            </h3>
            <p>
              Is uirlis iontach é haiseáil chun sláine sonraí nó bogearraí a fhíorú. Tugann aschur an feidhm haiseáil síniú uathúil de sonraí nó bogearraí. Má athraítear fiú carachtar amháin den ionchur, tá an aschur go hiomlán difriúil.
            </p>
            <figure>
              <img src={checksumGa} alt="Leathanach íoslódála Linux Mint OS. Cuireann sé scátháin íoslódála ar fáil ó gach cearn den domhan chomh maith le meabhrúchán agus nasc chun an ISO íoslódáilte a fhíorú i gcás ina soláthraíonn siad luach hash sínithe go digiteach den ISO." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Ligeann luachanna hais barántúlacht íoslódálacha ar líne a fhíorú</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
            Fadhb
            </h3>
            <p>
              Teastaíonn haiseáil chun réiteach a fáil ar fhadhb cripteagrafach dochúlaithe Bonngiotán. Tógtar an blocshlabhra Bonngiotán uilig agus bloc nua idirbheart mar inchur. Chun an fhadhb a réiteach teastaíonn aschur a thosaíonn le <b>méid áirithe náideanna</b>.
            </p>
            <figure>
              <img src={targetGa} alt="Thosaigh Bonngiotáin le 8 náid mar an sprioc, mar shampla: 00000000839a8e6886ab5951d76f411475428afc90947ee320161bbf18eb6048. Teastaíonn 19 náid sa sprioc inniu, mar shampla: 0000000000000000000197a93a0af835ec72bad8d5b4808391b781e5cb95aef0." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Méadaíonn deacracht nuair a mhéadaíonn cumhacht mianadóireachta</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Uimhir Ócáide
            </h3>
            <p>
              Chun aschur difriúl a fáil ar gach hais, is féidir <a href="https://en.bitcoin.it/wiki/Nonce" target="_blank">uimhir <b><i>ócáide</i></b></a> [<b>nonce</b>] sa réimse <b>0 go dtí 4,294,967,295</b> a chur sa mbloc. Fiú le os cionn 4 billiún roghanna, tá seans ann fiú dá mbainfidh triail as gach ceann nach mbeidh an t-aschur atá ag taisteal faighte agat.  Tá rudaí eagsúla a féidir a athrú i mbloc, ar nós an stampa ama. Má athraítear é seo, is féidir na 4 billiún roghanna d’uimhir ócáide a thriail as a nua arís.
            </p>
            <figure>
              <img src={nonceGa} alt="focal ócáide, litríocht, ainmfhocal firinscneach den chéad díochlaonadh, béarla: nonce-word (noun)." className={imgClasses} longdesc="https://www.tearma.ie/q/focal%20%C3%B3c%C3%A1ide/ga/"/>
              <figcaption className={classes.textCenter}><i><b>Míniú de Ócáide ar téarma.ie<br/>Foinse: <a href="https://www.tearma.ie/q/focal%20%C3%B3c%C3%A1ide/ga/" target="_blank">tearma.ie</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              An Slabhra Is Faide
            </h3>
            <p>
              Nuair a chuireann mianadóir bloc nua ar an mblcoshlabhra, craoltar an blocshlabra nua ar an líonra Bonngiotán. Seo an leagan is faide den bhlocshlabhra. Tá riail ann leis an cód Bonngiotán go gcaithfear glacadh leis an mblocshlabhra is faide mar an fíor blocshlabhra. Déantar bailíochtú ar an mblocshlabhra nua agus má ghlactar leis, tosaíonn an proiséas mianadóireachta arís agus bíonn an slabhra nua mar ionchur. Mura nglactar leis an mbloc nua, déantar neamhaird air agus leantar ar aghaidh ag obair leis an leagan den bhlocshlabhra a tháinig roimhe.
            </p>
            <figure>
              <img src={longest} alt="Lógónna f2pool agus slushpool a thaispeáint, gach ceann acu le slabhra a sheasann don bhlocshlabhra. Tá slabhra níos faide ag slushpool, dá bhrí sin tá an fíor-chóip den bhlocshlabhra acu." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Buann an slabhra is faide</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Do-Athraithe
            </h3>
            <p>
              Deirtear go bhfuil an blocshlabhra Bonngiotán <b><i>do-athraithe</i></b> [<b>immutable</b>]. Ciallaíonn sé seo nach féidir é a athrú agus dá bhrí sin ní féidir idirbheart Bonngiotán a <i>aisiompú</i> [<b>reverse</b>]. Cruthaíonn an próiseas mianadóireachta idirbheart Bonngiotán atá neamhphraiticiúil go <i>ríomhaireachtúil</i> [<b>computationally</b>] a aisiompú, chomh fada agus a rialaíonn mianadóirí macánta an blocshlabhra. Is as seo a thagann an téarma <a href="https://en.bitcoin.it/wiki/Majority_attack" target="_blank"><b><i>an t-ionsaí 51%</i></b></a> [<b>the 51% attack</b>]. Seo cás ina bhfuil níos mó ná 50% de na mianadóirí ag obair le chéile chun idirbheart[a] atá ar an mblocshlabhra a chealú.
            </p>
            <figure>
              <img src={immutableGa} alt="Bloc Bonngiotán #416236, Stampa-Ama: 2016-06-14 07:50, Sonraí Bonnboinn: /HaoBTC/Sun Chun Yu: Zhuang Yuan, will you marry me?, https://blockchair.com/bitcoin/block/416236" className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>B’fhéidir nach mbeidh an pósadh do-athraithe i gcónaí ach <u>tá</u> an togra seo atá scríofa i mbloc Bonngiotán 416236<br/>Foinse: <a href="https://blockchair.com/search?q=marry%20me" target="_blank">blockchair.com</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Ionsaí 51%
            </h3>
            <p>
              Níl móran bealaí a féidir ionsaí a dhéanamh ar shláine blocshlabra Bonngiotán. Níl ach bealach amháin i ndáiríre agus fiú leis seo, ní bheadh sé i bhfad go mbeadh an tsláine athchóirithe arís. Ar dtús, ní fheadfadh idirbheart Bonngiotán a chruthú mura bhfuil seoladh (agus an eochar phríobháideach) Bonngiotán agat agus méid bonngiotain ag an seoladh sin.
            </p>
            <p>
              An t-aon rud a féidir a dhéanamh ná triail a bhaint as an Bonngiotán céanna a sheoladh an dara huair. Dá mbeadh an oiread sin cumhachta ag grúpa nó grúpaí de mhianadóirí go mbeadh siad inann bloc nua a chruthú, idirbheart airid a bheith ann, agus ansin bloc eile a dhéanamh leis an idirbheart ag seoladh an bhonngiotán céanna ach chuig seoladh difriúil; seo é an caith dúbailte.
            </p>
            <p>
              Ach, ní ghlacfar ach le ceann de na hidirbhearta agus dhá bhrí sin, deirtear go bhfuil sé níos sabháilte fanacht go mbeidh thart ar 4 go 6 bhloc (40 go 60 nóimead) eile curtha ar bharr an bhlocshlabhra chun a bheith cinnte nach mbeidh an t-idirbheart aisiompaithe.
            </p>
            <Carousel {...settings}>
              <div>
                <figure>
                  <img src={longestChain1Ga} alt="Tá minéir bradach ag obair ar dhá shlabhra ag aon am agus é mar aidhm aige idirbheart a fhoilsiú ar shlabhra amháin agus gan é a áireamh ar an dara slabhra." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>Minéir bradach ag obair ar dhá shlabhra ag aon am</b></i></figcaption>
                </figure>
              </div>
              <div>
                <figure>
                  <img src={longestChain2Ga} alt="Caithfidh an minéir bradacha an slabhra a leathnú gan an t-idirbheart a theastaíonn uaidh a aisiompú sula leathnaíonn mianadóir eile an slabhra a bhfuil an bloc aige a theastaíonn uaidh a aisiompú." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>Caithfidh an minéir bradacha slabhra B a shíneadh sular féidir le mianadóir eile slabhra A a shíneadh</b></i></figcaption>
                </figure>
              </div>
            </Carousel>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Bailíochtóirí
            </h3>
            <p>
              Is féidir a bheith mar <b><i>bhailíochtóir</i></b> [<b>validator</b>] amháin ar líonra Bonngiotán. Teastaíonn roinnt achmhainní líonra chun déileáil leis an mblocshlabhra. Faoi láthair, tá sé os cionn <b>250GB</b> i méid agus bheadh sé le híoslódáil ar dtús. De réir an suíomh bitcoin.org, d’fhéadadh <a href="https://bitcoin.org/en/full-node" target="_blank">200GB a uasódáil agus 20GB a íoslódáil gach mí</a>.
            </p>
            <figure>
              <img src={chainSizeGa} alt="Graf a thaispeánann an méadú ar mhéid an blocslabhra Bonngiotán ó 2009 go 2020 áit a sáraíonn sé 250GB. Tugann sé faoi deara gur shroich sé 50GB in 2015, 100GB in 2016, 200GB in 2018 agus 250GB in 2019." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Tá blocslabhra Bonngiotán ag méadú i gcónaí<br/>Foinse: <a href="https://www.blockchain.com/charts/blocks-size" target="_blank">blockchain.com</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Méid Bloc
            </h3>
            <p>
              Tá <a href="https://en.bitcoin.it/wiki/Block_size_limit_controversy" target="_blank">díospóireacht mór</a> i gconaí faoi méid 1 MB (meigibheart) na bloic i mBonngiotán agus na teorainneacha a chruthaíonn sé ar an méid idirbhearta a féidir a chur i mbloc amháin. Cruthaíonn sé scoilteanna i bpobal an Bhonngiotáin. Tá daoine ag iarraidh méid na bloic, agus dhá bhrí sin méid na hidirbhearta, a choinnéal íseal mar go bhfeiceann siad Bonngiotán mar stór luacha cosúil le ór. Tá daoine eile ag iarriadh bloic níos mó chun spás a bheith acu le níos mó hidirbhearta agus táillí níos ísle mar is airgead laethúil dóibh siúd an Bonngiotán.
            </p>
            <figure>
              <img src={blocks} alt="3 chiúb i 3 mhéid." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Tá an díospóireacht faoi mhéid na mbloc tar éis pobal Bitcoin a roinnt</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Foirc
            </h3>
            <p>
              Tá forcanna coitianta i mbogearraí agus tá na mílte acu i mbogearraí Bonngiotán. Leis an foirc déantar cóip de chódanna na mbogearraí. Déantar foirceanna chun athruithe suntasacha a dhéanadh ar an gcód.
            </p>
            <p>
              I 2011, thosaigh <a href="https://github.com/litecoin-project/litecoin" target="_blank"><b><i>Bonntanaí</i> [Litecoin]</b></a> mar foirc de <a href="https://github.com/bitcoin/bitcoin" target="_blank">chód Bhonngiotáin</a>. Laghdaigh Litecoin am an bhloc síos go dtí 2.5 noiméad (10 noiméad i mBonngiotán). Meadaíonn Litecoin uastheorainn na boinn go dtí 84 milliún (21 milliún i mBonngiotán). Freisin, úsáideann Litecoin feidhm haiseáil difriúil, <a href="http://www.tarsnap.com/scrypt.html" target="_blank"><b>scrypt</b></a>, i gcomparáid le <b>SHA-256</b> i mBonngiotán.
            </p>
            <figure>
              <img src={forkingGa} alt="Leanann Bitcoin ag eisiúint leaganacha agus théann Litecoin ar a chonair eisiúint fhéin." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>I mí Dheireadh Fómhair 2011, eisíodh Bonntanaí tar éis dó a bheith brionnaithe ó Bonngiotán (Cór)</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Scoilt
            </h3>
            <p>
              I <b>2017</b>, cruthaíodh foirc de Bhonngiotáin inár meadaíodh méid an bhloic uaidh <b>1MB</b> go dtí <b>8MB</b> mar iarracht chun réiteach a fáil ar an díospóireacht faoi mhéid na bloic. Chruthaigh sé scoilt sna mianadóirí agus chruthiagh sé leagan nua de Bhonngiotáin ar a dtugtar <a href="https://www.bitcoincash.org/" target="_blank"><b><i>Bonngiotán Airgead</i> [Bitcoin Cash]</b></a> mar foirc de Bhonngiotáin. I 2018, tháinig scoilt eile nuair a cruthú <a href="https://bitcoinsv.io/" target="_blank"><b>Bonngiotán SV (Satoshi Vision)</b></a> mar foirc de Bonngiotán Airgead de bharr níos mó díospóidí.
            </p>
            <Carousel {...settings}>
              <div>
                <figure>
                  <img src={splitGa} alt="Comhartha forc-ar-an-bhóthar a léiríonn scoilt, nó forc, Bonngiotán Airgead ó Bonngiotán (Cór)." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>I mí Lúnasa 2017, chuir Bonngiotán Airgead bloic 8MB i bhfeidhm</b></i></figcaption>
                </figure>
              </div>
              <div>
                <figure>
                  <img src={bitcoinFeesGa} alt="Bonngiotán Cór (BTC): Táille An Chéad Bloc Eile: $ 0.87; Táille Airmheán Reatha: $ 0.10. Bonngiotán Airgead (BCH): Táille An Chéad Bloc Eile: $0.0011; Táille Airmheán Reatha: $0.0006." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>Earraigh 2020, táillí idirbhirt Bonngiotán (Cór) & Bonngiotán Airgead<br/>Foinse: <a href="https://bitcoinfees.cash/" target="_blank">bitcoinfees.cash</a></b></i></figcaption>
                </figure>
              </div>
            </Carousel>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Aerscaoileadh
            </h3>
            <p>
              Tugann na forcanna seo bónas ar a dtugtar <b><i>aerscaoileadh</i></b> [<b>airdrop</b>] dóibh siúd a bhfúil boinn acu roimhe an forc. Má bḣí méid áirithe <b>Bonngiotán</b> agat roimhe an foirc a chruthaigh <b>Bonngiotán Airgead</b>, bheadh an uimhir céanna de Bonngiotán Airgead agat tar éis an foirc. An rud céanna le <b>Bonngiotán SV</b>, mar gur tógadh an foirc uaidh Bonngiotán Airgead, bheadh an uimhir céanna de Bonngiotán SV agat tar éis an foirc agus a bhí agat de Bonngiotán Airgead roimh an foirc.
            </p>
            <figure>
              <img src={airdrop} alt="Criptea-airgeadra ceanglaí go paraisiúit á aerscaoileadh go talamh tar éis titim ó eitleán. Taispeántar trí cinn; NEM, Bitcoin Airgead agus Stellar Lumens." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Thosaigh <a href="https://www.stellar.org/" target="_blank">Stellar Lumens</a>, <a href="https://nem.io/" target="_blank">NEM</a> agus <a href="https://www.bitcoincash.org/" target="_blank">Bitcoin Airgead</a> mar aerscaoilte 'saor in aisce'</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Malartán
            </h3>
            <p>
              Is éard atá i malartán criptea-airgeadra ná ardán chun criptea-airgeadra a cheannacht le euro. Tósaíonn go leor daoine le criptea-airgeadra ar an mbealach seo, ach tá imní slándála leo. Tá <a href="https://www.cnbc.com/2018/03/21/how-to-pick-the-best-cryptocurrency-exchange.html" target="_blank"><b><i>haiceáil</i></b></a> [<b>hack</b>], <a href="https://www.justice.gov/usao-or/pr/two-canadian-nationals-sentenced-federal-prison-bitcoin-fraud" target="_blank"><b><i>sceamálacha</i></b></a> [<b>scams</b>] agus <a href="https://www.ft.com/content/6636e0e8-a06e-11e3-a72c-00144feab7de" target="_blank"><b><i>féimheachta</i></b></a> [<b>bankruptcies</b>] flúirse i stair ghearr deich mbliana malartání criptea-airgeadra.
            </p>
            <figure>
              <img src={exchange} alt="Lógónna 3 mhalartán criptea-airgeadra, Coinbase, Binance agus Kraken." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Comhlíonann <a href="https://coinbase.com" target="_blank">Coinbase</a> agus <a href="https://kraken.com" target="_blank">Kraken</a> na rialacháin in aghaidh Sciúradh Airgid agus Fios-do-Chustaiméirí<br/>Tá <a href="https://www.binance.com" target="_blank">Binance</a> ag éirí <a href="https://www.coindesk.com/binance-tightens-compliance-turning-to-identitymind-for-kyc" target="_blank">níos géilliúla</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Conradh Cliste
            </h3>
            <p>
              I <b>2015</b>, lainseáil Vitalik Buterin, ríomhchláraitheoir Rúiseach-Ceanadach, leagan <b><i>in-ríomhchláraithe</i></b> [<b>programmable</b>] de bhlocshlabhra ar a dtugtar <a href="https://ethereum.org/" target="_blank"><b>Ethereum</b></a>. Is ardán é Ethereum chun feidhmchláir a rith ar a dtugtar <b><i>conarthaí cliste</i></b> [<b>smart contracts</b>]. Is conarthaí <b><i>féin-forghníomhaigh</i></b> [<b>self-executing</b>] iad atá scríofa i gcód ríomhaire. Foilsítear agus ritheann na conarthaí ar an mblocshlabhra. Tá an chumhacht acu a bheith neamhspleách agus dosháraithe.
            </p>
            <p>
              Tá criptea-airgeadra dúchais ag an bhlocshlabhra Ethereum ar a dtugtar Ether (ETH). Éascaíonn an bhlocshlabhra Ethereum cruthú <b><i>licíní-criptea</i></b> [<b>crypto tokens</b>] freisin.
            </p>
            <figure>
              <img src={vitalik} alt="Grianghraf de Vitalik Buterin (ag caitheamh léine le lamas aon-adharcach, spásárthaí agus tuar ceatha)." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b><a href="https://vitalik.ca/" target="_blank">Vitalik Buterin</a> comhbhunaitheoir Ethereum agus comhbhunaitheoir Iris Bonngiotán</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Licíní
            </h3>
            <p>
              Is cinéal criptea-airgeadra iad <b>licíní criptea</b> ach go minic is ionann iad agus sócmhainn ar nós pointí i gclár dílseachta nó scaireanna in eagraíocht. Chruthaigh cruthaitheoirí <a href="https://www.mozilla.org" target="_blank">Firefox</a> an <a href="https://basicattentiontoken.org/" target="_blank"><b><i>Licín Aird Bunúsach</i></b></a> [<b>Basic Attention Token</b>] agus é mar aidhm acu <i>"éifeachtúlacht fógraíocht digiteach a fheabhsú go radacach"</i>.
            </p>
            <figure>
              <img src={tokenGa} alt="Licín Aird Bunúsach. Triantán is ea a lógó. Déanann taobh amháin ionadaíocht ar úsáideoirí (a mbaintear mí-úsáid astu), taobh eile ar fhoilsitheoirí (atá ag gortú) agus fógróirí ar an tríú taobh (atá ag cailleadh). Baineann suas le 50% de ghnáthshonraí soghluaiste an úsáideora le haghaidh fógraí agus rianaithe. Tógann Google agus Facebook 50% den chaiteachas ar fhógraí digiteacha ar fud an domhain. Níl eolas maith ag fógróirí ar a bhfuil siad ag íoc." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Éilíonn BAT go bhfuilfógraíocht digiteach lofa le idirghabhálaithe, rianta agus caimiléireacht<br/>Foinse: <a href="https://basicattentiontoken.org/" target="_blank">basicattentiontoken.org</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              DApps
            </h3>
            <p>
              Is feidhmchláir iad <a href="https://www.stateofthedapps.com/whats-a-dapp" target="_blank"><b><i>Feidhmchláir Díláraithe</i></b></a> [<b>Decentralised Applications (DApps)</b>] a ritheann ar blockchain agus a chomhlíonann critéir áirithe. Is é an ceann is tábhachtaí ná go gcaithfidh sé a bheith foinse oscailte ionas gur féidir le duine ar bith breathnú ar an gcód. Ba é ceann de na chéad DApps cluiche cosúil le <a href="https://tamagotchi.com/" target="_blank"><b>Tamagotchi</b></a> darb ainm <a href="https://www.cryptokitties.co/" target="_blank"><b><i>PuisíníCriptea</i></b></a> [<b>CryptoKitties</b>]. Laistigh de laethanta an lainseáil, bhí PuisíníCriptea ag trádáil ag luachanna níos mó ná <a href="https://www.cryptokitties.co/search?include=sale&orderDirection=desc&search=gen:1%20cooldown:fast" target="_blank"><b>€100,000</b></a>.
            </p>
            <figure>
              <img src={kitties} alt="Gabháil scáileáin de na 8 PuisíníCriptea is costasach le díol. Tá praghas ar 5 os cionn $1,000,000. Tá 2 os cionn $100,000 agus is é $75,000 praghas an 8ú ceann." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b><a href="https://www.cryptokitties.co/search?include=sale&orderDirection=desc&search=gen:1%20cooldown:fast" target="_blank">PuisíníCriptea</a> le díol</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              NFT
            </h3>
            <p>
              Léirigh an cluiche neafaiseach <b>PuisíníCriptea</b>, gné thar a bheith cumhachtach agus úsáideach de <b>blocshlabhra</b> in éineacht le <b>conarthaí cliste</b>, <a href="https://medium.com/fitzner-blockchain-consulting/exploring-non-fungible-tokens-a7e050e1616e" target="_blank"><b><i>Licín Neamh-Idirmhalartach</i></b></a> [<b>Non-Fungible Token (NFT)</b>] Léiríonn licín neamh-idirmhalartach úinéireacht sócmhainní digiteacha nach féidir a mhodhnú. Ligeann sé an fhéidearthacht sócmhainní digiteacha, ar nós íomhánna, ríomhleabhar, nó PuisínCriptea a chruthú. Dearbhaíonn sé líon na gcóipeanna bailí atá ann agus cé leis iad.
            </p>
            <p>
              Tá <a href="http://patft.uspto.gov/netacgi/nph-Parser?Sect1=PTO2&Sect2=HITOFF&p=1&u=%2Fnetahtml%2FPTO%2Fsearch-bool.html&r=1&f=G&l=50&co1=AND&d=PTXT&s1=Nike&s2=Crypto&OS=Nike+AND+Crypto&RS=Nike+AND+Crypto" target="_blank">iarratas ar phaitinn</a> déanta ag <b>Nike</b> chun an modh seo a úsáid chun sócmhainní digiteacha a chruthú atá nasctha le cóipeanna de bhróga fisiciúla iarbhír a díoladh.
            </p>
            <figure>
              <img src={nikeGa} alt="Chuir Nike isteach ar phaitinn blocslabhra chun leaganacha digiteacha dá bhróga a chruthú 'chun cabhrú le húsáideoirí barántúlacht na bróga atá á gceannach acu a fhíorú, chomh maith le buntáistí eile'." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Thóg Nike inspioráid ó <a href="https://www.cryptokitties.co/" target="_blank">PuisíníCriptea</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              DAO
            </h3>
            <p>
              In áit daoine, tá <a href="https://en.wikipedia.org/wiki/Decentralized_autonomous_organization" target="_blank"><b><i>Eagraíocht Uathrialach Díláraithe</i></b></a> [<b>Decentralised Autonomous Organisation (DAO)]</b> rialaithe ag rialacha atá leagtha síos i gcód ríomhaire . Cuireann Bonngiotán <b>deireadh leis an ngá atá le banc ceannais airgeadra nó banc tráchtála</b> chun airgead a eisiúint nó chun idirbheart ar líne a bhailíochtú. Tugann Ethereum i bhfad níos mó féidearthachtaí chun tríú páirtithe agus idirghabháil <b>an duine a dhíothú</b> in idirghníomhaíochtaí agus idirbhearta ar líne.
            </p>
            <Carousel {...settings}>
              <div>
                <figure>
                  <img src={steemGa} alt="Is blocslabhra sóisialta é Steem a fhásann pobail agus a chuireann sruthanna ioncaim láithreacha ar fáil d’úsáideoirí trí luach saothair a thabhairt dóibh as ábhar a roinnt." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>Cuireann STEEM ardáin ar fáil le haghaidh <a href="https://steemit.com/" target="_blank">blagála</a>, <a href="https://steemmonsters.com/" target="_blank">cluichí</a> agus <a href="https://d.tube/" target="_blank">físeáin</a></b></i></figcaption>
                </figure>
              </div>
              <div>
                <figure>
                  <img src={augurGa} alt="Is malartú gealltóireachta gan teorainn é Augur nach féidir agus nach n-athróidh do theorainneachbunaithe ar d’fheidhmíocht." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>Tairgeann <a href="https://www.augur.net/" target="_blank">AUGUR</a> gealltóireacht gan teorainn</b></i></figcaption>
                </figure>
              </div>
            </Carousel>
          </GridItem>
        </GridContainer>
      }
      {props.lang != 'ga' && 
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Electronic Cash
            </h3>
            <p>
              <b>Bitcoin</b> is <b>money</b> for the <b>internet</b>. Bitcoin can be sent just like an <b>email</b>. The <b>Euro</b> has 2 decimal places, and €0.01 is called a <b>cent</b>. Bitcoin has 8 decimal places, and B0.00000001 is called a <b>satoshi</b>.
            </p>
            <figure>
              <img src={digital} alt="Graphic of two people, one using a phone and the other using a laptop, sending or receiving digital money." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Many people already send and spend euros in digital form</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Satoshi Nakamoto
            </h3>
            <p>
              <b>Satoshi Nakamoto</b> is the pseudonym of the person (or persons) who <b>created Bitcoin</b>. The real identity of Satoshi Nakamoto is unknown. Satoshi is a masculine Japanese name meaning <i>wise</i>. A profile created by Satoshi on the forum <a href="https://web.archive.org/web/20110317060514/http://p2pfoundation.ning.com:80/profile/SatoshiNakamoto" target="_blank">p2pfoundation</a> in 2008 indicated a <b>35-year-old male located in Japan</b>. There is much deliberation over the <a href="https://www.economist.com/technology-quarterly/2018/08/30/satoshi-nakamoto-bitcoins-enigmatic-creator" target="_blank">identity</a> and motives of Satoshi Nakamoto.
            </p>
            <figure>
              <img src={times} alt="The UK Times, January 3rd, 2009, headlined 'Chancellor on Brink of Second Bailout for Banks'." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>The first block of the Bitcoin blockchain references the 3rd of January, 2009, Times headline</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Double Spend
            </h3>
            <p>
              It was in the autumn of <b>2008</b> that Satoshi Nakamoto published his infamous white paper <a href="https://bitcoin.org/bitcoin.pdf" target="_blank"><i><b>Bitcoin: A Peer-to-Peer Electronic Cash System</b></i></a>. In it was an ingenious solution to a problem that had puzzled cryptographers for the previous two decades, the <a href="https://www.investopedia.com/terms/d/doublespending.asp" target="_blank"><b>double spend</b></a>. The double-spend is to spend the same money a 2nd time.
            </p>
            <figure>
              <img src={checkPaid} alt="A (blank) cheque stamped paid." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Cheques can take days to clear</b></i></figcaption>
            </figure>
            <figure>
              <img src={checkInsufficient} alt="A (blank) cheque stamped insufficient funds." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Cheques often fail to clear</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Trusted Third Party
            </h3>
            <p>
              Before Bitcoin, to send money over the internet, a <b>trusted third party</b> such as PayPal, MasterCard or Bank of Ireland, was needed. The trusted third party ensures that the sender has sufficient funds. They may also mediate disputes and issue chargebacks.
            </p>
            <Carousel {...settings}>
              <div>
                <figure>
                  <img src={anglo1} alt="A graphic of the Anglo Irish Bank circular inter-institution money deposit scam. It shows three payments, €978M on September 26 2008, €2B on September 29 2008 and €4B on September 30 2008." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>The Anglo Irish Bank circular inter-institution money deposit scam <br/>Source: <a href="https://www.independent.ie/business/irish/anglo/6bn-fix-was-designed-to-make-bank-look-like-less-of-a-basket-case-29404959.html" target="_blank">independent.ie</a></b></i></figcaption>
                </figure>
              </div>
              <div>
                <figure>
                  <img src={anglo2} alt="David Drumm, Chief Executive, John Bowe, Director of Capital Markets, both of Anglo Irish Bank. Bowe: We're doing bits every day. Drumm: But we're putting it back? Bowe: The money goes in a circle. Drumm: Keep going." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>Abridged snippets of a phone conversation between David Drumm and John Bowe <br/>Source: <a href="https://www.independent.ie/business/irish/anglo/6bn-fix-was-designed-to-make-bank-look-like-less-of-a-basket-case-29404959.html" target="_blank">independent.ie</a></b></i></figcaption>
                </figure>
              </div>
              <div>
                <figure>
                  <img src={anglo3} alt="Bowe: The dance to get it back in time [is] becoming very tough. We pay into the bank [Irish Permanent]. The bank gives it to the assurance company [Irish Life]. The assurance company gives it back to us [Anglo Irish Bank]. An abridged transcript of a taped phone conversation between David Drumm and John Bowe on September 29th, 2008." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>Abridged snippets of a phone conversation between David Drumm and John Bowe <br/>Source: <a href="https://www.independent.ie/business/irish/anglo/6bn-fix-was-designed-to-make-bank-look-like-less-of-a-basket-case-29404959.html" target="_blank">independent.ie</a></b></i></figcaption>
                </figure>
              </div>
            </Carousel>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Distributed Ledger
            </h3>
            <p>
              Bitcoin turns on its head the <b>security model</b> that is the foundation of existing monetary systems. It <b>eliminates</b> the need for a trusted third party. Instead of a private, <b>centrally controlled</b> accounting ledger, Bitcoin proposes a <b>widely distributed public ledger</b> called a <b>blockchain</b>.
            </p>
            <div>
                <figure>
                  <img src={distributed} alt="A graphic attempting to depict many copies of the same ledger distributed all over the globe." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>There are many copies of the Bitcoin ledger all over the internet</b></i></figcaption>
                </figure>
              </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Cryptography
            </h3>
            <p>
              <b>Electronic money (eMoney)</b> was a hot topic in <b>cryptography</b> circles since the dawn of the modern internet in the late 1980s and early 1990s. Cryptography keeps communication safe by <b>obscuring data</b> while it travels from the sender to the recipient. It would be crucial in any eMoney system, and this is why Bitcoin is known as a <b>cryptocurrency</b>.
            </p>
            <figure>
              <img src={cipher} alt="A graphic representing one of the first forms of cryptography, the Caesar cypher. It shows a wheel graphic with the letters of the alphabet listed twice in two rows around the wheel. Each letter in the message is changed with another letter a certain number of letters ahead in the alphabet to make a message secret with the Caesar Cipher. The wheel displays with an offset of 7 characters, where A in the plain-text alphabet lines up with H in the cypher-text." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b><a href="https://earlychurchhistory.org/military/julius-caesars-secret-code/" target="_blank">Invented by Julius Caesar</a> to frustrate attempts to intercept his personal and, especially, his military correspondence</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              eMoney
            </h3>
            <p>
              There were attempts to create eMoney before Bitcoin. There was <a href="https://en.wikipedia.org/wiki/Ecash" target="_blank"><b>Ecash</b></a> in the early 1990s. Then there was <a href="https://en.wikipedia.org/wiki/Nick_Szabo#Bit_gold" target="_blank"><b>Bit gold</b></a>, <a href="https://en.wikipedia.org/wiki/Proof_of_work#Reusable_proof-of-work_as_e-money" target="_blank"><b>RPOW</b></a> and <a href="https://en.bitcoin.it/wiki/B-money" target="_blank"><b>b-money</b></a>. They all had issues, and eventually, they all failed. <a href="https://web.archive.org/web/20110525181920/http://p2pfoundation.ning.com/forum/topics/bitcoin-open-source?commentId=2003008%3AComment%3A9493" target="_blank"><b>According to Satoshi</b></a>, attempts to create eMoney had failed because they were all centrally controlled.
            </p>
            <figure>
              <img src={richard} alt="The Cantillon Effect: The first ones to receive the newly created money see their incomes rise, whereas the last ones to receive the newly created money see their purchasing power decline as consumer price inflation comes about. Richard Cantillon (1680s – May 1734) was an Irish-French economist. Some consider him as the founding father of modern economics." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Born in Tralee, Co. Kerry, <a href="https://en.wikipedia.org/wiki/Richard_Cantillon" target="_blank">Richard Cantillon</a> is an inspiration for eMoney pioneers such as <a href="https://twitter.com/NickSzabo4" target="_blank">Nick Szabo</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Mining
            </h3>
            <p>
              In January 2009 Satoshi released free <a href="https://opensource.com/resources/what-open-source" target="_blank">open source</a> software, <a href="https://github.com/bitcoin/bitcoin" target="_blank"><b>Bitcoin Core</b></a>, for <b>creating</b> and <b>maintaining</b> the Bitcoin blockchain. The software runs a process known as <b>mining</b>. The mining process is a challenge between users of the software, who are known as <b>miners</b>. The aim is to be the first to solve a problem that is very difficult (but it’s easy to verify the solution).
            </p>
            <figure>
              <img src={rubik} alt="A graphic of an unsolved and a solved Rubik's cube." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>It takes time and effort to solve a Rubik’s cube, but it’s easy to verify that it’s solved</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Proof-Of-Work
            </h3>
            <p>
              The mining process is more formally known as a <a href="https://en.wikipedia.org/wiki/Proof_of_work" target="_blank"><b>proof-of-work</b></a> system. Proof-of-work is an anti-spam technique. It's something like Google <a href="https://en.wikipedia.org/wiki/CAPTCHA" target="_blank">Captcha's</a>, where you must select some particular images (do some work) before you can access a website.
            </p>
            <figure>
              <img src={captcha} alt="Examples of different CAPTCHAs." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>A CAPTCHA is a challenge to verify if the user is a human</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <h3 className={classes.title}>
              Wallet
            </h3>
            <p>
              In February 2009 Satoshi released the first Bitcoin <b>wallet</b> software to allow the <b>sending</b> and <b>storing</b> of Bitcoin. There are numerous hardware and software wallets available today. Hardware wallets claim increased security, but <a href="https://westoahu.hawaii.edu/cyber/ledger-cryptocurrency-wallet-flaw/" target="_blank">nothing is entirely secure</a>. 
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <br/>
            <figure>
              <img src={wallets} alt="A hardware wallet and a phone app wallet." className={imgClasses} />
                <figcaption className={classes.textCenter}><i><b><a href="https://shop.ledger.com/pages/ledger-nano-x" target="_blank">Ledger hardware wallet</a> and mobile app</b></i></figcaption>
              </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Address
            </h3>
            <p>
              A <b>Bitcoin address</b> is needed to receive and store Bitcoin. A Bitcoin wallet allows you to create multiple addresses, somewhat akin to having multiple bank accounts.
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <figure>
              <img src={addresses} alt="A Bitcoin address is between 26 and 35 characters long, e.g. 1KvcJhqrFZ1DW5hpSQCYN3W4J2PLBRw94P. The total number of possible bitcoin addresses is 2¹⁶⁰ or 1461501637330902918203684832716283019655932542976." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>There is an almost infinite amount of possible Bitcoin addresses</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <h3 className={classes.title}>
              Pseudonymity
            </h3>
            <p>
              A Bitcoin address gives you <b>pseudonymity</b>. When Bitcoin is received, the <b>address of the recipient</b> and the <b>amount of Bitcoin received</b> is forever recorded and available for viewing on the public blockchain. It is said to be more <b>secure</b> and <b>private</b> to use a <a href="https://en.bitcoin.it/wiki/Address_reuse" target="_blank">new address</a> for each transaction.
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <figure>
            <img src={anonymous} alt="A face hidden by sunglasses." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Bitcoin transactions are not entirely anonymous or untraceable</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Trust
            </h3>
            <p>
              Before the 1970s, if two parties wanted to <b>share encrypted data</b>, both parties needed access to the same password to either encrypt or decrypt the data. This password, technically known as a <b>secret symmetric key</b>, would have to be shared in a secure way such as by courier or registered mail. This process did not facilitate efficient business telecommunication. When <a href="https://cisac.fsi.stanford.edu/people/whitfield_diffie" target="_blank">Whitfield Diffie</a> and <a href="https://cisac.fsi.stanford.edu/people/martin_hellman" target="_blanl">Martin Hellman</a> published their paper <a href="https://ee.stanford.edu/%7Ehellman/publications/24.pdf" target="_blank">New Directions in Cryptography</a> in 1976, things changed.
            </p>
            <figure>
              <img src={symmetric} alt="Depiction of a symmetric key dangerously exposed on the world wide web." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Sharing a symmetric key is necessary but risky</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Public-Private Keys
            </h3>
            <p>
              Instead of a single secret symmetric key, Diffie & Hellman proposed <b>two asymmetric keys</b>. One <b>private key</b> which the owner keeps secret, and one <b>public key</b>, which can be widely shared. The public key <b>encrypts</b> data which only the corresponding private key can <b>decrypt</b>.
            </p>
            <figure>
              <img src={asymmetric} alt="Depiction of private asymmetric keys safely with their owners, and their public asymmetric keys harmlessly and usefully exposed on the world wide web." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Sharing a public key is useful and harmless. Private keys should not be shared.</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Digital Signatures
            </h3>
            <p>
              The private key creates <b>tamper-proof</b>, <b>digitally-signed</b>, <b>non-encrypted</b> messages. The public key <b>verifies</b> that the signed message is precisely what the sender wrote.
            </p>
            <figure>
              <img src={checkSigned} alt="A cheque signed by Robert Redford." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>A digital signature is akin to an ink signature on paper</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Transaction
            </h3>
            <p>
              A Bitcoin address is essentially the public key of a <b>public-private key pair</b>. The private key digitally signs a Bitcoin transaction. The public key verifies the transaction. Transactions broadcast to the Bitcoin network wait with other <b>unconfirmed transactions</b>.
            </p>
            <figure>
              <img src={transaction} alt="Screenshot of a Bitcoin send transaction in the Trezor hardware wallet web user interface. An amount of 0.12345678 Bitcoin is the amount being sent, equivalent to €750.36. A 'Normal' fee of 42 sat/B is indicated, equivalent to €0.43. An 'Expected confirmation time' of 50 minutes is indicated." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>An example of a transaction being created in the <a href="https://trezor.io/" target="_blank">Trezor</a> hardware wallet web user interface</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Block
            </h3>
            <p>
              Bitcoin miners pick transactions from the unconfirmed group so that they may become confirmed. Transactions are added to a structure known as a <b>block</b>, essentially an <b>accounting ledger</b>. A block is limited in size at about 1-megabyte, which accommodates about <a href="https://en.bitcoin.it/wiki/Maximum_transaction_rate" target="_blank">3,500 transactions</a>.
            </p>
            <figure>
              <img src={block} alt="A simplified depiction of the data in a Bitcoin block. New Unique ID: 0000000000000000000f67...de27d19, Previous ID: 0000000000000000000d80...1395a40, Timestamp: 2020-03-25 08:52, Height: 622886, Miner: F2Pool, # Transactions: 2,931, Difficulty: 16,552,923,967,337.23, Size: 1.344719MB, Nonce: 408,115,700, Bitcoin Total: 10628.98283719 BTC, Block Reward: 12.50000000 BTC, Total Fees: 0.53265959 BTC, Transaction List: Reward + Fees of 13.03265959 BTC go to address 1KFH...DbYY, address 1AUR...411j sends 0.29843774 BTC to address 1HTf...vFoi, address 1HTf...vFoi sends 0.28939070 BTC to address 1EyE...mksn." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Some of the data in a Bitcoin block</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Blockchain
            </h3>
            <p>
              Each Bitcoin miner creates a new block each time the previous problem is solved. Once a miner solves the problem, they broadcast their solution along with their block. If accepted as a valid solution, the block links to the previous block, creating a sequence of chained blocks, i.e. a <b>blockchain</b>.
            </p>
            <figure>
              <img src={blockchain} alt="A simplified depiction of the Bitcoin blockchain showing that each block contains the reference to the previous block's unique ID." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Each Bitcoin block contains the unique ID value of the previous block</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Difficulty
            </h3>
            <p>
              The Bitcoin system adjusts the <b>difficulty</b> of the problem roughly every two weeks. The aim is to keep an average time to solve the problem at <b>10 minutes</b>. The results in a block of transactions being added to, or <a href="https://en.bitcoin.it/wiki/Confirmation" target="_blank">confirmed</a> on, the blockchain every 10 minutes.
            </p>
            <figure>
              <img src={difficulty} alt="A chart displaying the ever-increasing difficulty level set in Bitcoin. The difficulty level is the expected number of attempts required to solve the problem. It reached 2.5 trillion in 2018, 10 trillion in 2019, and exceeded 15 trillion in early 2020, reaching 16,552,923,967,337.23." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Bitcoin's difficulty level is almost always on an upward trend<br/>Source: <a href="https://www.coinwarz.com/mining/bitcoin/difficulty-chart" target="_blank">coinwarz.com</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Minting
            </h3>
            <p>
              The solving of the problem triggers the awarding of new Bitcoins to the successful miner. <u>All</u> Bitcoins are <b>created</b> and <b>distributed</b> in this way. Mining is akin to the <b>European Central Bank</b> <a href="https://www.ecb.europa.eu/press/pr/date/2016/html/pr160504.en.html" target="_blanl">printing and issuing new Euros</a>.
            </p>
            <figure>
              <img src={thomasPaine} alt="A portrait painting of Thomas Paine and a quote of his: 'If anything had or could have a value equal to gold and silver, it would require no tender law.'" className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b><a href="https://en.wikipedia.org/wiki/Thomas_Paine" target="_blank">Thomas Paine</a> wrote a blistering attack on paper money<br/>Source: <a href="https://mises.org/library/thomas-paine-paper-money" target="_blank">mises.org</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Halving
            </h3>
            <p>
              It is set in the Bitcoin system to <b>halve</b> the amount of Bitcoin awarded for adding a new block to the chain every 210,000 blocks. When Bitcoin started in 2009, the reward for adding a block to the chain was <b>50 Bitcoins</b>. The first <b>halving</b>, down to <b>25</b>, occurred on the 28th of November 2012. The second halving, down to <b>12.5</b>, occurred on the 9th of July 2016. The third halving, down to <b>6.25</b> Bitcoins, is expected to occur in <b>May 2020</b>.
            </p>
            <figure>
              <img src={inflation} alt="Bitcoin inflation chart from 2009 to 2020 with inflation peaking at 175% in January 2011 and declining ever since to reach 3.83% in 2020." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Bitcoin inflation is set to fall below the European Central Bank target of 2% after the 2020 halving<br/>Source: <a href="https://charts.bitcoin.com/btc/chart/inflation#ek" target="_blank">charts.bitcoin.com</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Limit
            </h3>
            <p>
              There is a preset <a href="https://en.bitcoin.it/wiki/Controlled_supply" target="_blank">limit of 21 million Bitcoins</a> coded into the Bitcoin software. This limit is due to be reached in the year <b>2140</b>. At this point, no more new Bitcoins are created and awarded for adding a new block to the chain.
            </p>
            <figure>
              <img src={supply} alt="There is a finite amount of Bitcoin, 21 million. 18 million will be minted by 2020." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>The supply and inflation rate of Bitcoin is predictable<br/>Source: <a href="https://cointelegraph.com" target="_blank">cointelegraph.com</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Fees
            </h3>
            <p>
              Fees can, and typically should be added in each Bitcoin transaction. Wallet apps add the appropriate fee to the transaction automatically. The required fee can <a href="https://bitinfocharts.com/comparison/bitcoin-transactionfees.html" target="_blank">vary dramatically</a>. However, the average transaction fee is generally <b>less than €1</b>. The fees are added to the reward for mining a block. When Bitcoin reaches its limit in the year 2140, these fees are the only reward for mining a block.
            </p>
            <figure>
              <img src={fees} alt="Bitcoin fees are set in satoshis." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Bitcoin fees in satoshis per data byte<br/>Reference: <a href="https://bitcoinfees.earn.com/" target="_blank">bitcoinfees.earn.com</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Mining Pools
            </h3>
            <p>
              When the Bitcoin blockchain <b>started in January 2009</b>, there were not many miners mining Bitcoin. A typical home pc could have run the Bitcoin Core software and have a reasonable chance of mining a block. However, before the end of 2010, <b>miners were grumbling</b> about mining for weeks without reward.
            </p>
            <p>
              Thus begins the <a href="https://bitcointalk.org/index.php?topic=1976.0" target="_blank">creation of <b>mining pools</b></a> where miners combine their computing power. The larger group has more chance of mining a block. Any Bitcoin awarded is split amongst the group, relative to the computing power they provided. The owners of the mining pool take a fee of about 5% from each reward.
            </p>
            <figure>
              <img src={pools} alt="A pie chart of mining pool computing power distribution dated April 2020. China-USA-EU based f2pool.com are on top with 18.5% of total bitcoin network hashing power. China-USA based poolin.com are 2nd with 15.9%. China-Singapore based pool.btc.com are 3rd with 14.2%. China based antpool.com are 4th with 11.4%. China based 1thash.top are 5th with 6.3%. China based huobipool.com are 6th with 6.2%. China based viabtc.com are 7th with 6.1%. Czech Republic based slushpool are 8th with 5.2%. The remaining 16.1% is for all other miners." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>April 2020 - Distribution of computing power of the top mining pools</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Equipment
            </h3>
            <p>
              The computing hardware used to mine Bitcoin is <a href="https://www.coindesk.com/price-drop-casts-pall-over-bitcoin-miners-equipment-upgrades" target="_blank">continuously evolving</a> as miners seek to gain an edge on each other. In a <a href="https://bitcointalk.org/index.php?topic=12.msg54#msg54" target="_blank">forum post</a> made in December 2009, Satoshi asked for there to be a <i>"gentleman's agreement to postpone the <b>GPU arms race</b> as long as we can for the good of the network"</i>. 
            </p>
            <p>
              <a href="https://en.bitcoin.it/wiki/ArtForz" target="_blank">ArtForz</a> may have been the first to break this agreement with his <b>Graphics Processing Unit (GPU)</b> mining setup in 2010. By 2013, <a href="cseweb.ucsd.edu/~mbtaylor/papers/bitcoin_taylor_cases_2013.pdf" target="_blank">academia</a> were discussing the creation of bespoke hardware, known as <b>Application-Specific Integrated Circuit (ASIC)</b>, for mining Bitcoin. A popular ASIC such as the <a href="https://shop.bitmain.com/product/detail?pid=00020191212132622274d285mEB9061F" target="_blank">Ant Miner S9 costs about €100</a>.
            </p>
            <figure>
              <img src={farm} alt="The inside of a Bitcoin mining farm in Sainte Haycinthe, Quebec." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>A Bitcoin Mining Farm in Sainte-Hyacinthe, Quebec</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Electricity
            </h3>
            <p>
              After the cost of the equipment, it is the cost of electricity that determines if Bitcoin mining is profitable. At a rate of <b>€0.15 per kiloWatt/hour (kWh)</b>, and a market value of <b>€7,500 per Bitcoin</b>, <b>100 Ant Miner S9’s</b> could mine circa <b>€50,000</b> worth of Bitcoin, but would have run up an electric bill of <b>€150,000</b>.
            </p>
            <p>
              Jurisdictions like Quebec, with residential rates as low as <b>€0.05</b> per kWh and industrial rates as low as <b>€0.01</b> per kWh, are particularly appealing to Bitcoin miners. Quebec's Energy Board has a love/hate relationship with Bitcoin miners. Over the past decade, they have <a href="https://www.technologyreview.com/s/610786/bitcoin-is-eating-quebec/" target="_blank">welcomed</a>, <a href="https://www.lesaffaires.com/blogues/francois-remy/le-gouvernement-isole-hydro-quebec-des-cryptomonnaies/602157" target="_blank">rejected</a> and most recently <a href="http://news.hydroquebec.com/en/press-releases/1487/the-regie-de-lenergie-hands-down-its-decision-in-the-blockchain-file/" target="_blank">conditionally allowed</a> Bitcoin miners access to their <a href="https://phys.org/news/2018-11-quebec-canada-hydroelectric-ready.html" target="_blank">energy surplus</a>.
            </p>
            <figure>
              <img src={electricity} alt="In 2020, the Bitcoin mining network is using an estimated circa 75 TeraWattHours of electricity annually. ANNUAL Bitcoin mining  FOOTPRINT; Carbon Footprint: 34.64 Mt CO2. Comparable to the carbon footprint of Denmark. Electrical Energy: 72.94 TWh. Comparable to the power consumption of Austria. Single Transaction mining FOOTPRINT; Carbon Footprint: 344.93 kg CO2. Equivalent to the carbon footprint of 862,321 VISA transactions or 57,488 hours of watching YouTube. Electrical Energy: 726.16 kWh. Equivalent to the power consumption of an average U.S. household over 24.54 days." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>The estimated footprint of the Bitcoin mining network<br/>Source: <a href="https://digiconomist.net/bitcoin-energy-consumption" target="_blank">BitcoinEnergyConsumption.com (digiconomist.net)</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Hashing
            </h3>
            <p>
              Bitcoin uses <b>hashing</b> to maintain the <b>integrity</b> of its blockchain. Hashing is a computer <a href="https://www.bbc.co.uk/bitesize/topics/z3tbwmn/articles/z3whpv4" target="_blank">algorithm</a> that can generate <b>unique</b> and <b>fixed-length</b> output, known as a <b>hash value</b>, from any amount of input. There are many types of hashing algorithms. The one used by Bitcoin is called <a href="https://en.bitcoin.it/wiki/SHA-256" target="_blank">SHA-256</a>. It produces output that is 64 characters in length, and you can try it <a href="https://xorbin.com/tools/sha256-hash-calculator" target="_blank">here</a>.
            </p>
            <figure>
              <img src={sha256} alt="Input1: 'A woman without her man is nothing.' Output1: 'e5bd4039185b81d6d6865f2c44dcf9eaf5b419a13f32af662b23cd946cfd5187'. Input2: 'A woman: without her, man is nothing.' Output2: '3b34251ce2ee674f6731bbf7c154f44ba8b94358215a1e8014167be1dee7ce9e'." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Secure Hash Algorithm (SHA-256) - Input and Output Examples</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Integrity
            </h3>
            <p>
              Hashing is a superb tool for verifying the integrity of data. The outputted hash value is the <b>unique <i>signature</i></b> of the inputted data. If even a single character of the input is changed, the outputted hash value is entirely different.
            </p>
            <figure>
              <img src={checksum} alt="The Linux Mint OS download page. It offers download mirrors from all over the world as well as a reminder and a link to verify the downloaded ISO where they provide a digitally signed hash value of the ISO." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Hash values allow the verification of the authenticity of online downloads</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Problem
            </h3>
            <p>
              The Bitcoin mining process is running the <b>SHA256</b> hashing algorithm repeatedly. The problem that the Bitcoin Core software creates for the miners is to be the first to create an output hash value that starts with a <b>certain amount of zeros</b>. The input data for the hash algorithm is the entire Bitcoin blockchain, plus a new block of transactions.
            </p>
            <figure>
              <img src={target} alt="Bitcoin started with a target of 8 leading zeros, e.g. 00000000839a8e6886ab5951d76f411475428afc90947ee320161bbf18eb6048. Today it requires 19 leading zeros, e.g. 0000000000000000000197a93a0af835ec72bad8d5b4808391b781e5cb95aef0." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Difficulty rises when mining power increases</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Nonce
            </h3>
            <p>
              To produce different output for each hash, a number, known as a <a href="https://en.bitcoin.it/wiki/Nonce" target="_blank"><b>nonce</b></a>, is added to each block. The number can range from <b>0 to 4,294,967,295</b>. There are various techniques for attempting to solve the problem. Still, essentially it's just a brute force attempt trying one nonce value at a time. Miners have other ways, such as picking different transactions, for adjusting their input block. 
            </p>
            <figure>
              <img src={nonce} alt="The Merriam-Webster.com definitions of 'nonce'. '1. the one, particular, or present occasion, purpose, or use, e.g. for the nonce. 2. occurring, used, or made only once or for a special occasion, e.g. a nonce word." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>A dictionary definition of nonce<br/>Source: <a href="https://www.merriam-webster.com/dictionary/nonce" target="_blank">merriam-webster.com</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Longest Chain
            </h3>
            <p>
              When a miner adds a new block to the blockchain, it broadcasts its new, more extended version of the blockchain, over the Bitcoin network. Coded into the rules of Bitcoin is that miners must accept the longest chain as the one true copy of the Bitcoin blockchain.
            </p>
            <figure>
              <img src={longest} alt="Simply showing logos of f2pool and slushpool each with a chain representing the blockchain. slushpool have a longer chain, thus they have the true copy of the blockchain." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Longest chain wins</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Immutable
            </h3>
            <p>
              The Bitcoin blockchain is said to be <b>immutable</b>, meaning that it's a permanent, un-editable transaction store. Transactions that are in the most recently added blocks are susceptible to being reversed in what's known as a <a href="https://en.bitcoin.it/wiki/Majority_attack" target="_blank"><b>51% attack</b></a>. Even with a 51% attack, transactions become <b>theoretically impossible to reverse</b> as soon as 3 or 4 more blocks are chained.
            </p>
            <figure>
              <img src={immutable} alt="Bitcoin block #416236, Timestamp: 2016-06-14 07:50, Coinbase data: /HaoBTC/Sun Chun Yu: Zhuang Yuan, will you marry me?, https://blockchair.com/bitcoin/block/416236" className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Marriage may not always be immutable but this proposal written into Bitcoin block 416236 is<br/>Source: <a href="https://blockchair.com/search?q=marry%20me" target="_blank">blockchair.com</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              51% Attack
            </h3>
            <p>
              The Bitcoin blockchain is reliant on the majority of miners being <b><i>honest</i></b>. If a mining pool gained more than 50% of the total <b>hashing power</b>, they could take control of the blockchain for several blocks in a row. A rogue miner could work on two versions of the blockchain at once. 
            </p>
            <p>
              In version A, they could have a particular transaction and broadcast it to the network. If the receiver is hasty, they may see that they have received the Bitcoins at their address in the most recent block on the chain. The rogue miner could then push another version B, without that particular transaction. The rogue miner needs to add another block to version B before another miner can add one to version A (containing the transaction they want reversing).
            </p>
            <p>
              However, it would soon become apparent, after just 1 or 2 blocks, that a group of miners are rogue. The rest of the network could just ignore them, and their efforts may not have been worth the considerable effort and cost involved.
            </p>
            <Carousel {...settings}>
              <div>
                <figure>
                  <img src={longestChain1} alt="A rogue miner is working on two chains at one time aiming to publish a transaction on one chain and not include it on the second chain." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>A rogue miner working on two chains at one time</b></i></figcaption>
                </figure>
              </div>
              <div>
                <figure>
                  <img src={longestChain2} alt="The rogue miner must extend the chain without the transaction they want reversing before another miner extends the chain that has the block that they want reversing." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>The rogue miner needs to extend chain B before another miner can extend chain A</b></i></figcaption>
                </figure>
              </div>
            </Carousel>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Full Node
            </h3>
            <p>
              Bitcoin offers software, known as <a href="https://bitcoin.org/en/full-node" target="_blank"><b>full node</b></a>, that validates transactions and blocks. Its purpose is to allow anyone to be able to verify for themselves the Bitcoin blockchain. It supports the integrity of the network and assists in the relaying of transactions and blocks to others on the Bitcoin network.
            </p>
            <p>
              It takes a reasonable amount of network bandwidth to run the software. It downloads the full Bitcoin blockchain at first, which is currently over <b>250GB</b>. According to <a href="https://bitcoin.org/en/full-node" target="_blank">bitcoin.org</a>, you can expect to <b>upload 200GB</b> and <b>download 20GB</b> each month. Running this software is known as running a full node.
            </p>
              <figure>
                <img src={chainSize} alt="A graph showing the increase in size of the Bitcoin blockchain from 2009 to 2020 where it execeeds 250GB. It indciates that it reached 50GB in 2015, 100GB in 2016, 200GB in 2018 and 250GB in 2019." className={imgClasses} />
                <figcaption className={classes.textCenter}><i><b>The ever increasing Bitcoin blockchain in GigaBytes<br/>Source: <a href="https://www.blockchain.com/charts/blocks-size" target="_blank">blockchain.com</a></b></i></figcaption>
              </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Block Size
            </h3>
            <p>
              There is much debate over Bitcoins <a href="https://en.bitcoin.it/wiki/Block_size_limit_controversy" target="_blank">1MB block size</a>. Given the average block time of 10 minutes and a maximum of circa 3,500 transactions, the Bitcoin network can process circa <b>10 transactions per second</b>. Paypal processed <a href="https://www.reuters.com/article/us-paypal-results-idUSKCN0V52RN" target="_blank"><b>450 payments per second</b></a> on Cyber Monday in 2015. <a href="https://mybroadband.co.za/news/security/190348-visanet-handling-100000-transactions-per-minute.html" target="_blank">Visa state</a> that they average <b>1,667 transactions per second</b> and are capable of handling <b>56,000 transactions per second</b>.
            </p>
            <p>
              Some in the Bitcoin community want larger blocks for higher transactions per second so that Bitcoin can be a daily use <b>currency</b> like <b>euros</b>. Others want the block size to remain as is as they prefer to see Bitcoin as a <b>store of value</b> like <b>gold</b>.
            </p>
            <figure>
              <img src={blocks} alt="3 cubes in 3 sizes." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>The block size debate has divided the Bitcoin community</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Forking
            </h3>
            <p>
              Software releases occur in versions with new features added or broken features fixed in each new version. Since Bitcoin is <b>open-source software</b>, the code from each version of its release is available to the public. <a href="https://github.com/bitcoin/bitcoin/blob/master/COPYING" target="_blank">Permission is granted</a> to take any version as a basis for another cryptocurrency or blockchain application in a process known as (software) <b>forking</b>.
            </p>
            <p>
              <a href="https://github.com/litecoin-project/litecoin" target="_blank"><b>Litecoin</b></a> started as a fork of the <a href="https://github.com/bitcoin/bitcoin" target="_blank">Bitcoin code</a> in 2011. Litecoin reduced the <b>block time</b> down to <b>2.5 minutes</b> compared to <b>10 minutes</b> for Bitcoin. Litecoin has a max supply of <b>84 million</b> coins compared to <b>21 million</b> for Bitcoin. Litecoin uses a different hashing algorithm, <a href="http://www.tarsnap.com/scrypt.html" target="_blank">scrypt</a>, compared to <b>SHA256</b> used by Bitcoin.
            </p>
            <figure>
              <img src={forking} alt="Bitcoin continues releasing versions as Litecoin goes on its release path." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>In October 2011, Litecoin released after being forked from Bitcoin (Core)</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Splits
            </h3>
            <p>
              In <b>2017</b>, in an attempt to resolve the Bitcoin block size debate, a fork was made of the Bitcoin software that increased the block size from <b>1MB</b> to <b>8MB</b>. A split occurred in the mining community. Most ignored the forked version and continued to use the version of Bitcoin with 1MB blocks. However, some miners did use the 8MB version of the software, thus creating another version of Bitcoin that would be known as <a href="https://www.bitcoincash.org/" target="_blank"><b>Bitcoin Cash</b></a>. In 2018, due to <a href="https://bitcoinist.com/bitcoin-cash-abc-vs-bitcoin-cash-sv-examining-the-bitcoin-cash-hash-war/" target="_blank">further disputes</a> within the Bitcoin Cash mining community, a fork of Bitcoin Cash was created that would be known as <a href="https://bitcoinsv.io/" target="_blank"><b>Bitcoin SV (Satoshi Vision)</b></a>.
            </p>
            <Carousel {...settings}>
              <div>
                <figure>
                  <img src={split} alt="A fork-in-the-road sign representing the split, or forking of, Bitcoin Cash from Bitcoin (Core)." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>In August 2017, Bitcoin Cash implemented 8MB blocks</b></i></figcaption>
                </figure>
              </div>
              <div>
                <figure>
                  <img src={bitcoinFees} alt="Bitcoin Core (BTC): Next Block Fee: $0.87; Current Median Fee: $0.10. Bitcoin CASH (BCH): Next Block Fee: $0.0011; Current Median Fee: $0.0006." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>Spring 2020, Bitcoin (Core) & Bitcoin Cash transaction fees<br/>Source: <a href="https://bitcoinfees.cash/" target="_blank">bitcoinfees.cash</a></b></i></figcaption>
                </figure>
              </div>
            </Carousel>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Air Drops
            </h3>
            <p>
              These forks give a bonus, known as an <b>airdrop</b>, to holders of the coin that forked. If you had a certain number of Bitcoin when Bitcoin Cash was forked, you would have the same number of Bitcoin Cash. The same as when Bitcoin SV forked from Bitcoin Cash, you would have the same number of coins on the new Bitcoin SV blockchain.
            </p>
            <figure>
              <img src={airdrop} alt="Cryptocurrencies being parachuted to the ground after being dropped from a plane. Three are shown; NEM, Bitcoin Cash and Stellar Lumens." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b><a href="https://www.stellar.org/" target="_blank">Stellar Lumens</a>, <a href="https://nem.io/" target="_blank">NEM</a> and <a href="https://www.bitcoincash.org/" target="_blank">Bitcoin Cash</a> started as 'free' airdrops</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Exchange
            </h3>
            <p>
              A <b>cryptocurrency exchange</b> is a platform to convert between euros and cryptocurrencies, or between cryptocurrencies.  Exchanges are many peoples route into cryptocurrencies, but there are security concerns. There are <a href="https://www.cnbc.com/2018/03/21/how-to-pick-the-best-cryptocurrency-exchange.html" target="_blank">hacks</a>, <a href="https://www.justice.gov/usao-or/pr/two-canadian-nationals-sentenced-federal-prison-bitcoin-fraud" target="_blank">scams</a> and <a href="https://www.ft.com/content/6636e0e8-a06e-11e3-a72c-00144feab7de" target="_blank">bankruptcies</a> aplenty to be found in the decade long history of cryptocurrency exchanges.   
            </p>
            <figure>
              <img src={exchange} alt="Logos of 3 cryptocurrency exchanges, Coinbase, Binance and Kraken." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b><a href="https://coinbase.com" target="_blank">Coinbase</a> and <a href="https://kraken.com" target="_blank">Kraken</a> comply with Anti-Money-Laundering and Know-Your-Customer regulations<br/> <a href="https://www.binance.com" target="_blank">Binance</a> is <a href="https://www.coindesk.com/binance-tightens-compliance-turning-to-identitymind-for-kyc" target="_blank">becoming more compliant</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Smart Contracts
            </h3>
            <p>
              In 2015, <a href="https://vitalik.ca/" target="_blank">Vitalik Buterin</a>, a Russian-Canadian programmer launched a programmable version of a blockchain called <a href="https://ethereum.org/" target="_blank"><b>Ethereum</b></a>. Ethereum is a platform for running <b>decentralised</b> and <b>autonomous applications (DApps)</b> or <b>organisations (DAO)</b>. Rules, known as <b>smart contracts</b>, set out in computer code, govern these apps or organisations. The Ethereum blockchain has its native cryptocurrency called <a href="https://coinmarketcap.com/currencies/ethereum/" target="_blank"><b>Ether (ETH)</b></a>. Ethereum also facilitates the creation of <b>crypto tokens</b>.
            </p>
            <figure>
              <img src={vitalik} alt="Photo of Vitalik Buterin (wearing a tshirt with unicorn llamas, spaceships and a rainbow)." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b><a href="https://vitalik.ca/" target="_blank">Vitalik Buterin</a> co-founder of Ethereum and co-founder of Bitcoin Magazine</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              Tokens
            </h3>
            <p>
              <b>Crypto tokens</b> are a type of cryptocurrency but often represent an asset such as points in a loyalty program or shares in an organisation. The creators of <a href="https://www.mozilla.org" target="_blank">Firefox</a> created the <a href="https://basicattentiontoken.org/" target="_blank">Basic Attention Token</a> with aims to <i>"radically improve the efficiency of digital advertising"</i>.
            </p>
            <figure>
              <img src={token} alt="Basic Attention Token. Its logo is a triangle. One side represents users (who are abused), one publishers (who are hurting) and one advertisers (who are losing). Up to 50% of the average user’s mobile data is for ads and trackers. Google and Facebook take 50% of world-wide digital ad spend. Advertisers lack good information on what they are paying for." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>BAT claim that intermediaries, trackers and fraud overrun digital advertising<br/>Source: <a href="https://basicattentiontoken.org/" target="_blank">basicattentiontoken.org</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              DApps
            </h3>
            <p>
              <a href="https://www.stateofthedapps.com/whats-a-dapp" target="_blank"><b>Decentralised Applications (DApps)</b></a> are applications that run on a blockchain and meet particular criteria. The most important one is that it must be open-source so that anyone can look at the code and be able to verify what the code does. One of the first DApps was a <a href="https://tamagotchi.com/" target="_blank"><b>Tamagotchi-like</b></a> game called <a href="https://www.cryptokitties.co/" target="_blank"><b>CryptoKitties</b></a>. Within days of launch, CryptoKitties were trading at values exceeding <a href="https://www.cryptokitties.co/search?include=sale&orderDirection=desc&search=gen:1%20cooldown:fast" target="_blank"><b>€100,000</b></a>.
            </p>
            <figure>
              <img src={kitties} alt="Screenshot of the 8 highest priced CryptoKitties for sale. 5 are priced at over $1,000,000, 2 at over $100,000 and the 8th one is $75,000." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b><a href="https://www.cryptokitties.co/search?include=sale&orderDirection=desc&search=gen:1%20cooldown:fast" target="_blank">CryptoKitties</a> for sale</b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              NFT
            </h3>
            <p>
              This seemingly innocuous game CryptoKitties demonstrated a superbly powerful and useful feature of <b>blockchain</b> coupled with <b>smart contracts</b>, a <b><a href="https://medium.com/fitzner-blockchain-consulting/exploring-non-fungible-tokens-a7e050e1616e" target="_blank">Non-Fungible Token (NFT)</a></b>. A non-fungible token represents ownership of digital assets which cannot be modified. It allows the possibility for creating digital assets, such an image, an ebook, or a CryptoKitty, and assert the number of valid copies that exist and who owns them.
            </p>
            <p>
              <b>Nike</b> has <a href="http://patft.uspto.gov/netacgi/nph-Parser?Sect1=PTO2&Sect2=HITOFF&p=1&u=%2Fnetahtml%2FPTO%2Fsearch-bool.html&r=1&f=G&l=50&co1=AND&d=PTXT&s1=Nike&s2=Crypto&OS=Nike+AND+Crypto&RS=Nike+AND+Crypto" target="_blank">filed a patent application</a> for using this method to create digital assets linked to copies of actual physical shoes sold.
            </p>
            <figure>
              <img src={nike} alt="Nike filed a blockchain patent to create digital versions of its shoes to help 'users verify the authenticity of the shoes they are purchasing, among other benefits'." className={imgClasses} />
              <figcaption className={classes.textCenter}><i><b>Nike took inspiration from <a href="https://www.cryptokitties.co/" target="_blank">CryptoKitties</a></b></i></figcaption>
            </figure>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <h3 className={classes.title}>
              DAO
            </h3>
            <p>
              A <a href="https://en.wikipedia.org/wiki/Decentralized_autonomous_organization" target="_blank"><b>Decentralised Autonomous Organisation (DAO)</b></a> is an organisation that is governed by transparent rules set in computer code rather than humans. Bitcoin <b>eliminates the need</b> for a central bank to issue currency or a commercial bank to validate an online transaction. Ethereum brings much more possibilities for the <b>elimination of third-parties and human intervention</b> in online interactions and transactions.
            </p>
            <Carousel {...settings}>
              <div>
                <figure>
                  <img src={steem} alt="Steem is a social blockchain that grows communities and makes immediate revenue streams possible for users by rewarding them for sharing content." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b>STEEM offer platforms for <a href="https://steemit.com/" target="_blank">blogging</a>, <a href="https://d.tube/" target="_blank">video</a> and <a href="https://steemmonsters.com/" target="_blank">gaming</a></b></i></figcaption>
                </figure>
              </div>
              <div>
                <figure>
                  <img src={augur} alt="Augur is a no-limit betting exchange where your limits can't and won't change based on your performance." className={imgClasses} />
                  <figcaption className={classes.textCenter}><i><b><a href="https://www.augur.net/" target="_blank">AUGUR</a> offer no-limit betting</b></i></figcaption>
                </figure>
              </div>
            </Carousel>
          </GridItem>
        </GridContainer>
      }
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);