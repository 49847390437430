import { combineReducers } from 'redux';
import localeReducer from './localeReducer';
import authenticationReducer from './authenticationReducer';
import businessCardReducer from './businessCardReducer';
import requestServiceReducer from './requestServiceReducer';

const rootReducer = combineReducers({
    i18n: localeReducer,
    authentication: authenticationReducer,
    businessCard: businessCardReducer,
    requestservice: requestServiceReducer
});

export default rootReducer;
