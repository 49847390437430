/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as paths from '@app/constants/paths';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import headerLinksStyle from "@app/assets/jss/material-kit-pro-react/components/headerLinksStyle";
import LanguageSwitchButton from "../i18n/LanguageSwitchButton";

function ConnectedHeaderLinks(props) {
    const { classes } = props;


      return (
          <List className={classes.list + " " + classes.mlAuto}>
              <ListItem className={classes.listItem}>
                  <LanguageSwitchButton />
              </ListItem>
          </List>
      );
}

const mapStateToProps = state => {
    return {
        userProfile: state.authentication.userProfile
    }
}

export default connect(mapStateToProps)(injectIntl(withStyles(headerLinksStyle)(ConnectedHeaderLinks)));