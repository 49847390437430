// LANDING
export const LANDING = '/';

// FOOTER
export const ABOUT_US = '/about-us';
export const BLOG = '/blog';
export const CONTACT_US = '/contact-us';
export const COOKIE_POLICY = '/cookie-policy';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_OF_USE = '/terms-of-use';

// BLOG POSTS
export const BLOG_BITCOIN = '/blog/bitcoin';
export const BLOG_BITCOIN_GA = '/blog/bonngiotán';
export const BLOG_DOMAIN = '/blog/insane-in-the-domain-name';
export const BLOG_DOMAIN_GA = '/blog/mo-fadas-mo-fadhbs';
export const BLOG_DOMAIN_PL = '/blog/szalony-w-nazwie-domeny';