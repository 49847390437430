import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/functions';
import '@firebase/storage';

import config from 'config';

const options = {
    apiKey: config.firebaseApiKey,
    projectId: config.firebaseProjectId,
    storageBucket: config.firebaseStorageBucket
};

firebase.initializeApp(options);
const firestore = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();

export {
    firebase,
    firestore,
    storage,
    functions
};
