import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText"
import Input from "@material-ui/core/Input";
import AutosuggestInput from "./AutosuggestInput";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components

import customInputStyle from "@app/assets/jss/material-kit-pro-react/components/customInputStyle";

function CustomInput({ ...props }) {
    const {
        classes,
        formControlProps,
        labelText,
        helperText,
        id,
        inputProps,
        white,
        inputRootCustomClasses,
        error,
        success,
        suggestions,
    } = props;

    const labelClasses = classNames({
        [classes.labelRoot]: true
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
        [classes.whiteUnderline]: white
    });
    const feedbackClasses = classNames({
        [classes.feedback]: true,
        [classes.feedbackError]: error,
        [classes.labelRootError]: error,
        [classes.feedbackSuccess]: success && !error,        
        [classes.labelRootSuccess]: success && !error
    })
    const helperTextClasses = classNames({
        [classes.helperText]: true,
        [" " + classes.helperTextError]: error,
        [" " + classes.helperTextSuccess]: success && !error
    });
    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined
    });
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
        );
    } else {
        formControlClasses = classes.formControl;
    }
    return (
        <FormControl {...formControlProps} className={formControlClasses}>
            {labelText !== undefined ? (
                <InputLabel 
                    shrink={true}
                    htmlFor={id} 
                    className={labelClasses}>
                    {labelText}
                </InputLabel>
            ) : null}
            {suggestions ? (
                <AutosuggestInput 
                    inputProps={{                        
                        ...inputProps,
                        id: id
                    }}
                    inputClasses={{
                        input: inputClasses,
                        root: marginTop,
                        disabled: classes.disabled,
                        underline: underlineClasses
                    }}
                    suggestions={suggestions} />
            ) : (
            <Input 
                id={id} 
                {...inputProps} 
                classes={{
                    input: inputClasses,
                    root: marginTop,
                    disabled: classes.disabled,
                    underline: underlineClasses
                }} />
            )}
            {error ? (
                <Clear className={feedbackClasses} />
            ) : success ? (
                <Check className={feedbackClasses} />
            ) : null}
            {helperText ? (
                <FormHelperText className={helperTextClasses}>
                    {helperText}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
}

CustomInput.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    suggestions: PropTypes.array
};

export default withStyles(customInputStyle)(CustomInput);
