import React from "react";
import Fade from "@material-ui/core/Fade";
import SnackbarContent from "@app/components/Snackbar/SnackbarContent";
import Error from "@material-ui/icons/Error";

const ErrorMessage = props => {
    return (
        <Fade  in={true} mountOnEnter unmountOnExit>
            <SnackbarContent color="danger" icon={Error} message={
                <span>{props.message}</span>
            }/>
        </Fade>
    );
}

export default ErrorMessage
