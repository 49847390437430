import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import withStyles from "@material-ui/core/styles/withStyles";
import { setLocale } from '@app/state/actions';
import CustomDropdown from "@app/components/CustomDropdown/CustomDropdown";

import languageSwitchButtonStyle from "@app/assets/jss/material-kit-pro-react/components/languageSwitchButtonStyle";

class ConnectedLanguageSwitchButton extends React.Component {
    constructor(props) {
        super(props);
        this.handleLanguageSwitch = this.handleLanguageSwitch.bind(this);
    }

    handleLanguageSwitch(language) {
        if (this.props.locale !== language.key) {
            this.props.setLocale(language.key);
        }
    }

    render() {
        const { classes, locale } = this.props;
        const languageKey = "language." + locale;
        const dropdownList = [
            { key: "en", value: this.props.intl.formatMessage({ id: "language.en" }) },
            { key: "ga", value: this.props.intl.formatMessage({ id: "language.ga" }) },
            { key: "pl", value: this.props.intl.formatMessage({ id: "language.pl" }) } 
        ];        
        return (
            <CustomDropdown
                onClick={this.handleLanguageSwitch}
                buttonText={this.props.intl.formatMessage({ id: languageKey })}
                buttonProps={{
                    round: false,
                    block: true,
                    color: "transparent",
                    className: classes.dropdown
                }}
                hoverColor= "success"                    
                dropPlacement="bottom"
                dropdownList={dropdownList.filter(element => element.key !== locale)} />
        )
    }
}

const mapStateToProps = state => {
    return {
        locale: state.i18n.locale
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setLocale: locale => dispatch(setLocale(locale))
    };
};

const LanguageSwitchButton = injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(languageSwitchButtonStyle)(ConnectedLanguageSwitchButton)));
export default LanguageSwitchButton;