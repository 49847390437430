import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import Badge from "@app/components/Badge/Badge";
import Button from "@app/components/CustomButtons/Button";

import sectionBlogInfoStyle from "@app/assets/jss/material-kit-pro-react/views/blogPostSections/sectionBlogInfoStyle";

function SectionBlogInfo({ ...props }) {
  const { classes } = props;

  const post = props.intl.formatMessage({ id: "post" });
  const share = props.intl.formatMessage({ id: "share" });
  const tweet = props.intl.formatMessage({ id: "tweet" });

  const tags = props.tags
    ? props.tags.map((tag, key) => {
        return (
          <Badge color="success" key={key}>
            {tag}
          </Badge>
        );
      })
    : null;

  const redditURL =
    "https://www.reddit.com/submit?url=https%3A//sionnach.solutions" +
    props.path +
    "&title=" +
    props.title;
  //const tweetURL = "https://twitter.com/share?url=https%3A//sionnach.solutions" + props.path;
  //const fbURL = "https://www.facebook.com/sharer/sharer.php?u=https%3A//sionnach.solutions" + props.path;
  //const linkedInURL = "https://www.linkedin.com/shareArticle?mini=true&url=https%3A//sionnach.solutions" + props.path + "&title=" + props.title + "&summary=&source=";

  const fbShare =
    "http://www.facebook.com/dialog/feed?app_id=58567469885&link=https%3A//sionnach.solutions" +
    props.path +
    "&display=page&quote=&hashtag=";
  const twShare =
    "https://twitter.com/intent/tweet?text=" +
    props.title +
    "&url=https%3A//sionnach.solutions" +
    props.path;
  /*const pinShare =
    "https://pinterest.com/pin/create/bookmarklet/?url=https%3A//sionnach.solutions" +
    props.path +
    "&description=" +
    props.title +
    "&title=" +
    props.title +
    "&media=";*/
  const liShare =
    "https://www.linkedin.com/shareArticle?mini=true&url=https%3A//sionnach.solutions" +
    props.path +
    "&title=" +
    props.title +
    "&summary=" +
    props.title +
    "&source=Sionnach.Solutions";

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <div className={classes.blogTags}>{tags}</div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Button
                color="facebook"
                round
                className={classes.buttons}
                href={fbShare}
                target="_blank"
                rel="noopener"
              >
                <i className="fab fa-facebook-square" />
                {share}
              </Button>
              <Button
                color="reddit"
                round
                className={classes.buttons}
                href={redditURL}
                target="_blank"
                rel="noopener"
              >
                <i className="fab fa-reddit" />
                {post}
              </Button>
              <Button
                color="twitter"
                round
                className={classes.buttons}
                href={twShare}
                target="_blank"
                rel="noopener"
              >
                <i className="fab fa-twitter" />
                {tweet}
              </Button>
              <Button
                color="linkedin"
                round
                className={classes.buttons}
                href={liShare}
                target="_blank"
                rel="noopener"
              >
                <i className="fab fa-linkedin" />
                {share}
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

SectionBlogInfo.propTypes = {
  intl: PropTypes.object,
  classes: PropTypes.object,
  path: PropTypes.object,
  title: PropTypes.object,
  tags: PropTypes.array
};

export default injectIntl(withStyles(sectionBlogInfoStyle)(SectionBlogInfo));
