import React from "react";
import cloudFunctions from '@app/services/cloudFunctions';

import SectionFooterView from './SectionFooterView';

class SectionFooterContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newsletterEmail: '',
            isNewsletterEmailValid: null,
            validationErrors: {
                newsletterEmail: { show: false, errorMessage: '' },
            },
            isLoading: false,
            subscriptionErrorMessage: ''
        };

        this.validateNewsletterEmail = this.validateNewsletterEmail.bind(this);
        this.onNewsletterEmailChange = this.onNewsletterEmailChange.bind(this);
        this.subscribe = this.subscribe.bind(this);
    }

    validateNewsletterEmail() {
        let {
            newsletterEmail,
            isNewsletterEmailValid,
            validationErrors
        } = this.state;
        if (isNewsletterEmailValid) {
            return true;
        }
        if (!newsletterEmail) {
            isNewsletterEmailValid = false;
            validationErrors.newsletterEmail.show = true;
            validationErrors.newsletterEmail.errorMessage = 'Email is required';
        }
        this.setState({
            isNewsletterEmailValid: isNewsletterEmailValid,
            validationErrors: validationErrors
        });
        return false;
    }

    onNewsletterEmailChange(e) {
        const value = e.target.value;
        let validationErrors = this.state.validationErrors;
        let isNewsletterEmailValid = value !== '' && value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i) !== null;
        if (isNewsletterEmailValid) {
            validationErrors.newsletterEmail.errorMessage = '';
        }
        else {
            validationErrors.newsletterEmail.errorMessage = 'Email is invalid';
        }
        validationErrors.newsletterEmail.show = !isNewsletterEmailValid;
        this.setState({
            newsletterEmail: value,
            isNewsletterEmailValid: isNewsletterEmailValid,
            validationErrors: validationErrors
        });
    }

    subscribe(e) {
        if (this.validateNewsletterEmail()) {
            const {
                newsletterEmail
            } = this.state;
            this.setState({
                isLoading: true,
                subscriptionErrorMessage: ''
            });
            cloudFunctions.subscribeNewsletter({
                emailAddress: newsletterEmail 
            })
               .then(() => {
                   this.setState({
                       newsletterEmail: '',
                       isNewsletterEmailValid: null,
                       subscribed: true,
                       isLoading: false
                   });
               })
                .catch(error => {
                    const errorMessage = 'Unable to save your subscription. Try again later.';
                    console.log(errorMessage + ': ' + error.code + ': ' + error.message);
                    this.setState({
                        isLoading: false,
                        subscriptionErrorMessage: errorMessage
                    });
                });
        }
    }

    generateViewProps() {
        return {
            ...this.state,
            onNewsletterEmailChange: this.onNewsletterEmailChange,
            subscribe: this.subscribe
        };
    }

    render() {
        const viewProps = this.generateViewProps();
        return (
            <SectionFooterView {...viewProps} />
        );
    }

}

export default SectionFooterContainer;
