import React from "react";
// core components
import Header from "@app/components/Header/Header";
import HeaderLinks from "@app/components/Header/HeaderLinks";
import Brand from "@app/components/Brand/Brand";
// nodejs library to set properties for components
import PropTypes from "prop-types";

const CustomHeader = props => {
  return (
      <Header
        brand={<Brand logo={props.logo}/>}
        color="transparent"
        links={<HeaderLinks dropdownHoverColor="success"/>}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "success"
        }}
        name={props.ogSiteName}
      />
  );
}

CustomHeader.propTypes = {
  logo: PropTypes.string.isRequired
}

export default CustomHeader
