import React from 'react';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import { injectIntl } from "react-intl";
import MediaQuery from 'react-responsive';
// nodejs library that concatenates classes
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import Help from '@material-ui/icons/Help';
import Settings from '@material-ui/icons/Settings';
import TrackChanges from '@material-ui/icons/TrackChanges';
import AccessTime from '@material-ui/icons/AccessTime';
import Category from '@material-ui/icons/Category';
// core components
import CustomHelmet from "@app/components/CustomHelmets/CustomHelmet";
import CustomHeader from "@app/components/Header/CustomHeader";
import CustomTabs from "@app/components/CustomTabs/CustomTabs";
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import Parallax from "@app/components/Parallax/Parallax";

import legalPagesStyle from "@app/assets/jss/material-kit-pro-react/views/legalPagesStyle";

const CookiePolicyView = (props) => {
    const { classes } = props;

    const helmetTitle = props.intl.formatMessage({ id: "cookie.policy" });
    const metaContent = props.intl.formatMessage({ id: "cookie.policy.meta" });
    const logoAlt = props.intl.formatMessage({ id: "logo.alt" });
    const cookiePolicy = props.intl.formatMessage({ id: "cookie.policy" });
    const ogSiteName = props.intl.formatMessage({ id: "og.site.name" });

    return (
        <div>
            <CustomHelmet/>
            <PrintProvider>
                <NoPrint force>
                    <CustomHeader  ogSiteName={ogSiteName}/>
                    <MediaQuery query="(min-device-width: 0px) and (max-device-width: 599px)">
                        <Parallax image={require("@app/assets/img/bg/cookie-policy-various-biscuits-600w.jpg")} filter="rose" verySmall/>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 600px) and (max-device-width: 959px)">
                        <Parallax image={require("@app/assets/img/bg/cookie-policy-various-biscuits-960w.jpg")} filter="rose" verySmall/>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 960px) and (max-device-width: 1279px)">
                        <Parallax image={require("@app/assets/img/bg/cookie-policy-various-biscuits-1280w.jpg")} filter="rose" verySmall/>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 1280px) and (max-device-width: 1919px)">
                        <Parallax image={require("@app/assets/img/bg/cookie-policy-various-biscuits-1920w.jpg")} filter="rose" verySmall/>
                    </MediaQuery>
                    <MediaQuery query="(min-device-width: 1920px)">
                        <Parallax image={require("@app/assets/img/bg/cookie-policy-various-biscuits-2560w.jpg")} filter="rose" verySmall/>
                    </MediaQuery>
                    <div className={classes.main}>
                        <div className={classes.container}>
                            <div className={classes.section}>
                                <h1 className={classNames(classes.title, classes.textCenter)}>
                                    {cookiePolicy}
                                </h1>
                                <h2 className={classNames(classes.title, classes.textCenter)}>What's a cookie?</h2>
                                <CustomTabs
                                    headerColor="rose"
                                    tabs={[
                                        {
                                            tabName: "What",
                                            tabIcon: Help,
                                            tabContent: (
                                                <p style={{ textAlign: `left` }}>
                                                    A "cookie" is a piece of information that is stored on your computer's hard drive and which records how you move your way around a website so that, when you revisit that website, it can present tailored options based on the information stored about your last visit.
                                                    <br /><br />
                                                    Cookies can also be used to analyse traffic and for advertising and marketing purposes.
                                                    <br /><br />
                                                    Cookies are used by nearly all websites and do not harm your system.
                                                </p>
                                            )
                                        },
                                        {
                                            tabName: "Settings",
                                            tabIcon: Settings,
                                            tabContent: (
                                                <p style={{ textAlign: `left` }}>
                                                    If you want to check or change what types of cookies you accept, this can usually be altered within your browser settings.
                                                    <br /><br />
                                                    You can block cookies at any time by activating the setting on your browser that allows you to refuse the setting of all or some cookies.
                                                    <br /><br />
                                                    By not blocking cookies and continuing to browse you are authorising the use of cookies.
                                                    <br /><br />
                                                    If you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.
                                                </p>
                                            )
                                        }
                                    ]}
                                />
                                <h2 className={classes.title}>How do we use cookies?</h2>
                                <CustomTabs
                                    headerColor="rose"
                                    tabs={[
                                        {
                                            tabName: "Track",
                                            tabIcon: TrackChanges,
                                            tabContent: (
                                                <p style={{ textAlign: `left` }}>
                                                    We use cookies to track your use of our website.
                                                    <br /><br />
                                                    This enables us to understand how you use the site and track any patterns with regards how you are using our website.
                                                    <br /><br />
                                                    This helps us to develop and improve our website as well as products and / or services in response to what you might need or want.
                                                </p>
                                            )
                                        },
                                        {
                                            tabName: "Type",
                                            tabIcon: AccessTime,
                                            tabContent: (
                                                <p style={{ textAlign: `left` }}>
                                                    Cookies are either:
                                                    <br /><br />
                                                    <strong>Session cookies</strong>: these are only stored on your computer during your web session and are automatically deleted when you close your browser – they usually store an anonymous session ID allowing you to browse a website without having to log in to each page but they do not collect any personal data from your computer; or
                                                    <br /><br />
                                                    <strong>Persistent cookies</strong>: a persistent cookie is stored as a file on your computer and it remains there when you close your web browser. The cookie can be read by the website that created it when you visit that website again.
                                                </p>
                                            )
                                        },
                                        {
                                            tabName: "Category",
                                            tabIcon: Category,
                                            tabContent: (
                                                <p style={{ textAlign: `left` }}>
                                                    Cookies can also be categorised as follows:
                                                    <br /><br />
                                                    <strong>Strictly necessary cookies</strong>: These cookies are essential to enable you to use the website effectively, such as when buying a product and / or service, and therefore cannot be turned off. Without these cookies, the services available to you on our website cannot be provided. These cookies do not gather information about you that could be used for marketing or remembering where you have been on the internet.
                                                    <br /><br />
                                                    <strong>Performance cookies</strong>: These cookies enable us to monitor and improve the performance of our website. For example, they allow us to count visits, identify traffic sources and see which parts of the site are most popular.
                                                    <br /><br />
                                                    <strong>Functionality cookies</strong>: These cookies allow our website to remember choices you make and provide enhanced features. For instance, we may be able to provide you with news or updates relevant to the services you use. They may also be used to provide services you have requested such as viewing a video or commenting on a blog. The information these cookies collect is usually anonymised.
                                                </p>
                                            )
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </NoPrint>
                <Print printOnly name="foo">
                    <h1>COOKIE POLICY</h1>
                    <h2>What's a cookie?</h2>
                    <p>
                        A "<strong>cookie</strong>" is a piece of information that is stored on your computer's hard drive and which records how you move your way around a website so that, when you revisit that website, it can present tailored options based on the information stored about your last visit.
                    </p>
                    <p>
                        Cookies can also be used to analyse traffic and for advertising and marketing purposes.
                    </p>
                    <p>
                        Cookies are used by nearly all websites and do not harm your system.
                    </p>
                    <p>
                        If you want to check or change what types of cookies you accept, this can usually be altered within your browser settings.
                    </p>
                    <p>
                        You can block cookies at any time by activating the setting on your browser that allows you to refuse the setting of all or some cookies.
                    </p>
                    <p>
                        By not blocking cookies and continuing to browse you are authorising the use of cookies.
                    </p>
                    <p>
                        If you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.
                    </p>
                    <h2>How do we use cookies?</h2>
                    <p>
                        We use cookies to track your use of our website.
                    </p>
                    <p>
                        This enables us to understand how you use the site and track any patterns with regards how you are using our website.
                    </p>
                    <p>
                        This helps us to develop and improve our website as well as products and / or services in response to what you might need or want.
                    </p>
                    <p>
                        Cookies are either:
                    </p>
                    <p>
                        <strong>Session cookies</strong>: these are only stored on your computer during your web session and are automatically deleted when you close your browser – they usually store an anonymous session ID allowing you to browse a website without having to log in to each page but they do not collect any personal data from your computer; or
                    </p>
                    <p>
                        <strong>Persistent cookies</strong>: a persistent cookie is stored as a file on your computer and it remains there when you close your web browser. The cookie can be read by the website that created it when you visit that website again.
                    </p>
                    <p>
                        Cookies can also be categorised as follows:
                    </p>
                    <p>
                        <strong>Strictly necessary cookies</strong>: These cookies are essential to enable you to use the website effectively, such as when buying a product and / or service, and therefore cannot be turned off. Without these cookies, the services available to you on our website cannot be provided. These cookies do not gather information about you that could be used for marketing or remembering where you have been on the internet.
                    </p>
                    <p>
                        <strong>Performance cookies</strong>: These cookies enable us to monitor and improve the performance of our website. For example, they allow us to count visits, identify traffic sources and see which parts of the site are most popular.
                    </p>
                    <p>
                        <strong>Functionality cookies</strong>: These cookies allow our website to remember choices you make and provide enhanced features. For instance, we may be able to provide you with news or updates relevant to the services you use. They may also be used to provide services you have requested such as viewing a video or commenting on a blog. The information these cookies collect is usually anonymised.
                    </p>
                </Print>
            </PrintProvider>
        </div>
    );
}

CookiePolicyView.propTypes = {
};

export default injectIntl(withStyles(legalPagesStyle)(CookiePolicyView));
