import {
  primaryColor,
  dangerColor,
  successColor,
  blackColor,
  defaultFont
} from "@app/assets/jss/material-kit-pro-react";

const customInputStyle = {
  formControl: {
      margin: "0 0 17px 0",
      paddingTop: "27px",
      position: "relative",
      "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
          color: "#495057"
      }
  },
  labelRoot: {
      ...defaultFont,
      color: blackColor,
      fontWeight: "400",
      fontSize: "19px",
      lineHeight: "1.42857",
      top: "5px",
      "& + $underline": {
          marginTop: "0px"
      }
  },
  labelRootError: {
      color: dangerColor + " !important"
  },
  labelRootSuccess: {
      color: successColor + " !important"
  },
  disabled: {
      "&:before": {
          backgroundColor: "transparent !important"
      }
  },
  underline: {
      "&:hover:not($disabled):before,&:before": {
          borderBottomColor: "#D2D2D2 !important",
          borderBottomWidth: "1px !important"
      },
      "&:after": {
          borderBottomColor: primaryColor[1]
      }
  },
  underlineError: {
      "&:after": {
          borderBottomColor: dangerColor[1]
      }
  },
  underlineSuccess: {
      "&:after": {
          borderBottomColor: successColor[1]
      }
  },
  feedback: {
      position: "absolute",
      right: "0"
  },
  feedbackSuccess: {
      top: "32px"
  },
  feedbackError: {
      top: "32px"
  },
  helperText: {
      ...defaultFont,
      color: "#AAAAAA !important",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "1.42857",
      top: "10px",
      "& + $underline": {
          marginTop: "0px"
      }
  },
  helperTextError: {
      color: dangerColor[1] + " !important"
  },
  helperTextSuccess: {
      color: successColor[1] + " !important"
  },
  whiteUnderline: {
      "&:hover:not($disabled):before,&:before": {
          borderBottomColor: "#FFFFFF"
      },
      "&:after": {
          borderBottomColor: "#FFFFFF"
      }
  },
  input: {
      color: "#495057",
      "&,&::placeholder": {
          fontSize: "14px",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: "400",
          lineHeight: "1.42857",
          opacity: "1"
      },
      "&::placeholder": {
          color: "#AAAAAA"
      }
  },
  whiteInput: {
      "&,&::placeholder": {
          color: "#FFFFFF",
          opacity: "1"
      }
  }
};

export default customInputStyle;
