import { SET_REQUEST_SERVICE_WIZARD_DATA } from '@app/state/actions/action-types';

const INITIAL_STATE = {
    requestServiceWizardData: {
        isLoading: false,
        errorMessage: '',
        serviceRequest: null
    },
};

const requestServiceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_REQUEST_SERVICE_WIZARD_DATA: {
            return { 
                ...state, 
                requestServiceWizardData: action.requestServiceWizardData
            };
        }
        default: return state;
    }
}

export default requestServiceReducer;
