import * as paths from '@app/constants/paths';

// Landing
import LandingPage from '@app/views/LandingPage/LandingPage';
// Footer
import AboutUsPage from '@app/views/AboutUsPage/AboutUsPage';
import Blog from '@app/views/Blog/BlogPostsPage/BlogPostsPage';
import ContactUsContainer from '@app/views/ContactUsPage/ContactUsContainer';
import CookiePolicyContainer from '@app/views/CookiePolicy/CookiePolicyContainer';
import PrivacyPolicyContainer from '@app/views/PrivacyPolicy/PrivacyPolicyContainer';
import TermsOfUseContainer from '@app/views/TermsConditions/TermsOfUseContainer';
// Blog Posts
import BlogBitcoin from '@app/views/Blog/BlogPostPages/Bitcoin/BlogPostPage';
import BlogDomain from '@app/views/Blog/BlogPostPages/Domain/BlogPostPage';

const routesIndex = [
    // Footer Pages
    { path: paths.ABOUT_US, component: AboutUsPage, isPrivate: false},
    { path: paths.BLOG, component: Blog, isPrivate: false},
    { path: paths.CONTACT_US, component: ContactUsContainer, isPrivate: false},
    { path: paths.COOKIE_POLICY, component: CookiePolicyContainer, isPrivate: false},
    { path: paths.TERMS_OF_USE, component: TermsOfUseContainer, isPrivate: false},
    { path: paths.PRIVACY_POLICY, component: PrivacyPolicyContainer, isPrivate: false},

    // Blog Pages
    { path: paths.BLOG_BITCOIN, component: BlogBitcoin, isPrivate: false},
    { path: paths.BLOG_BITCOIN_GA, component: BlogBitcoin, isPrivate: false},
    { path: paths.BLOG_DOMAIN, component: BlogDomain, isPrivate: false},
    { path: paths.BLOG_DOMAIN_GA, component: BlogDomain, isPrivate: false},
    { path: paths.BLOG_DOMAIN_PL, component: BlogDomain, isPrivate: false}
];

const defaultRoute = { component: LandingPage, isPrivate: false };

export { routesIndex, defaultRoute };
