import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import cardHeaderStyle from "@app/assets/jss/material-kit-pro-react/components/cardHeaderStyle";

function CardHeader({ ...props }) {
    const {
        classes,
        className,
        children,
        color,
        plain,
        image,
        signin,
        signup,
        home,
        postad,
        profile,
        noShadow,
        ...rest
    } = props;
    const cardHeaderClasses = classNames({
        [classes.cardHeader]: true,
        [classes[color + "CardHeader"]]: color,
        [classes.cardHeaderPlain]: plain,
        [classes.cardHeaderImage]: image,
        [classes.cardHeaderSignIn]: signin,
        [classes.cardHeaderSignUp]: signup,
        [classes.cardHeaderHome]: home,
        [classes.cardHeaderPostAd]: postad,
        [classes.cardHeaderProfile]: profile,
        [classes.noShadow]: noShadow,
        [className]: className !== undefined
    });
    return (
        <div className={cardHeaderClasses} {...rest}>
            {children}
        </div>
    );
}

CardHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    color: PropTypes.oneOf([
        "warning",
        "success",
        "danger",
        "info",
        "primary",
        "rose"
    ]),
    plain: PropTypes.bool,
    image: PropTypes.bool,
    signin: PropTypes.bool,
    signup: PropTypes.bool,
    home: PropTypes.bool,
    postad: PropTypes.bool,
    profile: PropTypes.bool,
    noShadow: PropTypes.bool
};

export default withStyles(cardHeaderStyle)(CardHeader);
