import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import cardStyle from "@app/assets/jss/material-kit-pro-react/components/cardStyle";

function Card({ ...props }) {
    const {
        classes,
        className,
        children,
        plain,
        raised,
        signin,
        signup,
        home,
        postad,
        profile,
        thumbnail,
        thumbnailbusinesscard,
        background,
        color,
        ...rest
    } = props;
    const cardClasses = classNames({
        [classes.card]: true,
        [classes.cardPlain]: plain,
        [classes.cardRaised]: raised,
        [classes.cardSignIn]: signin,
        [classes.cardSignUp]: signup,
        [classes.cardHome]: home,
        [classes.cardPostAd]: postad,
        [classes.cardProfile]: profile,
        [classes.cardThumbnail]: thumbnail,
        [classes.cardThumbnailBusinessCard]: thumbnailbusinesscard,
        [classes.cardBackground]: background,
        [classes[color]]: color,
        [className]: className !== undefined
    });
    return (
        <div className={cardClasses} {...rest}>
            {children}
        </div>
    );
}

Card.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    plain: PropTypes.bool,
    raised: PropTypes.bool,
    signin: PropTypes.bool,
    signup: PropTypes.bool,
    home: PropTypes.bool,
    postad: PropTypes.bool,
    profile: PropTypes.bool,
    thumbnail: PropTypes.bool,
    thumbnailbusinesscard: PropTypes.bool,
    background: PropTypes.bool,
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose"
    ]),
    product: PropTypes.bool
};

export default withStyles(cardStyle)(Card);
