import {
    primaryColor,
    primaryBoxShadow
} from "@app/assets/jss/material-kit-pro-react.jsx";

const autosuggestInputStyle = theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'inherit'
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    suggestion: {
        margin: "0 5px",
        borderRadius: "2px",
        display: 'block',
        "&:hover": {
            backgroundColor: primaryColor[1] + " !important",
            color: "#FFFFFF !important",
            ...primaryBoxShadow
        }
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none'
    },
    paper: {
        padding: "5px 0",
        margin: "0",
        borderRadius: "4px",
    }
});

export default autosuggestInputStyle;
