import { SET_USER_CREDENTIAL, SET_USER_PROFILE } from '@app/state/actions/action-types';


const INITIAL_STATE = {
    userCredential: null,
    userProfile: null
};

const authenticationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_CREDENTIAL: {
            return {
                ...state,
                userCredential: action.userCredential
            };
        }
        case SET_USER_PROFILE: {
            return {
                ...state,
                userProfile: action.userProfile
            };
        }
        default: return state;
    }
}

export default authenticationReducer;
