import React from 'react';
import PrivacyPolicyView from './PrivacyPolicyView';

class PrivacyPolicyContainer extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <PrivacyPolicyView />
        );
    }
}

export default PrivacyPolicyContainer;
