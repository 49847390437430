import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import cardFooterStyle from "@app/assets/jss/material-kit-pro-react/components/cardFooterStyle";

function CardFooter({ ...props }) {
    const {
        classes,
        className,
        children,
        plain,
        signin,
        signup,
        postad,
        profile,
        ...rest
    } = props;
    const cardFooterClasses = classNames({
        [classes.cardFooter]: true,
        [classes.cardFooterPlain]: plain,
        [classes.cardFooterSignIn]: signin,
        [classes.cardFooterSignUp]: signup,
        [classes.cardFooterPostAd]: postad,
        [classes.cardFooterProfile]: profile,
        [className]: className !== undefined
    });
    return (
        <div className={cardFooterClasses} {...rest}>
            {children}
        </div>
    );
}

CardFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    plain: PropTypes.bool,
    signin: PropTypes.bool,
    signup: PropTypes.bool,
    postad: PropTypes.bool,
    profile: PropTypes.bool
};

export default withStyles(cardFooterStyle)(CardFooter);