import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
// Typography
import Quote from "@app/components/Typography/Quote";
// images
import dns from "@app/assets/img/blog/domain/xn--fgra-ypa6a-ie-dns-996w.webp";
import screenshot from "@app/assets/img/blog/domain/fógraí.ie-gabháil-scáileáin-leathanach-lamairne-1234w.webp";
import email from "@app/assets/img/blog/domain/ó-eolas-xn--fgra-ypa6a-ie-566w.webp";
import chromium from "@app/assets/img/blog/domain/apple-v-apple-chromium-570w.webp";
import firefox from "@app/assets/img/blog/domain/apple-v-apple-firefox-520w.webp";
import users from "@app/assets/img/blog/domain/global-internet-usage.jpg";
import usersGa from "@app/assets/img/blog/domain/úsáideoirí-idirlín-domhanda.jpg";
import map from "@app/assets/img/blog/domain/ainmneacha-fearainn-idirnáisiúnaithe-mapa-áis-eoraip-afraic-thuaidh-1600w.webp";
import emojis from "@app/assets/img/blog/domain/emojis-in-domain-names-a-security-risk-for-everyone-1600w.webp";

import sectionTextStyle from "@app/assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle";

function SectionText({ ...props }) {
  const { classes } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      {props.lang === 'ga' && 
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={9}>
            <h2 className={classes.title + ' ' + classes.textCenter}>Mo Fada's Mo Fadhbs</h2>
            <p>I mí na Márta 2018, bhí mé réidh leis an oscailt suas do <i>seoltaí gréasáin</i> [web addresses] <b>.ie</b> d'<i>aonáin neamhthráchtála</i> [non-commercial entities]. Uaidh sin amach, le cruthúnas aitheantas Éireannach, bhí aon duine inann seoladh gréasáin <b>.ie</b> a chlarú. Ní raibh mé réidh don iarracht breise a bheadh ag teastáil do mo rogha seoladh gréasáin, fograi.ie.</p>
            <p>Go gairid t'éis fograi.ie a chlarú leis an sár <i>cláraitheoir</i> [registrar] letshost.ie, thosaigh mé ag smaoineamh ar síntí fada. Go sciobtha fuair mé amach go raibh síntí fada (agus go leor carachtair eile) <i>incháilithe</i> [eligible] i seoltaí gréasáin le beagnach 10 mbliana.</p>
            <h3 className={classes.title}>fógraí.ie</h3>
            <p>Bhí mé sásta go raibh mé inann an leagan ceart and cruinn den seoladh gréasáin a chlárú, fógraí.ie. Ní raibh mé sásta go mbeadh orm ioc chun an dara seoladh gréasáin a chlárú don suíomh gréasáin amháin. Ach chláraigh mé é. Is ansin a thosaigh an <i>chraic</i>.</p>
            <figure>
              <img src={dns} alt="Gabháil scáileáin de Socruithe Córas Ainmneacha Fearainn fógraí.ie" className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>Socruithe DNS fógraí.ie</b>
              </figcaption>
            </figure>
            <h3 className={classes.title}>xn--fgra-ypa6a.ie</h3>
            <p>Ní cuimhin liom an chéad uair a thug mé faoi deara é. Is dóigh gur bhfaca mé é roinnt uaireanta ach nár thug mé aird air. Áfach, go luath, fuair sé mo aire. Ansin, dhírigh mé air. Is é xn--fgra-ypa6a.ie a bḣí cláraithe. Ní fógraí.ie, ach xn--fgra-ypa6a.ie. 'nios, sula dtosaíonn sé ag breathnú go bhfuair mé sceamáilte ag letshsot.ie (níl mé ach t'éis sár a ghlaoch orthu) lig dom cúpla rud a shoiléiriú. Ar dtús, bhréag mé, beagán. Bhí <i>fógraí.ie</i> cláraithe ach is sa bhfoirm <i>xn--fgra-ypa6a.ie</i> a bhí sé cláraithe.</p>
            <h3 className={classes.title}>DNS</h3>
            <p>Tá <i>freastalaí</i> [servers] <i>Córas Ainmneacha Fearainn (CAF)</i> [Domain Name System (DNS)] ag rith <i>seolachán</i> [addressing] ar an idirlíon ó na '80idí. Is é <i>ainm fearainn</i> [domain name] an terma níos cruinne nó teicniúl atá i geist nuair a lathraím faoi seoladh gréasáin. Athraíonn na freastalaithe CAF an téacs i ainmneacha fearainn, m.s., fograi.ie chuig <i>uimhirchóras</i> [number system], m.s., 17.142.160.59, rud a thugtar seoladh IP (Prótacail Idirlín [Internet Protocol]) air. Ní thuigeann na freastalaí CAF ach na carachtair a-z, 0–9 agus fleiscín.</p>
            <figure>
              <img src={screenshot} alt="Gabháil scáileáin de leathanach lamairne fógraí.ie" className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>Tá cuma mhaith ar rudaí i Firefox</b>
              </figcaption>
            </figure>
            <h3 className={classes.title}>Isteach le Punycode</h3>
            <p><i>Comhréir ionchódú aistrigh</i> [transfer encoding system] atá simplí agus <i>éifeachtúil</i> [efficient]. Athraíonn Punycode carachtair neamhbhun-laidineach go dtí carachtair bun-laidineach i mbealach atá <i>inchúlaithe</i> [reversible] agus a grcuthaíonn <i>teaghrán uathúil</i> [unique string]. Is mar seo a n-athraítear fógraí.ie go dtí xn--fgra-ypa6a.ie, rud a dtuigeann na freastalaí CAF.</p>
            <h3 className={classes.title}>r-phost</h3>
            <p>Feistithe le mo dhá ainm fearainn, fograi.ie agus fógraí.ie, thosaigh mé ar an tasc <i>soláthraí</i> [provider] ríomhphoist a roghnú. Phioc mé GSuite Google (<i>go héifeachtúil</i> [effectively] GMail do ghnó) go príomha mar gheall ar an praghas agus freisin mar go raibh mé <i>cleachta</i> [accustomed] le GMail. Leis an misneach a bhí agam t'éis fógraí.ie a fheicéail i <i>mbarra seoltaí</i> [address bar] an <i>brabhsálaí gréasáin</i> [web browser], <i>chlaon</i> [deviated] mé uaidh info@fograi.ie chuig eolas@fógraí. Ar ndóigh! Nach iontach an chraic a bheadh leis! (Cuimhnigh gur gheall mé craic níos luaithe). Ghlac GSuite le eolas@fógraí.ie gan aon fhadhb, nó, sin a cheap mé.</p>
            <h3 className={classes.title}>xn--f</h3>
            <p>Go teicniúil, ní raibh aon fhadhb, bhí gach rud ag oibriú i gceart. Bhí mé inann ríomhphoist a sheoladh agus a fháil le eolas@fógraí.ie. Ach bhí fadhb. Fadhb <i>xn--f</i> mór dar liom! Le gach ríomhphost a sheoladh uaidh eoals@fógraí.ie bhí seoltóir: eolas@xn--fgra-ypa6a.ie le feciéal ag an <i>faighteoir</i> [recipient], ní eolas@fógraí.ie mar a bḣí siúl agam.</p>
            <figure>
              <img src={email} alt="Gabháil scáileáin de rphost Microsoft" className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>Níl Hotmail/Outlook ag úsáid Punycode fós</b>
              </figcaption>
            </figure>
            <p>Thosaigh Gmail ag tacú le carachtair taobh amuigh de a-z i seoltaí ríomhphoist sa bhliain 2014. Thosaigh Microsoft ag cuir tacaíochta i bhfeidhm sa mbliain 2018.</p>
            <h3 className={classes.title}>Spúfáil</h3>
            <p>Bhí imní ar lucht slándáil roimh oscailt ainmneacha fearainn do carachtair taobh amuigh de a-z de bharr imní árdú ar ainmneacha fearainn (nó  ríomhphost) <i>faoi bhréagriocht</i> [spoofing]. Mar shampla an bfhecieann tú an difríocht idir an а beag san aibítir <i>Choireallach</i> [Cyrillic] agus an a beag i mBéarla? Cóipeáil an dá nasc seo go bfhecieadh tú an difríocht.</p>
            <Quote
              textClassName={classes.quoteText}
              text="аpple.com v apple.com"
            />
            <p>D'fhéadfadh lucht ríomhphiost <i>sceamáil</i> [scamming] suíomh a chlarú mar аpple.com leis an а beag san aibítir Choireallach chun <i>scéim cham</i> [scam] a thrial ar úsáideoirí an fíor apple.com.</p>
            <figure>
              <img src={chromium} alt="Gabháil scáileáin san brabhsálaí Chromium" className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>аpple.com v apple.com sa brabhsálaí Chromium</b>
              </figcaption>
            </figure>
            <figure>
              <img src={firefox} alt="Gabháil scáileáin san brabhsálaí Firefox" className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>аpple.com v apple.com sa brabhsálaí Firefox</b>
              </figcaption>
            </figure>
            <p>Bheadh sé deacair nó <i>dodhéanta</i> [impossible] ainm fearainn a chlarú le meascán litreacha don aibítir Choireallach agus aibítir na Laidine. Tá rialacha i bhfeidhm ag gach <i>iarmhír fearainn</i> [domain suffix]. Ní ligeann <b>.ie</b> na hÉireann ach na síntí fada á, é, í, ó agus ú le breise.</p>
            <p>Ní raibh teangaí ar nós an Ghaeilge mórán ar intinn nuair a thosaigh obair <i>idirnáisiúnú</i> [internationalisation] ar ainmneacha fearainn. Is chun idirlíon atá <i>inrochtan</i> [accessible] do gach duine ar domhan a chruthú an cúis atá leis. Ag deireadh an bhlian 2000, bhí 26% den 360 milliún duine a bhí ar líne suite sna Stáit Aontaithe Mheiriceá. I 2019, is iad an tSín agus an Ind atá chun tosaigh le os cionn 30% den níos mó ná 4 billiún duine atá ar líne. Tá os cionn 50% de dhaoine atá ar líne suite san Áis.</p>
            <figure>
              <img src={usersGa} alt="Grafaic faisnéise de na trí thír is mó le úsáideora idirlín sna blianta 2000 agus 2019." className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>Tá an chuid is mó de úsáideoirí an idirlíon suite san Áis<br/><a href="https://www.internetworldstats.com" target="_blank">InternetWorldStats</a></b>
              </figcaption>
            </figure>
            <h3 className={classes.title}>ICANN</h3>
            <p>Is iad ICANN (Internet Corporation for Assigned Number and Names) a dhéanann bainistiú ar Chóras Ainmneacha Fearainn an t-idirlíon. Is féidir le aon duine páirt a ghlacadh i <i>sainiúil</i> [defining] polasaí ICANN. Tá ICANN <i>cíocrach</i> [eager] chun ligeann do gach duine ar domhan <i>rochtain</i> [access] a fháil ar an idirlíon ina dteanga áitiúil.</p>
            <figure>
              <img src={map} alt="Mapa grafaic faisnéise den Áis, An Eoraip agus an Afraic Thuaidh." className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>Ainmneacha Fearainn Idirnáisiúnaithe - Samplaí i mBéarla agus an legan áitiúil. <a href="https://globalbydesign.com/internationalized-domain-names/" target="_blank">GlobalByDesign</a>.</b>
              </figcaption>
            </figure>
            <h3 className={classes.title}>Fearainn Emojis</h3>
            <p>Buíochas le Punycode, is féidir emojis a úsáid in ainmneacha fearainn. Cuireann ICANN cosc ar úsáid emojis in ainmneacha fearainn mar go <i>maíonn</i> [claim] siad go bhfuil siad contúirteach. Deireann ICANN go bhfuil sé deacair emojis a aithint óna chéile agus ní thaispeánann siad mar an gcéanna ar gach ardán.</p>
            <figure>
              <img src={emojis} alt="Grafaic faisnéise ag tasipéaint contúirtí a bhaineann le emoji's in ainmneacha fearainn." className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>Slioct as <i>Emojis in Domain Names: A Security Risk for Everyone</i> ar fáil uaidh <a href="https://www.icann.org/en/system/files/files/idn-emojis-domain-names-13feb19-en.pdf" target="_blank">ICANN&nbsp;(mar&nbsp;<i>pdf</i>)</a>.</b>
              </figcaption>
            </figure>
            <Quote
              textClassName={classes.quoteText}
              text="Ní chloíonn roinnt fearainn go hiomlán leis an gcaighdeán ICANN agus ligeann siad emojis inár ainmneacha fearainn: .cf, .ga, .gq, .ml, .tk, .st, .fm, .to, .je, .gg, .kz, .com.kz, .org.kz, .ws"
            />
            <p>Ó tháinig tú go dtí seo, b'fhéidir go mbeadh spéis agat i <a href="https://meetings.icann.org/en/montreal66" target="_blank">gCruinniú Ginearálta Bliantúil ICANN</a> a bheidh ar siúl idir 2–7 Samhain 2019 i Montreal Canada. Tá cruinnithe ICANN saor in aisce agus oscailte don phobal, ach moltar go láidir <a href="https://events.icann.org/icann66-montreal/registration/Site/Register" target="_blank">réamhchlárú</a>.</p>
          </GridItem>
        </GridContainer>
      }
      {props.lang != 'ga' &&
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={9}>
            <h2 className={classes.title + ' ' + classes.textCenter}>Insane In The Domain Name</h2>
            <p>In March 2018, I was ready for the opening up of .ie domains to non-commercial entities. Now, with proof of Irish id, anyone could register any available .ie domain (rather than just their name as before). I wasn’t ready for the extra efforts that my domain name choice, fograi.ie, would need.</p>
            <p>Fograi (actually it’s <i><b>fógraí</b></i>) is the Irish word for announcements/notifications/adverts. Soon after registering fograi.ie with the superb registrar <a href="https://www.letshost.ie/" target="_blank">letshost.ie</a>, I began to wonder about síneadh fada’s. A síneadh fada is an acute accent on vowels (á, é, í, ó, ú) in Irish. I quickly discovered that síneadh fada’s (and a whole lot more characters besides) became eligible in domain names in the early 2010s.</p>
            
            <h3 className={classes.title}>fógraí.ie</h3>
            <p>I was happy that I could get the real and proper version of the domain registered, <a href="https://fógraí.ie/" target="_blank">fógraí.ie</a>. I wasn’t so happy about having to pay to register a second domain for one website. However, I did register it. So that’s when the <i>fun</i> began.</p>
            <figure>
              <img src={dns} alt="Screencap of the Domain Name System (DNS) settings of fógraí.ie." className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>Domain Name System (DNS) settings for fógraí.ie</b>
              </figcaption>
            </figure>
            
            <h3 className={classes.title}>xn--fgra-ypa6a.ie</h3>
            <p>I don’t recall when I first noticed it. I probably saw it a few times but didn’t give it any attention. However, soon, it got my attention. Then it became my focus. <a href="https://xn--fgra-ypa6a.ie/" target="_blank">xn--fgra-ypa6a.ie</a> is what was registered. Not fógraí.ie, but xn--fgra-ypa6a.ie! Now before it starts to sound like I got scammed by letshost.ie (I did call them superb) let me clarify a few things. Firstly, I lied, but only a little. I had registered fógraí.ie but xn--fgra-ypa6a.ie is <i>how</i> it is registered.</p>
            
            <h3 className={classes.title}>Domain Name System (DNS)</h3>
            <p>DNS servers have run addressing on the internet since the ’80s. DNS servers convert text web domains, e.g., fograi.ie to a numbered system, e.g., 17.178.96.59 known as Internet Protocol (IP) addresses. These DNS servers were designed to only accommodate the 26 Latin characters, a-z, the digits 0–9 and the hyphen character.</p>
            <figure>
              <img src={screenshot} alt="Screencap of the landing page of fógraí.ie in the Firefox web browser and fógraí.ie displayed correctly in the browser wed address field." className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>Things look good in Firefox</b>
              </figcaption>
            </figure>
            
            <h3 className={classes.title}>Enter Punycode</h3>
            <p>Punycode is a <i>simple and efficient transfer encoding syntax</i>. It <b>uniquely</b> and <b>reversibly transforms</b> non-basic Latin text into basic Latin text. This encoding process is how <i>fógraí.ie</i> ends up as <i>xn--fgra-ypa6a.ie</i>, which the good ole DNS servers can understand.</p>
            
            <h3 className={classes.title}>em@il</h3>
            <p>Equipped with my two domains, fograi.ie and fógraí.ie, I went about the task of getting an email address. Primarily for its familiarity and affordability, I decided upon using GSuite, basically a business version of Google’s Gmail. Buoyed by the sight of fógraí.ie in the web browser address bar, I deviated from the original plan of info@fograi.ie and went instead for eolas@fógraí.ie. Of course I did! What fun! (Remember I promised you some of that earlier).</p>
            
            <h3 className={classes.title}>xn--f</h3>
            <p>That’s when it happened. I saw it again. Every time! In every <b>xn--f</b> email I sent, there it was.</p>
            <Quote
              textClassName={classes.quoteText}
              text="from: eolas@xn--fgra-ypa6a.ie"
            />
            <p>            
              Even if such an email does not get <b>spam</b> filtered, it’s a wholly unreasonable expectation on the recipient to be able to identify it from a scam email.</p>
            <figure>
              <img src={email} alt="Screencap from the Microsoft email web app which has the sender email dispalyed as: <eolas@xn--fgra-ypa6a.ie>" className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>Hotmail/Outlook were not using Punycode in 2018</b>
              </figcaption>
            </figure>
            <p>Gmail started supporting non-basic Latin characters in email addresses in <a href="https://googleblog.blogspot.com/2014/08/a-first-step-toward-more-global-email.html" target="_blank">2014</a>. Microsoft’s support started to roll out in <a href="https://techcommunity.microsoft.com/t5/Exchange-Team-Blog/EAI-support-announcement/ba-p/607595" target="_blank">2018</a>.</p>
            
            <h3 className={classes.title}>Domain Spoofing</h3>
            <p><b>Domain spoofing</b> was a concern of security experts before the internationalisation of domain names. Would you notice the difference between the ‘а’ in the <b>Cyrillic</b> alphabet and the ‘a’ in the <b>Latin</b> alphabet? Try these two links to see the difference:</p>
            <Quote
              textClassName={classes.quoteText}
              text="аpple.com v apple.com"
            />
            <p>Scammers could look to register Cyrillic alphabet ‘а’ аpple.com as part of a scheme to target users of the real apple.com.</p>
            <figure>
              <img src={chromium} alt="Screencap of the Chromium web browser" className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>аpple.com v apple.com in the Chromium web browser</b>
              </figcaption>
            </figure>
            <figure>
              <img src={firefox} alt="Screencap of the Firefox web browser" className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>аpple.com v apple.com in the Firefox web browser</b>
              </figcaption>
            </figure>
            <p>It would be difficult or impossible to register such a domain as Cyrillic alphabet ‘а’ аpple.com. There are blocks on mixing characters from different alphabets in domains. Each domain suffix has its own rules, known as <a href="https://tools.ietf.org/html/rfc8228" target="_blank">Label Generation Rulesets</a>. Ireland’s .ie domain suffix additionally allows the Irish accented characters (á, é, í, ó, ú) only.</p>
            <figure>
              <img src={users} alt="Grafaic faisnéise de na trí thír is mó le úsáideora idirlín sna blianta 2000 agus 2019." className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>The majority of internet users reside in Asia.<br/>Source: <a href="https://www.internetworldstats.com" target="_blank">InternetWorldStats</a></b>
              </figcaption>
            </figure>
            <p>My domain name pain is a prime example of an (English) <a href="https://en.wikipedia.org/wiki/First_World_problem" target="_blank">First World problem</a>. The reason for internationalising domain names is to create an equally accessible internet for all of the world’s citizens. At the end of the year 2000, 26% of the 360 million global internet users resided in the USA. In 2019, China and India lead the way accounting for more than a 30% share of the over 4 billion global internet users. Asia overall accounts for over 50% of the global total.</p>
            
            <h3 className={classes.title}>ICANN</h3>
            <p>The <a href="https://www.icann.org/" target="_blank">Internet Corporation for Assigned Number and Names</a> (ICANN) manage the internet’s Domain Name System (DNS). ICANN policy is driven by its community, which consists of anyone who wants to be involved. ICANN is eager to allow people around the world to access domain names in their local languages.</p>
            <figure>
              <img src={map} alt="Internationalized Domain Names — Some examples in English with equivalent native script." className={imgClasses} width="100%"/>
              <figcaption className={classes.textCenter}>
                <b>Internationalized Domain Names — Some examples in English with equivalent native script.<br/>Reference: <a href="https://globalbydesign.com/internationalized-domain-names/" target="_blank">GlobalByDesign</a>.</b>
              </figcaption>
            </figure>
            
            <h3 className={classes.title}>Emoji Domains</h3>
            <p>Punycode also allows the use of emojis in domain names. ICANN prohibits emojis in domain names as it considers them <a href="https://www.icann.org/en/system/files/files/idn-emojis-domain-names-13feb19-en.pdf" target="_blank" type="pdf">risky</a>. ICANN says that emojis can be hard to tell apart and don’t display the same on each platform.</p>
            <figure>
              <img src={emojis} alt="Graphic from ICANN indicating the risks with regard to emojis in domain names." className={imgClasses} width="100%" longdesc="https://www.icann.org/en/system/files/files/idn-emojis-domain-names-13feb19-en.pdf"/>
              <figcaption className={classes.textCenter}>
                <b>Extract from <i>Emojis in Domain Names: A Security Risk for Everyone</i> available from <a href="https://www.icann.org/en/system/files/files/idn-emojis-domain-names-13feb19-en.pdf" target="_blank">ICANN&nbsp;(as&nbsp;a&nbsp;<i>pdf</i>)</a>.</b>
              </figcaption>
            </figure>
            <Quote
              textClassName={classes.quoteText}
              text="Not all domains heed ICANN warnings and allow emojis in domain names: .cf, .ga, .gq, .ml, .tk, .st, .fm, .to, .je, .gg, .kz, .com.kz, .org.kz, .ws"
            />
            <p>Since you have come this far you might be interested in the ICANN <a href="https://meetings.icann.org/en/" target="_blank">meetings</a> which are open to the public but registration is recommended.</p>
          </GridItem>
        </GridContainer>
      }
    </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);