import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

const ConnectedPrivateRoute = ({ component: Component, ...rest }) => {    
    return (
        <Route {...rest} render={props => (<Component {...props} />)} />
    )
};

const mapStateToProps = state => {
    return {
        userCredential: state.authentication.userCredential
    }
}

const PrivateRoute = connect(mapStateToProps)(ConnectedPrivateRoute);
export default PrivateRoute;
