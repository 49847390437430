import React from 'react';
import { Helmet } from "react-helmet";
// nodejs library to set properties for components
import PropTypes from "prop-types";

const CustomHelmet = props => {
    return (
        <Helmet>
            {props.htmlLang ? <html lang={props.htmlLang}/> : ''}
            {props.helmetTitle ? <title>{props.helmetTitle}</title> : ''}
            {props.canonical ? <link rel="canonical" href={props.canonical} /> : ''}
            {props.metaDescription ? <meta name="description" content={props.metaDescription}/> : ''}
            {props.robots ? <meta name="robots" content={props.robots}/> : ''}
            
            {/* website, music, video, article, book, profile */}
            {props.ogType ? <meta property="og:type" content={props.ogType}/> : ''}
            {/* Name which should be displayed for the overall site */}
            {props.ogSiteName ? <meta property="og:site_name" content={props.ogSiteName}/> : ''}
            {/* Title of this object */}
            {props.ogTitle ? <meta property="og:title" content={props.ogTitle}/> : ''}
            {/* One to two sentence description of this object */}
            {props.ogDescription ? <meta property="og:description" content={props.ogDescription}/> : ''}
            {/* Canonical URL of this object that will be used as its permanent ID */}
            {props.ogURL ? <meta property="og:url" content={props.ogURL}/> : ''}
            {/* Locale these tags are marked up in */}
            {props.ogLocale ? <meta property="og:locale" content={props.ogLocale}/> : ''}
            {/* Other locales this page is available in */}
            {props.ogLocaleAlt1 ? <meta property="og:locale:alternate" content={props.ogLocaleAlt1}/> : ''}
            {props.ogLocaleAlt2 ? <meta property="og:locale:alternate" content={props.ogLocaleAlt2}/> : ''}
            {/* URL to a video file that complements this object */}
            {/* Image URL which should represent this object */}
            {props.ogImage ? <meta property="og:image" content={props.ogImage}/> : ''}
            {/* A MIME type for this image */}
            {props.ogImageType ? <meta property="og:image:type" content={props.ogImageType}/> : ''}
            {props.ogImageWidth ? <meta property="og:image:width" content={props.ogImageWidth}/> : ''}
            {props.ogImageHeight ? <meta property="og:image:height" content={props.ogImageHeight}/> : ''}
            {/* A description of what is in the image (not a caption) */}
            {props.ogImageAlt ? <meta property="og:image:alt" content={props.ogImageAlt}/> : ''}
            {props.ogVideo ? <meta property="og:video" content={props.ogVideo}/> : ''}
            {props.ogVideoType ? <meta property="og:video:type" content={props.ogVideoType}/> : ''}
            {props.ogVideoWidth ? <meta property="og:video:width" content={props.ogVideoWidth}/> : ''}
            {props.ogVideoHeight ? <meta property="og:video:height" content={props.ogVideoHeight}/> : ''}
            {/* URL to an audio file that complements this object */}
            {props.ogAudio ? <meta property="og:audio" content={props.ogAudio}/> : ''}
            {/* A MIME type for this audio */}
            {props.ogAudioType ? <meta property="og:audio:type" content={props.ogAudioType}/> : ''}
            {/* OG type article */}
            {/* Date.prototype.toISOString() */}
            {props.ogArticlePublishedTime ? <meta property="og:article:published_time" content={props.ogArticlePublishedTime}/> : ''}
            {props.ogArticleModifiedTime ? <meta property="og:article:modified_time" content={props.ogArticleModifiedTime}/> : ''}
            {props.ogArticleExpirationTime ? <meta property="og:article:expiration_time" content={props.ogArticleExpirationTime}/> : ''}
            {props.ogArticleAuthor ? <meta property="og:article:author" content={props.ogArticleAuthor}/> : ''}
            {/* A high-level section name e.g. Technology */}
            {props.ogArticleSection ? <meta property="og:article:section" content={props.ogArticleSection}/> : ''}
            {/* Tag words associated with this article */}
            {props.ogArticleTag1 ? <meta property="og:article:tag" content={props.ogArticleTag}/> : ''}
            {props.ogArticleTag2 ? <meta property="og:article:tag" content={props.ogArticleTag}/> : ''}
            {props.ogArticleTag3 ? <meta property="og:article:tag" content={props.ogArticleTag}/> : ''}
            {props.ogArticleTag4 ? <meta property="og:article:tag" content={props.ogArticleTag}/> : ''}
            {props.ogArticleTag5 ? <meta property="og:article:tag" content={props.ogArticleTag}/> : ''}
            {props.ogArticleTag6 ? <meta property="og:article:tag" content={props.ogArticleTag}/> : ''}
            {/* OG type profile */}
            {props.ogProfileFirstName ? <meta property="og:profile:first_name" content={props.ogProfileFirstName}/> : ''}
            {props.ogProfileLastName ? <meta property="og:profile:last_name" content={props.ogProfileLastName}/> : ''}
            {props.ogProfileUsername ? <meta property="og:profile:username" content={props.ogProfileUsername}/> : ''}
            
            {/* Title of content (max 70 characters) */}
            {props.twitterTitle ? <meta name="twitter:title" content={props.twitterTitle}/> : ''}
            {/* Description of content (maximum 200 characters) */}
            {props.twitterDescription ? <meta name="twitter:description" content={props.twitterDescription}/> : ''}
            {/* summary, summary_large_image */}
            {props.twitterCardType ? <meta name="twitter:card" content={props.twitterCardType}/> : ''}
            {/* @username of website */}
            {props.twitterSite ? <meta name="twitter:site" content={props.twitterSite}/> : ''}
            {/* @username of content creator */}
            {props.twitterCreator ? <meta name="twitter:creator" content={props.twitterCreator}/> : ''}
            {/* URL of image. Must be less than 5MB. */}
            {/* JPG, PNG, WEBP and (A)GIF (1st frame) */}
            {/* summary: aspect ratio of 1:1, min 144x144, max 4096x4096 */}
            {/* summary_large_image: aspect ratio of 2:1, min 300x157, max 4096x4096 */}
            {props.twitterImage ? <meta name="twitter:image" content={props.twitterImage}/> : ''}
            {/* Convey the essential nature of an image to users who are visually impaired */}
            {/* Maximum 420 characters */}
            {props.twitterImageAlt ? <meta name="twitter:image:alt" content={props.twitterImageAlt}/> : ''}
            {/* HTTPS URL of player iframe */}
            {/* Default to ‘sound off’ for videos that automatically play content */}
            {/* Content greater than 10 seconds in length must not automatically play */}
            {/* Include stop, pause and play controls */}
            {props.twitterPlayer ? <meta name="twitter:player" content={props.twitterPlayer}/> : ''}
            {/* Width of iFrame specified in twitter:player in pixels */}
            {props.twitterPlayerWidth ? <meta name="twitter:player:width" content={props.twitterPlayerWidth}/> : ''}
            {/* Height of iFrame specified in twitter:player in pixels */}
            {props.twitterPlayerHeight ? <meta name="twitter:player:height" content={props.twitterPlayerHeight}/> : ''}
            
            {/* Microsoft OS App Tile */}
            {props.msTileColor ? <meta name="msapplication-TileColor" content={props.msTileColor}/> : ''}
            {props.themeColor ? <meta name="theme-color" content={props.themeColor}/> : ''}
            {props.appName ? <meta name="application-name" content={props.appName}/> : ''}
        </Helmet>
    );
}

CustomHelmet.defaultProps = {
    htmlLang: 'en_IE',
    twitterCreator: 'momarta'
};

CustomHelmet.propTypes = {
    htmlLang: PropTypes.oneOf(['en_IE', 'ga_IE', 'pl_PL']).isRequired,
    helmetTitle: PropTypes.string.isRequired,
    canonical: PropTypes.string.isRequired,
    metaDescription: PropTypes.string.isRequired,

    ogType: PropTypes.oneOf(['website', 'music', 'video', 'article', 'book', 'profile']).isRequired,
    ogDescription: PropTypes.string,
    ogURL: PropTypes.string.isRequired,

    ogLocale: PropTypes.string.isRequired,
    ogLocaleAlt1: PropTypes.string,
    ogLocaleAlt2: PropTypes.string,

    ogImage: PropTypes.string.isRequired,
    ogImageType: PropTypes.oneOf(['image/png', 'image/jpeg', 'image/gif']).isRequired,
    ogImageWidth: PropTypes.number.isRequired,
    ogImageHeight: PropTypes.number.isRequired,
    ogImageAlt: PropTypes.string.isRequired,

    ogVideo: PropTypes.string,
    ogVideoWidth: PropTypes.number,
    ogVideoHeight: PropTypes.number,

    ogAudio: PropTypes.string,

    ogArticlePublishedTime: PropTypes.string,
    ogArticleModifiedTime: PropTypes.string,
    ogArticleExpirationTime: PropTypes.string,
    ogArticleAuthor: PropTypes.string,
    ogArticleSection: PropTypes.string,
    ogArticleTag1: PropTypes.string,
    ogArticleTag2: PropTypes.string,
    ogArticleTag3: PropTypes.string,
    ogArticleTag4: PropTypes.string,
    ogArticleTag5: PropTypes.string,
    ogArticleTag6: PropTypes.string,
    ogProfileFirstName: PropTypes.string,
    ogProfileLastName: PropTypes.string,
    ogProfileUsername: PropTypes.string,

    twitterTitle: PropTypes.string,
    twitterDescription: PropTypes.string,
    twitterCardType: PropTypes.oneOf(['summary', 'summary_large_image']),
    twitterSite: PropTypes.string,
    twitterCreator: PropTypes.string,
    twitterImage: PropTypes.string,
    twitterImageAlt: PropTypes.string,
    twitterPlayer: PropTypes.string,
    twitterPlayerWidth: PropTypes.string,
    twitterPlayerHeight: PropTypes.string,

    appName: PropTypes.string.isRequired,
    msTileColor: PropTypes.string,
    themeColor: PropTypes.string
};

export default CustomHelmet
