import React from 'react';
import CookiePolicyView from './CookiePolicyView';

class CookiePolicyContainer extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <CookiePolicyView />
        );
    }
}

export default CookiePolicyContainer;
