import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import cardBodyStyle from "@app/assets/jss/material-kit-pro-react/components/cardBodyStyle";

function CardBody({ ...props }) {
    const {
        classes,
        className,
        children,
        background,
        plain,
        formHorizontal,
        thumbnail,
        color,
        textCenter,
        ...rest
    } = props;
    const cardBodyClasses = classNames({
        [classes.cardBody]: true,
        [classes.textCenter]: textCenter,
        [classes.cardBodyBackground]: background,
        [classes.cardBodyPlain]: plain,
        [classes.cardBodyFormHorizontal]: formHorizontal,
        [classes.cardBodyColor]: color,
        [classes.cardBodyThumbnail]: thumbnail,
        [className]: className !== undefined
    });
    return (
        <div className={cardBodyClasses} {...rest}>
            {children}
        </div>
    );
}

CardBody.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    background: PropTypes.bool,
    textCenter: PropTypes.bool,
    plain: PropTypes.bool,
    formHorizontal: PropTypes.bool,
    thumbnail: PropTypes.bool,
    color: PropTypes.bool
};

export default withStyles(cardBodyStyle)(CardBody);
