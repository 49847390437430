import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { injectIntl } from "react-intl";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Email from "@material-ui/icons/Email";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import LinearProgress from "@material-ui/core/LinearProgress";
// core components
import Brand from "@app/components/Brand/Brand";
import Header from "@app/components/Header/Header";
import HeaderLinks from "@app/components/Header/HeaderLinks";
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import InfoArea from "@app/components/InfoArea/InfoArea";
import CustomInput from "@app/components/CustomInput/CustomInput";
import Button from "@app/components/CustomButtons/Button";
import Badge from "@app/components/Badge/Badge";

import ErrorMessage from "@app/views/ErrorPage/ErrorMessage";

import contactUsStyle from "@app/assets/jss/material-kit-pro-react/views/contactUsStyle";

const CustomSkinMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: 53.24109, lng: -9.458762 }}
      defaultOptions={{
        fullscreenControl: false,
        mapTypeControl: false,
        scrollwheel: false,
        streetViewControl: false,
        zoomControl: false,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          {
            featureType: "poi.park",
            stylers: [{ visibility: "on" }]
          },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          {
            featureType: "poi.medical",
            stylers: [{ visibility: "on" }]
          },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={props.mapPosition} />
    </GoogleMap>
  ))
);

const ContactUsView = props => {
  const classes = props.classes;
  const nameSuccess = props.isNameValid;
  const nameError = props.validationErrors.name.show && !props.isNameValid;
  const nameHelperText = props.validationErrors.name.show
    ? props.validationErrors.name.errorMessage
    : "";
  const emailSuccess = props.isEmailValid;
  const emailError = props.validationErrors.email.show && !props.isEmailValid;
  const emailHelperText = props.validationErrors.email.show
    ? props.validationErrors.email.errorMessage
    : "";
  const phoneSuccess = props.isPhoneValid;
  const phoneError = props.validationErrors.phone.show && !props.isPhoneValid;
  const phoneHelperText = props.validationErrors.phone.show
    ? props.validationErrors.phone.errorMessage
    : "";
  const messageSuccess = props.isMessageValid;
  const messageError =
    props.validationErrors.message.show && !props.isMessageValid;
  const messageHelperText = props.validationErrors.message.show
    ? props.validationErrors.message.errorMessage
    : "";

  const contactUs = props.intl.formatMessage({ id: "contact.us" });
  const sendMessage = props.intl.formatMessage({ id: "send.us.a.message" });
  const sentSuccessMessage = props.intl.formatMessage({
    id: "send.success.message"
  });
  const yourName = props.intl.formatMessage({ id: "your.name" });
  const emailAddress = props.intl.formatMessage({ id: "email.address" });
  const phone = props.intl.formatMessage({ id: "phone" });
  const yourMessage = props.intl.formatMessage({ id: "your.message" });
  const send = props.intl.formatMessage({ id: "send" });
  const ireland = props.intl.formatMessage({ id: "ireland" });
  const canada = props.intl.formatMessage({ id: "canada" });
  const postalAddress = props.intl.formatMessage({ id: "postal.address" });
  const legalName = props.intl.formatMessage({ id: "legal.name" });
  const sionnachBuiLimited = props.intl.formatMessage({ id: "sionnach.bui.limited" });
  const sionnachSolutions = props.intl.formatMessage({ id: "sionnach.solutions.quebec" });

  return (
    <div>
      <Helmet>
        <title>
          {window.location.hostname} {contactUs}
        </title>
        <meta
          name="description"
          content="Our contact details and a form to send us a message"
        />
      </Helmet>
      <Header
        brand={<Brand />}
        links={<HeaderLinks />}
        color="transparent"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "success"
        }}
      />
      <div className={classes.bigMap}>
        <CustomSkinMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCEUEcM1SsexIIWea1q4TcAt2_D9B_6mLI"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              style={{
                height: `100%`,
                borderRadius: "6px",
                overflow: "hidden"
              }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
          mapPosition={props.mapPosition}
        />
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>{sendMessage}</h2>
            {props.errorMessage && (
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <ErrorMessage message={props.errorMessage} />
                </GridItem>
              </GridContainer>
            )}
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.progress}>
                  {props.isLoading && <LinearProgress />}
                </div>
                {props.messageAccepted && (
                  <Badge color="success">
                    <h3
                      className={classNames(
                        classes.title,
                        classes.messageAccepted
                      )}
                    >
                      {sentSuccessMessage}
                    </h3>
                  </Badge>
                )}
                {!props.messageAccepted && !props.isLoading && (
                  <form name="contactus" onSubmit={props.onSubmit}>
                    <CustomInput
                      id="name"
                      labelText={yourName}
                      helperText={nameHelperText}
                      success={nameSuccess}
                      error={nameError}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "name",
                        value: props.name,
                        onChange: props.onNameChange
                      }}
                    />
                    <CustomInput
                      id="email"
                      labelText={emailAddress}
                      helperText={emailHelperText}
                      success={emailSuccess}
                      error={emailError}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "email",
                        value: props.email,
                        onChange: props.onEmailChange
                      }}
                    />
                    <CustomInput
                      id="phone"
                      labelText={phone}
                      helperText={phoneHelperText}
                      success={phoneSuccess}
                      error={phoneError}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: "phone",
                        value: props.phone,
                        onChange: props.onPhoneChange
                      }}
                    />
                    <CustomInput
                      id="message"
                      labelText={yourMessage}
                      helperText={messageHelperText}
                      success={messageSuccess}
                      error={messageError}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 6,
                        name: "message",
                        value: props.message,
                        onChange: props.onMessageChange
                      }}
                    />
                    <div className={classes.textCenter}>
                      <Button
                        aria-label={send}
                        type="submit"
                        color="success"
                        round
                      >
                        {send}
                      </Button>
                    </div>
                  </form>
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InfoArea
                  className={classes.info}
                  title={postalAddress}
                  description={
                    <p>
                      H91 VH4C
                      <br />
                      <b>{ireland}</b>
                      <br />
                      546 Rue Therien,
                      <br />
                      Montréal,
                      <br />
                      QC H4C 3A4
                      <br/>
                      <b>{canada}</b>
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="success"
                />
                <InfoArea
                  className={classes.info}
                  title={legalName}
                  description={<p>{sionnachBuiLimited}<br />{sionnachSolutions}</p>}
                  icon={BusinessCenter}
                  iconColor="success"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

ContactUsView.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  validationErrors: PropTypes.object.isRequired,

  onNameChange: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onPhoneChange: PropTypes.func.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default injectIntl(withStyles(contactUsStyle)(ContactUsView));
