import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from "react-redux";
import { routesIndex, defaultRoute } from './routes/index'
import PrintProvider, { NoPrint } from 'react-easy-print';
import store from './state/store';
import DynamicIntlProvider from './components/i18n/DynamicIntlProvider'
import PrivateRoute from './components/Route/PrivateRoute';
import SectionFooterContainer from "@app/views/Footer/SectionFooterContainer";

import './assets/css/index.css';
import "./assets/scss/material-kit-pro-react.scss?v=1.8.0";
import './assets/css/all.css';
import './assets/css/font.css';

import './assets/manifest.json';
import './assets/browserconfig.xml';

import './assets/img/favicon.ico';
import './assets/img/favicons/favicon-32.png';
import './assets/img/favicons/favicon-57.png';
import './assets/img/favicons/favicon-76.png';
import './assets/img/favicons/favicon-96.png';
import './assets/img/favicons/favicon-120.png';
import './assets/img/favicons/favicon-128.png';
import './assets/img/favicons/favicon-144.png';
import './assets/img/favicons/favicon-152.png';
import './assets/img/favicons/favicon-180.png';
import './assets/img/favicons/favicon-195.png';
import './assets/img/favicons/favicon-196.png';
import './assets/img/favicons/favicon-228.png';
import './assets/img/favicons/smalltile.png';
import './assets/img/favicons/mediumtile.png';
import './assets/img/favicons/largetile.png';

ReactDOM.render(
    <Provider store={store} >
        <DynamicIntlProvider>
            <Router>
                <div>
                    <Switch>
                        {routesIndex.map((route, key) => {
                            return <PrivateRoute exact path={route.path} key={key} component={route.component} isPrivate={route.isPrivate} />
                        })}
                        {defaultRoute &&
                            <PrivateRoute component={defaultRoute.component} isPrivate={defaultRoute.isPrivate} />
                        }
                    </Switch>
                    <PrintProvider>
                        <NoPrint force>
                            <SectionFooterContainer/>
                        </NoPrint>
                    </PrintProvider>
                </div>
            </Router>
        </DynamicIntlProvider>
    </Provider>,
    document.getElementById('app')
);
