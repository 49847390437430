import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/icons
import Mail from "@material-ui/icons/Mail";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import { Link } from "react-router-dom";
import ErrorMessage from "@app/components/Error/ErrorMessage";
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import Button from "@app/components/CustomButtons/Button";
import CustomInput from "@app/components/CustomInput/CustomInput";
import Footer from "@app/components/Footer/Footer";
import Clearfix from "@app/components/Clearfix/Clearfix";
// styles
import footerStyle from "@app/assets/jss/material-kit-pro-react/components/footerStyle";

const SectionFooterView = props => {
  const { classes } = props;
  const yourEmail = props.intl.formatMessage({ id: "your.email" });
  const send = props.intl.formatMessage({ id: "send" });
  const newsletterEmailSuccess = props.isNewsletterEmailValid;
  const newsletterEmailError =
    props.validationErrors.newsletterEmail.show &&
    !props.isNewsletterEmailValid;
  const newsletterEmailHelperText = props.validationErrors.newsletterEmail.show
    ? props.validationErrors.newsletterEmail.errorMessage
    : "";

  return (
    <div className={classes.section}>
      <Footer
        theme="white"
        content={
          <div>
            <div className={classNames(classes.pullCenter, classes.copyRight)}>
              <FormattedMessage id="copyright" />
              &nbsp;&copy;&nbsp;{1900 + new Date().getYear()}{" "}
              <Link to="/">Sionnach Solutions</Link>
              <br />
              <FormattedMessage id="all.rights.reserved" />
            </div>
          </div>
        }
      >
        <div className={classes.footer}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <h5>
                <FormattedMessage id="about" />
              </h5>
              <ul className={classes.linksVertical}>
                <li>
                  <Link to="/about-us">
                    <FormattedMessage id="about.us" />
                  </Link>
                </li>
                <li>
                  <Link to="/blog">
                    <FormattedMessage id="blog" />
                  </Link>
                </li>
              </ul>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <h5>
                <FormattedMessage id="contact" />
              </h5>
              <ul className={classes.linksVertical}>
                <li>
                  <Link to="/contact-us">
                    <FormattedMessage id="contact.us" />
                  </Link>
                </li>
              </ul>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <h5>
                <FormattedMessage id="legal" />
              </h5>
              <ul className={classes.linksVertical}>
                <li>
                  <Link to="/privacy-policy">
                    <FormattedMessage id="privacy.policy" />
                  </Link>
                </li>
                <li>
                  <Link to="/cookie-policy">
                    <FormattedMessage id="cookie.policy" />
                  </Link>
                </li>
                <li>
                  <Link to="/terms-of-use">
                    <FormattedMessage id="terms.of.use" />
                  </Link>
                </li>
              </ul>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <h5>
                <FormattedMessage id="stats.for.geeks" />
              </h5>
              <p>
                <FormattedMessage id="display.size" />:{" "}
                {window.screen.width * window.devicePixelRatio} x{" "}
                {window.screen.height * window.devicePixelRatio}
                <br />
                <FormattedMessage id="pixel.ratio" />: {window.devicePixelRatio}
                <br />
                <FormattedMessage id="color.depth" />:{" "}
                {window.screen.colorDepth}
                <br />
                <FormattedMessage id="operating.system" />: {navigator.platform}
                <br />
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </Footer>
    </div>
  );
};

export default injectIntl(withStyles(footerStyle)(SectionFooterView));
