import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

import autosuggestInputStyle from "@app/assets/jss/material-kit-pro-react/components/autosuggestInputStyle.jsx";

class AutosuggestInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: []
        };

        this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
        this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.getSuggestionValue = this.getSuggestionValue.bind(this);
        this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
        this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
    };

    shouldRenderSuggestions(value) {
        return true;
    }

    renderSuggestionsContainer(options) {
        if (options.children) return (
            <Paper {...options.containerProps} square className={this.props.classes.paper}>
                {options.children}
            </Paper>
        )
    }

    renderInputComponent(inputProps) {
        const { classes, ref, ...otherInputProps } = inputProps;
        return (
            <Input
                {...otherInputProps}
                inputRef={node => {
                    ref(node);
                }}
                classes={classes} />
        );
    }

    renderSuggestion(suggestion, { query, isHighlighted }) {
        return (
            <MenuItem selected={isHighlighted} component="div" className={this.props.classes.suggestion}>
                <div>
                    {suggestion}
                </div>
            </MenuItem>
        );
    }
    
    getSuggestionValue(suggestion) {
        return suggestion;
    }

    handleSuggestionsFetchRequested(value) {
        const inputValue = value.value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let suggestions = this.props.suggestions;
        if (inputLength > 0) {
            suggestions = suggestions.filter(suggestion => {
                return suggestion.slice(0, inputLength).toLowerCase() === inputValue;
            });
        }
        this.setState({
            suggestions: suggestions,
        });
    };

    handleSuggestionsClearRequested() {
        this.setState({
            suggestions: [],
        });
    };

    render() {
        const { classes, inputClasses, inputProps } = this.props;
        const autosuggestProps = {
            suggestions: this.state.suggestions,
            shouldRenderSuggestions: this.shouldRenderSuggestions,
            renderSuggestionsContainer: this.renderSuggestionsContainer,
            renderInputComponent: this.renderInputComponent,
            renderSuggestion: this.renderSuggestion,
            getSuggestionValue: this.getSuggestionValue,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested
        };
        return (
            <Autosuggest
                {...autosuggestProps}
                inputProps={{
                    ...inputProps,
                    classes: inputClasses
                }}
                theme={{
                    container: classes.container,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}/>
        );
    }
}

AutosuggestInput.propTypes = {
    classes: PropTypes.object.isRequired,
    inputClasses: PropTypes.object,
    inputProps: PropTypes.object
};

export default withStyles(autosuggestInputStyle)(AutosuggestInput);
