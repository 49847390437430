import React from "react";
import { injectIntl } from "react-intl";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import Badge from "@app/components/Badge/Badge";
import Button from "@app/components/CustomButtons/Button";
import Card from "@app/components/Card/Card";
import CardAvatar from "@app/components/Card/CardAvatar";

import authorMom from "@app/assets/img/blog/author-mom.jpg";

import sectionBlogAuthorStyle from "@app/assets/jss/material-kit-pro-react/views/blogPostSections/sectionBlogInfoStyle";

function SectionBlogAuthor({ ...props }) {
  const { classes } = props;

  const findOnTwitter = props.intl.formatMessage({ id: "find.on.twitter" });

  return (
    <div className={classes.section}>
      <hr />
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card plain profile className={classes.card}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={3}>
                <CardAvatar plain profile>
                  <img src={authorMom} alt="Máirtín Ó Márta" />
                </CardAvatar>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <h4 className={classes.cardTitle}>Máirtín Ó Márta</h4>
                <p className={classes.description}>
                  An t-údar.
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Button 
                  href="https://twitter.com/momarta"
                  color="twitter">
                  <i className="fab fa-twitter" />{findOnTwitter}
                </Button>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default injectIntl(withStyles(sectionBlogAuthorStyle)(SectionBlogAuthor));
