import React from "react";
import { injectIntl } from "react-intl";
import MediaQuery from "react-responsive";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import Card from "@app/components/Card/Card";
import CardHeader from "@app/components/Card/CardHeader";
import CardBody from "@app/components/Card/CardBody";

import productStyle from "@app/assets/jss/material-kit-pro-react/views/landingPageSections/productStyle";

import dioltaS from "@app/assets/img/logos/diolta-250w.webp";
import dioltaM from "@app/assets/img/logos/diolta-300w.webp";
import dioltaL from "@app/assets/img/logos/diolta-350w.webp";
import dionS from "@app/assets/img/logos/dion-250w.webp";
import dionM from "@app/assets/img/logos/dion-300w.webp";
import dionL from "@app/assets/img/logos/dion-350w.webp";
import fograiS from "@app/assets/img/logos/fograi-250w.webp";
import fograiM from "@app/assets/img/logos/fograi-300w.webp";
import fograiL from "@app/assets/img/logos/fograi-350w.webp";
import localProSEN from "@app/assets/img/logos/LocalPro-en-600w.jpg";
import localProMEN from "@app/assets/img/logos/LocalPro-en-960w.jpg";
import localProLEN from "@app/assets/img/logos/LocalPro-en-1280w.jpg";
import localProSGA from "@app/assets/img/logos/LocalPro-ga-600w.jpg";
import localProMGA from "@app/assets/img/logos/LocalPro-ga-960w.jpg";
import localProLGA from "@app/assets/img/logos/LocalPro-ga-1280w.jpg";
import localProSPL from "@app/assets/img/logos/LocalPro-pl-600w.jpg";
import localProMPL from "@app/assets/img/logos/LocalPro-pl-960w.jpg";
import localProLPL from "@app/assets/img/logos/LocalPro-pl-1280w.jpg";

function SectionProduct(props) {
  const classes = props;

  const subTitle = props.intl.formatMessage({
    id: "landing.twitter.description"
  });

  const localPro = props.intl.formatMessage({
    id: "landing.local.pro.tagline"
  });

  const diolta = props.intl.formatMessage({
    id: "landing.diolta.tagline"
  });

  const dion = props.intl.formatMessage({
    id: "landing.dion.tagline"
  });

  const fograi = props.intl.formatMessage({
    id: "landing.fograi.tagline"
  });
  

  const currentLang = props.currentLang;

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={9}>
          <h2 className={classes.title}>{subTitle}</h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Card profile plain>
              <CardHeader image plain>
                <a href="https://localpro.ie" target="_blank">
                  {currentLang === "en" && (
                    <img
                      id="LocalPro"
                      src={require("@app/assets/img/logos/LocalPro-" +
                        currentLang +
                        "-600w.jpg")}
                      srcSet={
                        localProSEN +
                        " 600w," +
                        localProMEN +
                        " 960w," +
                        localProLEN +
                        " 1280w"
                      }
                      sizes="100vw"
                      alt="LocalPro.ie"
                    />
                  )}
                  {currentLang === "ga" && (
                    <img
                      id="LocalPro"
                      src={require("@app/assets/img/logos/LocalPro-" +
                        currentLang +
                        "-600w.jpg")}
                      srcSet={
                        localProSGA +
                        " 600w," +
                        localProMGA +
                        " 960w," +
                        localProLGA +
                        " 1280w"
                      }
                      sizes="100vw"
                      alt="LocalPro.ie"
                    />
                  )}
                  {currentLang === "pl" && (
                    <img
                      id="LocalPro"
                      src={require("@app/assets/img/logos/LocalPro-" +
                        currentLang +
                        "-600w.jpg")}
                      srcSet={
                        localProSPL +
                        " 600w," +
                        localProMPL +
                        " 960w," +
                        localProLPL +
                        " 1280w"
                      }
                      sizes="100vw"
                      alt="LocalPro.ie"
                    />
                  )}
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${localProLEN})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <h4 className={classes.cardTitle}>LocalPro.ie</h4>
                <h6
                  className={
                    classes.cardCategory + " " + classes.cardDescription
                  }
                >
                  {localPro}
                </h6>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <Card profile plain>
              <CardHeader image plain>
                <a href="https://díon.ie" target="_blank">
                  <img
                    id="dion"
                    src={require("@app/assets/img/logos/dion-350w.jpg")}
                    srcSet={
                      dionS + " 250w," + dionM + " 300w," + dionL + " 350w"
                    }
                    sizes="100vw"
                    alt="díon.ie"
                  />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${dionL})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <h4 className={classes.cardTitle}>díon.ie</h4>
                <h6
                  className={
                    classes.cardCategory + " " + classes.cardDescription
                  }
                >
                  {dion}
                </h6>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <Card profile plain>
              <CardHeader image plain>
                <a href="https://díolta.ie" target="_blank">
                  <img
                    id="diolta"
                    src={require("@app/assets/img/logos/diolta-350w.jpg")}
                    srcSet={
                      dioltaS +
                      " 250w," +
                      dioltaM +
                      " 300w," +
                      dioltaL +
                      " 350w"
                    }
                    sizes="100vw"
                    alt="díolta.ie"
                  />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${dioltaL})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <h4 className={classes.cardTitle}>díolta.ie</h4>
                <h6
                  className={
                    classes.cardCategory + " " + classes.cardDescription
                  }
                >
                  {diolta}
                </h6>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <Card profile plain>
              <CardHeader image plain>
                <a href="https://fógraí.ie" target="_blank">
                  <img
                    id="fograi"
                    src={require("@app/assets/img/logos/fograi-350w.jpg")}
                    srcSet={
                      fograiS +
                      " 250w," +
                      fograiM +
                      " 300w," +
                      fograiL +
                      " 350w"
                    }
                    sizes="100vw"
                    alt="fógraí.ie"
                  />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${fograiL})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <h4 className={classes.cardTitle}>fógraí.ie</h4>
                <h6
                  className={
                    classes.cardCategory + " " + classes.cardDescription
                  }
                >
                  {fograi}
                </h6>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default injectIntl(withStyles(productStyle)(SectionProduct));
