import React from "react";
import { injectIntl } from "react-intl";
import MediaQuery from 'react-responsive';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Euro from "@material-ui/icons/EuroSymbol";
import Adb from "@material-ui/icons/Adb";
// core components
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import Card from "@app/components/Card/Card";
import CardBody from "@app/components/Card/CardBody";
import CardHeader from "@app/components/Card/CardHeader";
import Success from "@app/components/Typography/Success";
import Danger from "@app/components/Typography/Danger";

import dataWorthXXS from "@app/assets/img/blog/data-worth-300w.jpg";
import dataWorthXS from "@app/assets/img/blog/data-worth-600w.jpg";
import dataWorthSM from "@app/assets/img/blog/data-worth-960w.jpg";
import dataWorthMD from "@app/assets/img/blog/data-worth-1280w.jpg";
import dataWorthLG from "@app/assets/img/blog/data-worth-1920w.jpg";
import dataWorthXL from "@app/assets/img/blog/data-worth-2560w.jpg";
import dataWorthGAXXS from "@app/assets/img/blog/fiúchas-sonraí-300w.jpg";
import dataWorthGAXS from "@app/assets/img/blog/fiúchas-sonraí-600w.jpg";
import dataWorthGASM from "@app/assets/img/blog/fiúchas-sonraí-960w.jpg";
import dataWorthGAMD from "@app/assets/img/blog/fiúchas-sonraí-1280w.jpg";
import dataWorthGALG from "@app/assets/img/blog/fiúchas-sonraí-1920w.jpg";
import dataWorthGAXL from "@app/assets/img/blog/fiúchas-sonraí-2560w.jpg";
import calibraphateXXS from "@app/assets/img/blog/calibraphate-300w.jpg";
import calibraphateXS from "@app/assets/img/blog/calibraphate-600w.jpg";
import calibraphateSM from "@app/assets/img/blog/calibraphate-960w.jpg";
import calibraphateMD from "@app/assets/img/blog/calibraphate-1280w.jpg";
import calibraphateLG from "@app/assets/img/blog/calibraphate-1920w.jpg";
import calibraphateXL from "@app/assets/img/blog/calibraphate-2560w.jpg";

import sectionNextStyle from "@app/assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle";

function SectionNext({ ...props }) {
  const { classes } = props;

  const comingNext = props.intl.formatMessage({ id: "blog.coming.next" });
  const dataWorthAlt = props.intl.formatMessage({ id: "blog.data.worth.image.alt" });
  const howMuchDataWorth = props.intl.formatMessage({ id: "blog.how.much.data.worth" });
  const dataWorthIntro = props.intl.formatMessage({ id: "blog.data.worth.intro" });
  const dataMonetisation = props.intl.formatMessage({ id: "data.monetisation" });
  const socialMediaism = props.intl.formatMessage({ id: "social.mediaism" });
  const socialMediaismImageAlt = props.intl.formatMessage({ id: "blog.calibraphate.image.alt" });
  const calibraphateTitle = props.intl.formatMessage({ id: "blog.calibraphate.title" });
  const calibraphateIntro = props.intl.formatMessage({ id: "blog.calibraphate.intro" });

  const dataBody = (
    <CardBody plain>
    <Success>
      <Euro
        fontSize="large"
      />
      <h3 className={classes.title}>
         {dataMonetisation}
      </h3>
    </Success>
    <h4 className={classes.cardTitle}>
      <a href="#">
        {howMuchDataWorth}
      </a>
    </h4>
    <h5 className={classes.description}>
      {dataWorthIntro}
    </h5>
  </CardBody>
  );

  const calibraBody = (
    <CardBody plain>
    <Danger>
      <Adb
        fontSize="large"
      />
      <h3 className={classes.title}>
       {socialMediaism}
      </h3>
    </Danger>
    <h4 className={classes.cardTitle}>
      {calibraphateTitle}
    </h4>
    <h5 className={classes.description}>
      {calibraphateIntro}
    </h5>
  </CardBody>
  );

  return (
    <div className={classes.section}>
      <h2 className={classes.title + " " + classes.textCenter}>
        {comingNext}
      </h2>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card plain>
            <MediaQuery query="(min-device-width: 0px) and (max-device-width: 599px)">
              <CardHeader image plain>
                {props.lang === 'ga' && 
                  <img src={dataWorthGAXXS} alt={dataWorthAlt} />
                }
                {props.lang != 'ga' && 
                  <img src={dataWorthXXS} alt={dataWorthAlt} />
                }
              </CardHeader>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 600px) and (max-device-width: 959px)">
              <CardHeader image plain>
                {props.lang === 'ga' && 
                  <img src={dataWorthGAXXS} alt={dataWorthAlt} />
                }
                {props.lang != 'ga' && 
                  <img src={dataWorthXXS} alt={dataWorthAlt} />
                }
              </CardHeader>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 960px) and (max-device-width: 1279px)">
              <CardHeader image plain>
                {props.lang === 'ga' && 
                  <img src={dataWorthGAXS} alt={dataWorthAlt} />
                }
                {props.lang != 'ga' && 
                  <img src={dataWorthXS} alt={dataWorthAlt} />
                }
              </CardHeader>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 1280px) and (max-device-width: 1919px)">
              <CardHeader image plain>
                {props.lang === 'ga' && 
                  <img src={dataWorthGAXS} alt={dataWorthAlt} />
                }
                {props.lang != 'ga' && 
                  <img src={dataWorthXS} alt={dataWorthAlt} />
                }
              </CardHeader>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 1920px)">
              <CardHeader image plain>
                {props.lang === 'ga' && 
                  <img src={dataWorthGASM} alt={dataWorthAlt} />
                }
                {props.lang != 'ga' && 
                  <img src={dataWorthSM} alt={dataWorthAlt} />
                }
              </CardHeader>
            </MediaQuery>
            {dataBody}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card plain>
            <MediaQuery query="(min-device-width: 0px) and (max-device-width: 599px)">
              <CardHeader image plain>
                <img src={calibraphateXXS} alt={socialMediaismImageAlt} />
              </CardHeader>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 600px) and (max-device-width: 959px)">
              <CardHeader image plain>
                <img src={calibraphateXXS} alt={socialMediaismImageAlt} />
              </CardHeader>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 960px) and (max-device-width: 1279px)">
              <CardHeader image plain>
                <img src={calibraphateXS} alt={socialMediaismImageAlt} />
              </CardHeader>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 1280px) and (max-device-width: 1919px)">
              <CardHeader image plain>
                <img src={calibraphateXS} alt={socialMediaismImageAlt} />
              </CardHeader>
            </MediaQuery>
            <MediaQuery query="(min-device-width: 1920px)">
              <CardHeader image plain>
                <img src={calibraphateSM} alt={socialMediaismImageAlt} />
              </CardHeader>
            </MediaQuery>
            {calibraBody}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default injectIntl(withStyles(sectionNextStyle)(SectionNext));