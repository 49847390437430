import { functions } from './firebase';


function addContactUsMessage(request) {
    const callable = functions.httpsCallable('notifications-addContactUsMessage');
    return callable(request);
}

const cloudFunctions = {
    addContactUsMessage
}
export default cloudFunctions;
