import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import MediaQuery from 'react-responsive';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import CustomHelmet from "@app/components/CustomHelmets/CustomHelmet";
import CustomHeader from "@app/components/Header/CustomHeader";
import Parallax from "@app/components/Parallax/Parallax";
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
// sections for this page
import SectionText from "./Sections/SectionText";
import SectionBlogTagsShare from "../../BlogPostsPage/Sections/SectionBlogTagsShare";
import SectionBlogAuthor from "../../BlogPostsPage/Sections/SectionBlogAuthor";
import SectionCurrent from "../../BlogPostsPage/Sections/SectionCurrent";
import SectionNext from "../../BlogPostsPage/Sections/SectionNext";

import blogPostPageStyle from "@app/assets/jss/material-kit-pro-react/views/blogPostPageStyle";
class BlogPostPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;

    const currentLang = this.props.locale;

    const canonical = this.props.intl.formatMessage({ id: "blog.domain.canonical" });
    const ogTitle = this.props.intl.formatMessage({ id: "blog.domain.og.title" });
    const ogDescription = this.props.intl.formatMessage({ id: "blog.domain.og.description" });
    const ogType = this.props.intl.formatMessage({ id: "blog.domain.og.type" });
    const ogArticlePublishedTime = this.props.intl.formatMessage({ id: "blog.domain.og.article.published.time" });
    const ogArticleModifiedTime = this.props.intl.formatMessage({ id: "blog.domain.og.article.modified.time" });
    const ogArticleAuthor = this.props.intl.formatMessage({ id: "blog.domain.og.article.author" });
    const ogArticleSection = this.props.intl.formatMessage({ id: "blog.domain.og.article.section" });
    const ogArticleTag1 = this.props.intl.formatMessage({ id: "blog.domain.og.article.tag1" });
    const ogArticleTag2 = this.props.intl.formatMessage({ id: "blog.domain.og.article.tag2" });
    const ogArticleTag3 = this.props.intl.formatMessage({ id: "blog.domain.og.article.tag3" });
    const ogArticleTag4 = this.props.intl.formatMessage({ id: "blog.domain.og.article.tag4" });
    const ogArticleTag5 = this.props.intl.formatMessage({ id: "blog.domain.og.article.tag5" });
    const ogSiteName = this.props.intl.formatMessage({ id: "blog.domain.og.site.name" });
    const ogLocale = this.props.intl.formatMessage({ id: "blog.domain.og.locale" });
    const ogLocaleAlt1 = this.props.intl.formatMessage({ id: "blog.domain.og.localeAlt1" });
    const ogLocaleAlt2 = this.props.intl.formatMessage({ id: "blog.domain.og.localeAlt2" });
    const ogImage = this.props.intl.formatMessage({ id: "blog.domain.og.image" });
    const ogImageType = this.props.intl.formatMessage({ id: "blog.domain.og.image.type" });
    const ogImageWidth = this.props.intl.formatMessage({ id: "blog.domain.og.image.width" });
    const ogImageHeight = this.props.intl.formatMessage({ id: "blog.domain.og.image.height" });
    const ogImageAlt = this.props.intl.formatMessage({ id: "blog.domain.og.image.alt" });
    const twitterTitle = this.props.intl.formatMessage({ id: "blog.domain.twitter.title" });
    const twitterDescription = this.props.intl.formatMessage({ id: "blog.domain.twitter.description" });
    const twitterCardType = this.props.intl.formatMessage({ id: "blog.domain.twitter.card.type" });
    const twitterCreator = this.props.intl.formatMessage({ id: "blog.domain.twitter.creator" });
    const twitterImage = this.props.intl.formatMessage({ id: "blog.domain.twitter.image" });
    const twitterImageAlt = this.props.intl.formatMessage({ id: "blog.domain.twitter.image.alt" });

    const blogTitle = this.props.intl.formatMessage({ id: "blog.domain.title" });
    const blogSubTitle = this.props.intl.formatMessage({ id: "blog.domain.subtitle" });
    const blogSubTitleKicker = this.props.intl.formatMessage({ id: "blog.domain.subtitle.kicker" });

    const tags = [
      this.props.intl.formatMessage({ id: "blog.domain.og.article.tag1" }),
      this.props.intl.formatMessage({ id: "blog.domain.og.article.tag2" }),
      this.props.intl.formatMessage({ id: "blog.domain.og.article.tag3" }),
      this.props.intl.formatMessage({ id: "blog.domain.og.article.tag4" })
    ];

    const parallaxContent = (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={8} className={classes.textCenter}>
            <h1 className={classes.title}>
              {blogTitle}
            </h1>
            <MediaQuery query="(min-device-height: 580px)">
              <h2 className={classes.subtitle}>
                {blogSubTitle}
              </h2>
            </MediaQuery>
            <MediaQuery query="(min-device-height: 720px)">
              <h3 className={classes.subtitle}>
                {blogSubTitleKicker}
              </h3>
            </MediaQuery>
          </GridItem>
        </GridContainer>
      </div>
    );

    return (
      <div>
        <CustomHelmet
          htmlLang={currentLang}
          helmetTitle={ogTitle}
          canonical={canonical}
          metaDescription={ogDescription}
          ogType={ogType}
          ogSiteName={ogSiteName}
          ogArticlePublishedTime={ogArticlePublishedTime}
          ogArticleModifiedTime={ogArticleModifiedTime}
          ogArticleAuthor={ogArticleAuthor}
          ogArticleSection={ogArticleSection}
          ogArticleTag1={ogArticleTag1}
          ogArticleTag2={ogArticleTag2}
          ogArticleTag3={ogArticleTag3}
          ogArticleTag4={ogArticleTag4}
          ogArticleTag5={ogArticleTag5}
          ogTitle={ogTitle}
          ogDescription={ogDescription}
          ogURL={canonical}
          ogLocale={ogLocale}
          ogLocaleAlt1={ogLocaleAlt1}
          ogLocaleAlt2={ogLocaleAlt2}
          ogImage={ogImage}
          ogImageType={ogImageType}
          ogImageWidth={ogImageWidth}
          ogImageHeight={ogImageHeight}
          ogImageAlt={ogImageAlt}
          twitterTitle={twitterTitle}
          twitterDescription={twitterDescription}
          twitterCardType={twitterCardType}
          twitterCreator={twitterCreator}
          twitterImage={twitterImage}
          twitterImageAlt={twitterImageAlt}
        />
        <CustomHeader/>
        <MediaQuery query="(min-device-width: 0px) and (max-device-width: 599px)">
          <Parallax image={require("@app/assets/img/blog/website-online-presence-600w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 600px) and (max-device-width: 959px)">
          <Parallax image={require("@app/assets/img/blog/website-online-presence-960w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 960px) and (max-device-width: 1279px)">
          <Parallax image={require("@app/assets/img/blog/website-online-presence-1280w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1280px) and (max-device-width: 1919px)">
          <Parallax image={require("@app/assets/img/blog/website-online-presence-1920w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1920px)">
          <Parallax image={require("@app/assets/img/blog/website-online-presence-2560w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <div className={classes.main}>
          <div className={classes.container}>
            <SectionText
              lang={currentLang}
            />
            <SectionBlogTagsShare
              path={this.props.location.pathname}
              title={blogTitle}
              tags={tags} />
            <SectionBlogAuthor />
            <SectionCurrent
              domain={true}
              width={12} />
            <SectionNext
              lang={currentLang}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      locale: state.i18n.locale
  }
}
const mapDispatchToProps = dispatch => {
    return {
        setLocale: locale => dispatch(setLocale(locale))
    };
};

const Page = injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(blogPostPageStyle)(BlogPostPage)));
export default Page;