import React from 'react';
import TermsOfUseView from './TermsOfUseView';

class TermsOfUseContainer extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
       return (
            <TermsOfUseView />
        );
    }
}

export default TermsOfUseContainer;
