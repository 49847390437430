/*eslint-disable*/ import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import MediaQuery from 'react-responsive';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import CustomHelmet from "@app/components/CustomHelmets/CustomHelmet";
import CustomHeader from "@app/components/Header/CustomHeader";
import Parallax from "@app/components/Parallax/Parallax";
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
// sections for this page
import SectionText from "./Sections/SectionText";
import SectionBlogTagsShare from "../../BlogPostsPage/Sections/SectionBlogTagsShare";
import SectionBlogAuthor from "../../BlogPostsPage/Sections/SectionBlogAuthor";
import SectionNext from "../../BlogPostsPage/Sections/SectionNext";
import SectionCurrent from "../../BlogPostsPage/Sections/SectionCurrent";

import blogPostPageStyle from "@app/assets/jss/material-kit-pro-react/views/blogPostPageStyle";

class BlogPostPage extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { classes } = this.props;

    const currentLang = this.props.locale;

    const canonical = this.props.intl.formatMessage({ id: "blog.bitcoin.canonical" });
    const ogTitle = this.props.intl.formatMessage({ id: "blog.bitcoin.og.title" });
    const ogDescription = this.props.intl.formatMessage({ id: "blog.bitcoin.og.description" });
    const ogType = this.props.intl.formatMessage({ id: "blog.bitcoin.og.type" });
    const ogArticlePublishedTime = this.props.intl.formatMessage({ id: "blog.bitcoin.og.article.published.time" });
    const ogArticleModifiedTime = this.props.intl.formatMessage({ id: "blog.bitcoin.og.article.modified.time" });
    const ogArticleAuthor = this.props.intl.formatMessage({ id: "blog.bitcoin.og.article.author" });
    const ogArticleSection = this.props.intl.formatMessage({ id: "blog.bitcoin.og.article.section" });
    const ogArticleTag1 = this.props.intl.formatMessage({ id: "blog.bitcoin.og.article.tag1" });
    const ogArticleTag2 = this.props.intl.formatMessage({ id: "blog.bitcoin.og.article.tag2" });
    const ogArticleTag3 = this.props.intl.formatMessage({ id: "blog.bitcoin.og.article.tag3" });
    const ogArticleTag4 = this.props.intl.formatMessage({ id: "blog.bitcoin.og.article.tag4" });
    const ogSiteName = this.props.intl.formatMessage({ id: "blog.bitcoin.og.site.name" });
    const ogLocale = this.props.intl.formatMessage({ id: "blog.bitcoin.og.locale" });
    const ogLocaleAlt1 = this.props.intl.formatMessage({ id: "blog.bitcoin.og.localeAlt1" });
    const ogLocaleAlt2 = this.props.intl.formatMessage({ id: "blog.bitcoin.og.localeAlt2" });
    const ogImage = this.props.intl.formatMessage({ id: "blog.bitcoin.og.image" });
    const ogImageType = this.props.intl.formatMessage({ id: "blog.bitcoin.og.image.type" });
    const ogImageWidth = this.props.intl.formatMessage({ id: "blog.bitcoin.og.image.width" });
    const ogImageHeight = this.props.intl.formatMessage({ id: "blog.bitcoin.og.image.height" });
    const ogImageAlt = this.props.intl.formatMessage({ id: "blog.bitcoin.og.image.alt" });
    const twitterTitle = this.props.intl.formatMessage({ id: "blog.bitcoin.twitter.title" });
    const twitterDescription = this.props.intl.formatMessage({ id: "blog.bitcoin.twitter.description" });
    const twitterCardType = this.props.intl.formatMessage({ id: "blog.bitcoin.twitter.card.type" });
    const twitterCreator = this.props.intl.formatMessage({ id: "blog.bitcoin.twitter.creator" });
    const twitterImage = this.props.intl.formatMessage({ id: "blog.bitcoin.twitter.image" });
    const twitterImageAlt = this.props.intl.formatMessage({ id: "blog.bitcoin.twitter.image.alt" });

    const blogTitle = this.props.intl.formatMessage({ id: "blog.bitcoin.title" });
    const blogh1 = this.props.intl.formatMessage({ id: "blog.bitcoin.h1" });
    const blogh2 = this.props.intl.formatMessage({ id: "blog.bitcoin.h2" });
    const shareTitle = blogh1 + " - " + blogh2;

    const tags = [
      this.props.intl.formatMessage({ id: "blog.bitcoin.og.article.tag1" }),
      this.props.intl.formatMessage({ id: "blog.bitcoin.og.article.tag2" }),
      this.props.intl.formatMessage({ id: "blog.bitcoin.og.article.tag3" }),
      this.props.intl.formatMessage({ id: "blog.bitcoin.og.article.tag4" })
    ];

    const parallaxContent = (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={8} className={classes.textCenter}>
            <h1 className={classes.title}>
              {blogh1}
            </h1>
            <h2 className={classes.subtitle}>
              {blogh2}
            </h2>
          </GridItem>
        </GridContainer>
      </div>
    );

    return (
      <div>
        <CustomHelmet
          htmlLang={currentLang}
          helmetTitle={ogTitle}
          canonical={canonical}
          metaDescription={ogDescription}
          ogType={ogType}
          ogSiteName={ogSiteName}
          ogArticlePublishedTime={ogArticlePublishedTime}
          ogArticleModifiedTime={ogArticleModifiedTime}
          ogArticleAuthor={ogArticleAuthor}
          ogArticleSection={ogArticleSection}
          ogArticleTag1={ogArticleTag1}
          ogArticleTag2={ogArticleTag2}
          ogArticleTag3={ogArticleTag3}
          ogArticleTag4={ogArticleTag4}
          ogTitle={ogTitle}
          ogDescription={ogDescription}
          ogURL={canonical}
          ogLocale={ogLocale}
          ogLocaleAlt1={ogLocaleAlt1}
          ogLocaleAlt2={ogLocaleAlt2}
          ogImage={ogImage}
          ogImageType={ogImageType}
          ogImageWidth={ogImageWidth}
          ogImageHeight={ogImageHeight}
          ogImageAlt={ogImageAlt}
          twitterTitle={twitterTitle}
          twitterDescription={twitterDescription}
          twitterCardType={twitterCardType}
          twitterCreator={twitterCreator}
          twitterImage={twitterImage}
          twitterImageAlt={twitterImageAlt}
        />
        <CustomHeader/>
        <MediaQuery query="(min-device-width: 0px) and (max-device-width: 599px)">
          <Parallax image={require("@app/assets/img/blog/bitcoin-600w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 600px) and (max-device-width: 959px)">
          <Parallax image={require("@app/assets/img/blog/bitcoin-960w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 960px) and (max-device-width: 1279px)">
          <Parallax image={require("@app/assets/img/blog/bitcoin-1280w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1280px) and (max-device-width: 1919px)">
          <Parallax image={require("@app/assets/img/blog/bitcoin-1920w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1920px)">
          <Parallax image={require("@app/assets/img/blog/bitcoin-2560w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <div className={classes.main}>
          <div className={classes.container}>
            <SectionText 
              lang={currentLang}
            />
            <SectionBlogTagsShare
              path={this.props.location.pathname}
              title={shareTitle}
              tags={tags} />
            <SectionBlogAuthor />
            <SectionCurrent
              bitcoin={true}
              width={12} />
            <SectionNext
              lang={currentLang}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      locale: state.i18n.locale
  }
}
const mapDispatchToProps = dispatch => {
    return {
        setLocale: locale => dispatch(setLocale(locale))
    };
};

const Page = injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(blogPostPageStyle)(BlogPostPage)));
export default Page;