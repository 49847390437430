import {
    main,
    container,
    title
} from "@app/assets/jss/material-kit-pro-react";

const legalPagesStyle = {
    main: {
        ...main
    },
    container: {
        ...container,
        paddingTop: "60px"
    },
    section: {
        padding: "0px 0 1px",
        textAlign: "left"
    },
    title: {
        ...title,
        marginBottom: "60px",
        marginTop: "0px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign: "center"
    },
    description: {
        color: "#999"
    },
    tab: { marginLeft: "40px" },
    mB10: { marginBottom: "10px" }
};

export default legalPagesStyle;
