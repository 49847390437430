import React from "react";
import { injectIntl } from "react-intl";
import MediaQuery from 'react-responsive';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import Button from "@app/components/CustomButtons/Button";
import Card from "@app/components/Card/Card";
import CardBody from "@app/components/Card/CardBody";

import domainPainXXS from "@app/assets/img/blog/website-online-presence-300w.jpg";
import domainPainXS from "@app/assets/img/blog/website-online-presence-600w.jpg";
import domainPainSM from "@app/assets/img/blog/website-online-presence-960w.jpg";
import domainPainMD from "@app/assets/img/blog/website-online-presence-1280w.jpg";
import domainPainLG from "@app/assets/img/blog/website-online-presence-1920w.jpg";
import domainPainXL from "@app/assets/img/blog/website-online-presence-2560w.jpg";
import bitcoinXXS from "@app/assets/img/blog/bitcoin-300w.jpg";
import bitcoinXS from "@app/assets/img/blog/bitcoin-600w.jpg";
import bitcoinSM from "@app/assets/img/blog/bitcoin-960w.jpg";
import bitcoinMD from "@app/assets/img/blog/bitcoin-1280w.jpg";
import bitcoinLG from "@app/assets/img/blog/bitcoin-1920w.jpg";
import bitcoinXL from "@app/assets/img/blog/bitcoin-2560w.jpg";

import sectionCurrentStyle from "@app/assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle";

function SectionCurrent({ ...props }) {
  const { classes } = props;

  const current = props.width == 6 ? props.intl.formatMessage({ id: "blog.read.on" }) : props.intl.formatMessage({ id: "blog.you.may.also.like" });

  const domainTitle = props.intl.formatMessage({ id: "blog.domain.title" });
  const domainLink = props.intl.formatMessage({ id: "blog.domain.link" });
  const domainIntro = props.intl.formatMessage({ id: "blog.domain.intro" });

  const bitcoinLink = props.intl.formatMessage({ id: "blog.bitcoin.link" });
  const bitcoinTitle = props.intl.formatMessage({ id: "blog.bitcoin.title" });
  const bitcoinIntro = props.intl.formatMessage({ id: "blog.bitcoin.intro" });

  const read = props.intl.formatMessage({ id: "blog.read" });

  const domainBody = (
    <CardBody background>
      <a href={domainLink}>
        <h3 className={classes.cardTitle}>
          {domainTitle}
        </h3>
      </a>
      <h4 className={classes.category}>
        <b>{domainIntro}</b>
      </h4>
      <Button aria-label={read} round href={domainLink} color="warning">
        <FormatAlignLeft className={classes.icons} />
        {read}
      </Button>
    </CardBody>
  );

  const bitcoinBody = (
    <CardBody background>
    <a href={bitcoinLink}>
      <h3 className={classes.cardTitle}>
        {bitcoinTitle}
      </h3>
    </a>
    <h4 className={classes.category}>
      <b>{bitcoinIntro}</b>
    </h4>
    <Button aria-label={read} round href={bitcoinLink} color="warning">
      <FormatAlignLeft className={classes.icons} />
      {read}
    </Button>
  </CardBody>
  );

  return (
    <div className={classes.section}>
      <h2 className={classes.title + " " + classes.textCenter}>
        {current}
      </h2>
      <GridContainer>
        <MediaQuery query="(min-device-width: 0px) and (max-device-width: 599px)">
          {props.domain != true && 
            <GridItem xs={12} sm={12} md={props.width}>
              <Card raised background style={props.width == 6 ? { backgroundImage: "url(" + domainPainXXS + ")" } : { backgroundImage: "url(" + domainPainXS + ")" }}>
                {domainBody}
              </Card>
            </GridItem>
          }
          {props.bitcoin != true && 
            <GridItem xs={12} sm={12} md={props.width}>
              <Card raised background style={props.width == 6 ? { backgroundImage: "url(" + bitcoinXXS + ")" } : { backgroundImage: "url(" + bitcoinXS + ")" }}>
                {bitcoinBody}
              </Card>
            </GridItem>
          }
        </MediaQuery>
        <MediaQuery query="(min-device-width: 600px) and (max-device-width: 959px)">
          {props.domain != true && 
            <GridItem xs={12} sm={12} md={props.width}>
              <Card raised background style={props.width == 6 ? { backgroundImage: "url(" + domainPainXS + ")" } : { backgroundImage: "url(" + domainPainSM + ")" }}>
                {domainBody}
              </Card>
            </GridItem>
          }
          {props.bitcoin != true && 
            <GridItem xs={12} sm={12} md={props.width}>
              <Card raised background style={props.width == 6 ? { backgroundImage: "url(" + bitcoinXS + ")" } : { backgroundImage: "url(" + bitcoinSM + ")" }}>
                {bitcoinBody}
              </Card>
            </GridItem>
          }
        </MediaQuery>
        <MediaQuery query="(min-device-width: 960px) and (max-device-width: 1279px)">
          {props.domain != true && 
            <GridItem xs={12} sm={12} md={props.width}>
              <Card raised background style={props.width == 6 ? { backgroundImage: "url(" + domainPainSM + ")" } : { backgroundImage: "url(" + domainPainMD + ")" }}>
                {domainBody}
              </Card>
            </GridItem>
          }
          {props.bitcoin != true && 
            <GridItem xs={12} sm={12} md={props.width}>
              <Card raised background style={props.width == 6 ? { backgroundImage: "url(" + bitcoinSM + ")" } : { backgroundImage: "url(" + bitcoinMD + ")" }}>
                {bitcoinBody}
              </Card>
            </GridItem>
          }
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1280px) and (max-device-width: 1919px)">
          {props.domain != true && 
            <GridItem xs={12} sm={12} md={props.width}>
              <Card raised background style={props.width == 6 ? { backgroundImage: "url(" + domainPainMD + ")" } : { backgroundImage: "url(" + domainPainLG + ")" }}>
                {domainBody}
              </Card>
            </GridItem>
          }
          {props.bitcoin != true && 
            <GridItem xs={12} sm={12} md={props.width}>
              <Card raised background style={props.width == 6 ? { backgroundImage: "url(" + bitcoinMD + ")" } : { backgroundImage: "url(" + bitcoinLG + ")" }}>
                {bitcoinBody}
              </Card>
            </GridItem>
          }
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1920px)">
          {props.domain != true && 
            <GridItem xs={12} sm={12} md={props.width}>
              <Card raised background style={props.width == 6 ? { backgroundImage: "url(" + domainPainLG + ")" } : { backgroundImage: "url(" + domainPainXL + ")" }}>
                {domainBody}
              </Card>
            </GridItem>
          }
          {props.bitcoin != true && 
            <GridItem xs={12} sm={12} md={props.width}>
              <Card raised background style={props.width == 6 ? { backgroundImage: "url(" + bitcoinLG + ")" } : { backgroundImage: "url(" + bitcoinXL + ")" }}>
                {bitcoinBody}
              </Card>
            </GridItem>
          }
        </MediaQuery>
      </GridContainer>
    </div>
  );
}

export default injectIntl(withStyles(sectionCurrentStyle)(SectionCurrent));