/*eslint-disable*/
import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import MediaQuery from 'react-responsive';
// nodejs library that concatenates classes
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import CustomHelmet from "@app/components/CustomHelmets/CustomHelmet";
import CustomHeader from "@app/components/Header/CustomHeader";
import GridContainer from "@app/components/Grid/GridContainer";
import GridItem from "@app/components/Grid/GridItem";
import Parallax from "@app/components/Parallax/Parallax";

import aboutUsStyle from "@app/assets/jss/material-kit-pro-react/views/aboutUsStyle";

class AboutUsPage extends React.Component {
  componentDidMount() {
      window.scrollTo(0, 0);
  }
  render() {
    const { classes } = this.props;

    const currentLang = this.props.locale;
    const canonical = this.props.intl.formatMessage({ id: "about.us.canonical" });
    const ogTitle = this.props.intl.formatMessage({ id: "about.us.og.title" });
    const ogDescription = this.props.intl.formatMessage({ id: "about.us.og.description" });
    const ogType = this.props.intl.formatMessage({ id: "about.us.og.type" });
    const ogSiteName = this.props.intl.formatMessage({ id: "about.us.og.site.name" });
    const ogLocale = this.props.intl.formatMessage({ id: "about.us.og.locale" });
    const ogLocaleAlt1 = this.props.intl.formatMessage({ id: "about.us.og.localeAlt1" });
    const ogLocaleAlt2 = this.props.intl.formatMessage({ id: "about.us.og.localeAlt2" });
    const ogImage = this.props.intl.formatMessage({ id: "about.us.og.image" });
    const ogImageType = this.props.intl.formatMessage({ id: "about.us.og.image.type" });
    const ogImageWidth = this.props.intl.formatMessage({ id: "about.us.og.image.width" });
    const ogImageHeight = this.props.intl.formatMessage({ id: "about.us.og.image.height" });
    const ogImageAlt = this.props.intl.formatMessage({ id: "about.us.og.image.alt" });
    const twitterTitle = this.props.intl.formatMessage({ id: "about.us.twitter.title" });
    const twitterDescription = this.props.intl.formatMessage({ id: "about.us.twitter.description" });
    const twitterCardType = this.props.intl.formatMessage({ id: "about.us.twitter.card.type" });
    const twitterCreator = this.props.intl.formatMessage({ id: "about.us.twitter.creator" });
    const twitterImage = this.props.intl.formatMessage({ id: "about.us.twitter.image" });
    const twitterImageAlt = this.props.intl.formatMessage({ id: "about.us.twitter.image.alt" });

    const pageTitle = this.props.intl.formatMessage({ id: "about.us" });
    const tagLine = this.props.intl.formatMessage({ id: "about.us.tagLine" });

    const parallaxContent = (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem
            md={8}
            sm={8}
            className={classNames(
              classes.mlAuto,
              classes.mrAuto,
              classes.textCenter
            )}
          >
            <h1 className={classes.title}>
              {pageTitle}
            </h1>
            <h2 className={classes.title}>
              {tagLine}
            </h2>
            <h3 className={classes.title}>
              {ogDescription}
            </h3>
          </GridItem>
        </GridContainer>
      </div>
    );

    return (
      <div>
        <CustomHelmet
          htmlLang={currentLang}
          helmetTitle={ogTitle}
          canonical={canonical}
          metaDescription={ogDescription}
          ogType={ogType}
          ogSiteName={ogSiteName}
          ogTitle={ogTitle}
          ogDescription={ogDescription}
          ogURL={canonical}
          ogLocale={ogLocale}
          ogLocaleAlt1={ogLocaleAlt1}
          ogLocaleAlt2={ogLocaleAlt2}
          ogImage={ogImage}
          ogImageType={ogImageType}
          ogImageWidth={ogImageWidth}
          ogImageHeight={ogImageHeight}
          ogImageAlt={ogImageAlt}
          twitterTitle={twitterTitle}
          twitterDescription={twitterDescription}
          twitterCardType={twitterCardType}
          twitterCreator={twitterCreator}
          twitterImage={twitterImage}
          twitterImageAlt={twitterImageAlt}
        />
        <CustomHeader/>
        <MediaQuery query="(min-device-width: 0px) and (max-device-width: 599px)">
          <Parallax image={require("@app/assets/img/bg/about-us-fiona-working-in-the-office-600w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 600px) and (max-device-width: 959px)">
          <Parallax image={require("@app/assets/img/bg/about-us-fiona-working-in-the-office-960w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 960px) and (max-device-width: 1279px)">
            <Parallax image={require("@app/assets/img/bg/about-us-fiona-working-in-the-office-1280w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1280px) and (max-device-width: 1919px)">
          <Parallax image={require("@app/assets/img/bg/about-us-fiona-working-in-the-office-1920w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
        <MediaQuery query="(min-device-width: 1920px)">
          <Parallax image={require("@app/assets/img/bg/about-us-fiona-working-in-the-office-2560w.jpg")} filter="dark">
            {parallaxContent}
          </Parallax>
        </MediaQuery>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      locale: state.i18n.locale
  }
}
const mapDispatchToProps = dispatch => {
    return {
        setLocale: locale => dispatch(setLocale(locale))
    };
};

const Page = injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(aboutUsStyle)(AboutUsPage)));
export default Page;